import { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import {
  sendTeam,
  sendOpponentTeam,
} from '../../../redux/operations/teamOperations';
import * as validators from '../../../utils/validators';
import ValidationInput from '../../common/validationComponents/ValidationInput';
import ValidationSelect from '../../common/validationComponents/ValidationSelect';
import { modalTypes } from '../../../consts/consts';
import { changeStatus } from '../../../redux/actions/modalActions';
import SelectPlayers from '../../SelectPlayers/SelectPlayersContainer';
import { getCategory } from '../../../api/category-service/category-service';

import FileLoader from '../../fileLoader/fileLoader';
import RecomendationInfo from '../../Recomendation/Recomendation';

import countryOptions from '../options/countryOptions.json';

import './styles.scss';
import { changeGameInnerFormStatus } from '../../../redux/actions/gameActions';

let FormTeam = ({ handleSubmit, isOpponent, renderType }) => {
  const [selectPlayer, setPlayerSelect] = useState([]);
  const [category, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [uploadImages, changeUploadImage] = useState([]);
  const [emptyImage, changeEmptyImage] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getCategory().then(({ items }) => setCategory(items));
  }, []);

  const submitForm = (data = {}) => {
    if (!uploadImages.length && isOpponent) {
      return changeEmptyImage(true);
    }

    const newData = {
      ...data,
      players: selectPlayer,
    };

    isOpponent
      ? dispatch(sendOpponentTeam(newData, renderType, uploadImages))
      : dispatch(sendTeam(newData, renderType));
  };

  const addPlayerToTeam = arr => setPlayerSelect(arr);

  //FileLoader
  const callbackError = value => {
    setError(value);
  };

  const callbackValue = value => {
    changeUploadImage(value);
    changeEmptyImage(false);
  };

  return (
    <div className={'form-team-container'}>
      <form className={'form-team'} onSubmit={handleSubmit(submitForm)}>
        {isOpponent && (
          <div>
            {emptyImage && (
              <p className={'form-team__error'}>Need to add an image</p>
            )}
            {error && <p className={'form-team__error'}>{error}</p>}

            <RecomendationInfo
              text="Recommendation: upload images no more than 415px width and 405px high"
              position="right"
            />
            <FileLoader
              errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
              callbackError={callbackError}
              callbackValue={callbackValue}
            />
          </div>
        )}
        <div>
          <label>Name</label>
          <Field
            className={'form-team__input'}
            name="name"
            component={ValidationInput}
            type="text"
            validate={[validators.required, validators.maxLength16]}
            placeholder="Team name"
          />
        </div>
        <div>
          <div>
            <label>Country</label>
            <Field
              className={'form-team__input'}
              name="country"
              component={ValidationSelect}
              validate={[validators.required]}
              placeholder="select"
            >
              <option></option>
              {countryOptions.map(({ name, isoCode }) => (
                <option key={name} value={isoCode}>
                  {name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div>
          <label>Tag</label>
          <Field
            className={'form-team__input'}
            name="game"
            component={ValidationSelect}
            validate={[validators.required]}
            placeholder="select"
          >
            <option></option>
            {category.map(({ name, _id: id }) => (
              <option key={name} value={id}>
                {name}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <label> Select a player to add to the team</label>
          <SelectPlayers
            isOpponent={isOpponent}
            addPlayerToTeam={addPlayerToTeam}
          />
        </div>
        <div className={'form-team__actions'}>
          <button className="button" type="submit">
            submit
          </button>
          <button
            onClick={() => {
              renderType === 'gameFormCreate'
                ? dispatch(changeGameInnerFormStatus(false))
                : dispatch(changeStatus(modalTypes.CLOSE));
            }}
            className="button"
            type="button"
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );
};

FormTeam = reduxForm({
  form: 'team',
})(FormTeam);

export default FormTeam;
