export const uploadImagesConfig = [
  {
    width: '1920',
    height: '1080',
    image: 'titleImage_FULLHD',
    size: 'FULL HD',
  },
  // {
  //   width: '1440',
  //   height: '768',
  //   image: 'titleImage_DESKTOP',
  //   size: 'Desktop',
  // },

  // {
  //   width: '1024',
  //   height: '600',
  //   image: 'titleImage_LAPTOP',
  //   size: 'Laptop',
  // },
  // {
  //   width: '768',
  //   height: '576',
  //   image: 'titleImage_TABLET',
  //   size: 'Tablet',
  // },
  // {
  //   width: '428',
  //   height: '926',
  //   image: 'titleImage_BIGPHONE',
  //   size: 'Big phone',
  // },
  // {
  //   width: '375',
  //   height: '667',
  //   image: 'titleImage_PHONE',
  //   size: 'Phone',
  // },
  // {
  //   width: '320',
  //   height: '480',
  //   image: 'titleImage_SMALLPHONE',
  //   size: 'Small phone',
  // },
];
