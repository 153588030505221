import ValidationInput from '../common/validationComponents/ValidationInput';
import * as validators from '../../utils/validators';

export const firstConfig = {
  listOfFieldsName: ['firstEn'],
  listOfFields: [
    {
      className: 'input',
      name: 'firstEn',
      component: ValidationInput,
      type: 'text',
      validate: [
        validators.required,
        validators.minLength2,
        validators.maxLength14,
        validators.spaces,
      ],
      placeholder: 'first name EN',
    },
  ],
};

export const lastConfig = {
  listOfFieldsName: ['lastEn'],
  listOfFields: [
    {
      className: 'input',
      name: 'lastEn',
      component: ValidationInput,
      type: 'text',
      validate: [
        validators.required,
        validators.minLength2,
        validators.maxLength14,
        validators.spaces,
      ],
      placeholder: 'last name EN',
    },
  ],
};

export const positionConfig = {
  listOfFieldsName: ['positionEn'],
  listOfFields: [
    {
      className: 'input',
      name: 'positionEn',
      component: ValidationInput,
      type: 'text',
      validate: [validators.required, validators.maxLength2000],
      placeholder: 'position EN',
    },
  ],
};
