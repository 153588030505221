import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import * as validators from '../../../utils/validators';
import { getNormalizeTournamentData } from '../../../utils/changeDataFormat';
import ValidationInput from '../../common/validationComponents/ValidationInput';
import ValidationSelect from '../../common/validationComponents/ValidationSelect';
import { getCategory } from '../../../api/category-service/category-service';
import { modalTypes } from '../../../consts/consts';
import { changeStatus } from '../../../redux/actions/modalActions';
import {
  sendTournament,
  fetchTournament,
  editTournamentAll,
  editTournamentForm,
  editTornamentLogo,
} from '../../../redux/operations/tournamentOperation';
import { getErrorMessage } from '../../../redux/actions/errorHandlerAction';
import formatOptions from '../options/formatOptions.json';
import FileLoader from '../../fileLoader/fileLoader';
import './styles.scss';
import { closeModal } from '../../../redux/actions/modalActions';
import RecomendationInfo from '../../Recomendation/Recomendation';

let FormTournament = ({
  handleSubmit,
  initialize,
  type,
  idTournament,
  dirty,
}) => {
  const [category, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [emptyLogo, changeEmptyLogo] = useState(false);
  const [emptyBackground, changeEmptyBackground] = useState(false);
  const [uploadLogo, changeUploadLogo] = useState([]);
  const [defaultLogo, setDefaultLogo] = useState(null);
  const [uploadBackground, changeUploadBackground] = useState([]);
  const [defaultBackground, setDefaultBackground] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type) {
      dispatch(fetchTournament(idTournament))
        .then(tournament => {
          const normalizedData = getNormalizeTournamentData(tournament);
          setDefaultLogo(normalizedData.logo);
          setDefaultBackground(normalizedData.background);
          initialize(normalizedData);
        })
        .catch(({ message }) => {
          return dispatch(getErrorMessage(message));
        });
    }

    getCategory().then(({ items }) => {
      setCategory(items);
    });
  }, []);

  const callbackError = value => {
    setError(value);
  };

  const callbackLogo = value => {
    changeUploadLogo(value);
    changeEmptyLogo(false);
    setDefaultLogo('');
  };

  const callbackBackground = value => {
    changeUploadBackground(value);
    changeEmptyBackground(false);
    setDefaultBackground('');
  };

  const handleSubmitForm = (data = {}) => {
    if (!uploadLogo.length && !defaultLogo) return changeEmptyLogo(true);
    if (!uploadBackground.length && !defaultBackground)
      return changeEmptyBackground(true);

    const actualUpdateTournament = () => {
      if (!dirty && !!uploadLogo.length && !!uploadBackground.length) {
        dispatch(
          editTornamentLogo(idTournament, {
            logo: uploadLogo[0],
            background: uploadBackground[0],
          }),
        );
      } else if (dirty && !!uploadLogo.length && !!uploadBackground.length) {
        dispatch(
          editTournamentAll(idTournament, data, {
            logo: uploadLogo[0],
            background: uploadBackground[0],
          }),
        );
      } else if (dirty && !uploadLogo.length && !uploadBackground.length) {
        dispatch(editTournamentForm(idTournament, data));
      } else {
        return dispatch(closeModal());
      }
    };

    type
      ? actualUpdateTournament()
      : dispatch(
          sendTournament(data, {
            logo: uploadLogo[0],
            background: uploadBackground[0],
          }),
        );
  };

  return (
    <div className="form-event-container">
      <form className={'form-event'} onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="form-event__text-content">
          <div className="form-event__text-content-first">
            <div>
              <label>Tournament Name</label>
              <Field
                className={'form-event__input'}
                name="title"
                component={ValidationInput}
                type="text"
                validate={[validators.required]}
                placeholder="tournament name"
              />
            </div>
            <div>
              <label>Tag</label>
              <Field
                className={'form-event__input'}
                name="discipline"
                component={ValidationSelect}
                validate={[validators.required]}
                placeholder="select"
              >
                <option></option>
                {category.map(({ name, _id: id }) => (
                  <option key={name} value={id}>
                    {name}
                  </option>
                ))}
              </Field>
            </div>
            <div>
              <label>Start Date</label>
              <Field
                className={'form-event__input'}
                name="startDate"
                component={ValidationInput}
                type="datetime-local"
                validate={[validators.required, validators.eventDate]}
                placeholder="startDate"
              />
            </div>
            <div>
              <label>End Date</label>
              <Field
                className={'form-event__input'}
                name="endDate"
                component={ValidationInput}
                type="datetime-local"
                validate={[validators.required, validators.eventDate]}
                placeholder="endDate"
              />
            </div>
            <div>
              <label>Format</label>
              <Field
                className={'form-event__input'}
                name="format"
                component={ValidationSelect}
                validate={[validators.required]}
                placeholder="select"
              >
                <option></option>
                {formatOptions.map(({ name }, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </Field>
            </div>
            <div className="form-event__image">
              <div className="form-event__image-item">
                {emptyLogo && (
                  <p className="form-event__error">Need to add an logo</p>
                )}
                {error && <p className={'form-event__error'}>{error}</p>}
                <RecomendationInfo
                  text={
                    'Recommendation: upload images that are no more than 350 pixels width'
                  }
                />
                <p>Add logo</p>
                <FileLoader
                  name={'logo'}
                  errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
                  callbackError={callbackError}
                  callbackValue={callbackLogo}
                  defaultImage={defaultLogo ? [defaultLogo] : []}
                />
              </div>
              <div className="form-event__image-item">
                {emptyBackground && (
                  <p className="form-event__error">Need to add an background</p>
                )}
                {error && <p className={'form-event__error'}>{error}</p>}
                <RecomendationInfo
                  text={
                    'Recommendation: upload images that are no more than 350 pixels width'
                  }
                />
                <p>Add background</p>
                <FileLoader
                  name={'background'}
                  errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
                  callbackError={callbackError}
                  callbackValue={callbackBackground}
                  defaultImage={defaultBackground ? [defaultBackground] : []}
                />
              </div>
            </div>
          </div>
          <div className="form-event__text-content-second">
            <div>
              <label>Location EN</label>
              <Field
                className={'form-event__input'}
                name="locationEn"
                component={ValidationInput}
                type="text"
                validate={[validators.required]}
                placeholder="location english"
              />
            </div>
            <div>
              <label>Description EN</label>
              <Field
                className={'form-event__input'}
                name="descriptionEn"
                component={ValidationInput}
                type="text"
                validate={[validators.required]}
                placeholder="description english"
              />
            </div>
          </div>
        </div>
        <div className={'form-event__actions'}>
          <button className="button" type="submit">
            submit
          </button>
          <button
            onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
            className="button"
            type="button"
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );
};

FormTournament = reduxForm({
  form: 'event',
})(FormTournament);

export default FormTournament;
