export const confirmTypes = {
  PLAYER: 'confirm-remove-player',
  ACHIEVEMENT: 'confirm-remove-achievement',
  CATEGORY: 'confirm-remove-category',
  TEAM: 'confirm-remove-team',
  OPPONENT_TEAM: 'confirm-remove-opponent-team',

  CONFIRM_REMOVE_GAME: 'CONFIRM_REMOVE_GAME',
  CONFIRM_REMOVE_TOURNAMENT: 'CONFIRM_REMOVE_TOURNAMENT',
};
export const formTypes = {
  CATEGORIES: 'categories',
  TEAM: 'team',
  OPPONENT_TEAM: 'opponent-team',
  PLAYER_OPPONENT: 'PLAYER_OPPONENT',
  ACHIEVEMENT: 'achievement',
  PLAYER: 'player',
  EDIT_PLAYER: 'edit-player',
  EDIT_CATEGORY: 'edit-category',
  EDIT_TEAM_PLAYERS: 'edit-team-players',
  GAME: 'game',
  EDIT_GAME: 'edit-game',
  TOURNAMENT: 'tournament',
  EDIT_TOURNAMENT: 'edit-tournament',
  EDIT_OPPONENT_TEAM: 'edit-opponent-team',
  EDIT_ACHIEVEMENT: 'edit-achievement',
  EDIT_PLAYER_OPPONENT: 'EDIT_PLAYER_OPPONENT',
  EDIT_OPPONENT_TEAM_PLAYERS: 'EDIT_OPPONENT_TEAM_PLAYERS',
  LINKS_MANAGEMENT: 'links-management',
};
export const mediaTypes = {
  UPLOAD_VIDEO: 'MEDIA/UPLOAD_VIDEO',
  CREATE_ALBUM: 'MEDIA/CREATE_ALBUM',
  OPEN_ALBUM: 'MEDIA/OPEN_ALBUM',
  OPEN_VIDEO_COMMENT: 'MEDIA/OPEN_VIDEO_COMMENT',
  CONFIRM_REMOVE_VIDEO: 'MEDIA/CONFIRM-REMOVE_VIDEO',
  CONFIRM_REMOVE_ALBUM: 'MEDIA/CONFIRM-REMOVE_ALBUM',
  OPEN_GAME_COMMENT: 'OPEN_GAME_COMMENT',
};
export const news = {
  OPEN_NEWS_FORM: 'NEWS/OPEN_NEWS_FORM',
  OPEN_EDIT_NEWS_FORM: 'NEWS/OPEN_EDIT_NEWS_FORM',

  CONFIRM_REMOVE_ARTICLE: 'NEWS/CONFIRM_REMOVE_ARTICLE',
};
export const about = {
  OPEN_ADD_PARTNER_FORM: 'ABOUT/OPEN_ADD_PARTNER_FORM',
  OPEN_EDIT_PARTNER_FORM: 'ABOUT/OPEN_EDIT_PARTNER_FORM',
  OPEN_ADD_STAFF_FORM: 'ABOUT/OPEN_ADD_STAFF_FORM',
  CONFIRM_REMOVE_PARTNER: 'ABOUT/CONFIRM_REMOVE_PARTNER',
  CONFIRM_REMOVE_STAFF: 'ABOUT/CONFIRM_REMOVE_STAFF',
};

export const modalTypes = {
  OPEN_ADD_PARTNER_FORM: () => ({
    open: true,
    type: about.OPEN_ADD_PARTNER_FORM,
    isShowTooltip: true,
  }),
  OPEN_EDIT_PARTNER_FORM: id => ({
    open: true,
    type: about.OPEN_EDIT_PARTNER_FORM,
    id: id,
    isShowTooltip: true,
  }),
  OPEN_ADD_STAFF_FORM: () => ({
    open: true,
    type: about.OPEN_ADD_STAFF_FORM,
    isShowTooltip: true,
  }),
  CONFIRM_REMOVE_PARTNER: id => ({
    open: true,
    type: about.CONFIRM_REMOVE_PARTNER,
    id: id,
  }),
  CONFIRM_REMOVE_STAFF: id => ({
    open: true,
    type: about.CONFIRM_REMOVE_STAFF,
    id: id,
  }),
  OPEN_NEWS_FORM: content => ({
    open: true,
    type: news.OPEN_NEWS_FORM,
    content: content,
    isShowTooltip: true,
  }),

  OPEN_EDIT_NEWS_FORM: {
    open: true,
    type: news.OPEN_EDIT_NEWS_FORM,
    isShowTooltip: true,
  },

  OPEN_UPLOAD_VIDEO: (edit, typeVideo, id) => ({
    open: true,
    type: mediaTypes.UPLOAD_VIDEO,
    id,
    edit,
    typeVideo,
    isShowTooltip: true,
  }),
  OPEN_VIDEO_COMMENT: (id, typeComment, idAlbum) => ({
    type: mediaTypes.OPEN_VIDEO_COMMENT,
    open: true,
    typeComment: typeComment,
    id,
    idAlbum,
  }),
  OPEN_ALBUM: id => ({
    open: true,
    id: id,
    type: mediaTypes.OPEN_ALBUM,
    isShowTooltip: true,
  }),
  CREATE_ALBUM: () => ({
    open: true,
    type: mediaTypes.CREATE_ALBUM,
    isShowTooltip: true,
  }),
  CONFIRM_REMOVE_ALBUM: id => ({
    open: true,
    type: mediaTypes.CONFIRM_REMOVE_ALBUM,
    id,
  }),
  REMOVE_VIDEO: (id, typeVideo) => ({
    open: true,
    type: mediaTypes.CONFIRM_REMOVE_VIDEO,
    typeVideo,
    id,
  }),
  CLOSE: {
    open: false,
    type: null,
    item: null,
    isShowTooltip: false,
  },
  CATEGORIES: {
    open: true,
    type: formTypes.CATEGORIES,
    item: null,
    isShowTooltip: true,
  },
  TEAM: {
    open: true,
    type: formTypes.TEAM,
    item: null,
    isShowTooltip: true,
  },
  OPPONENT_TEAM: {
    open: true,
    type: formTypes.OPPONENT_TEAM,
    item: null,
    isShowTooltip: true,
  },
  ACHIEVEMENT: {
    open: true,
    type: formTypes.ACHIEVEMENT,
    item: null,
    isShowTooltip: true,
  },
  PLAYER: {
    open: true,
    type: formTypes.PLAYER,
    item: null,
    isShowTooltip: true,
  },
  LINKS_MANAGEMENT: (item = null) => ({
    open: true,
    type: formTypes.LINKS_MANAGEMENT,
    item,
    isShowTooltip: true,
  }),
  PLAYER_OPPONENT: {
    open: true,
    type: formTypes.PLAYER_OPPONENT,
    item: null,
    isShowTooltip: true,
  },
  CONFIRM_REMOVE_ARTICLE: id => ({
    open: true,
    type: news.CONFIRM_REMOVE_ARTICLE,
    id,
  }),
  EDIT_TEAM_PLAYERS: id => ({
    open: true,
    type: formTypes.EDIT_TEAM_PLAYERS,
    id,
    isShowTooltip: true,
  }),
  EDIT_OPPONENT_TEAM_PLAYERS: id => ({
    open: true,
    type: formTypes.EDIT_OPPONENT_TEAM_PLAYERS,
    id,
    isShowTooltip: true,
  }),
  EDIT_PLAYER: id => ({
    open: true,
    type: formTypes.EDIT_PLAYER,
    id,
    isShowTooltip: true,
  }),
  EDIT_PLAYER_OPPONENT: id => ({
    open: true,
    type: formTypes.EDIT_PLAYER_OPPONENT,
    id,
    isShowTooltip: true,
  }),
  EDIT_OPPONENT_TEAM: id => ({
    open: true,
    type: formTypes.EDIT_OPPONENT_TEAM,
    id,
    isShowTooltip: true,
  }),

  EDIT_CATEGORY: id => ({
    open: true,
    type: formTypes.EDIT_CATEGORY,
    id,
    isShowTooltip: true,
  }),
  CONFIRM_REMOVE_PLAYER: (id, isOpponent) => ({
    open: true,
    type: confirmTypes.PLAYER,
    id,
    isOpponent,
  }),
  CONFIRM_REMOVE_ACHIEVEMENT: id => ({
    open: true,
    type: confirmTypes.ACHIEVEMENT,
    id,
  }),
  CONFIRM_REMOVE_CATEGORY: id => ({
    open: true,
    type: confirmTypes.CATEGORY,
    id,
  }),
  CONFIRM_REMOVE_TEAM: (id, isOpponent) => ({
    open: true,
    type: confirmTypes.TEAM,
    id,
    isOpponent,
  }),
  CONFIRM_REMOVE_OPPONENT_TEAM: id => ({
    open: true,
    type: confirmTypes.OPPONENT_TEAM,
    id,
  }),

  CONFIRM_REMOVE_GAME: id => ({
    open: true,
    type: confirmTypes.CONFIRM_REMOVE_GAME,
    id,
  }),

  CONFIRM_REMOVE_TOURNAMENT: id => ({
    open: true,
    type: confirmTypes.CONFIRM_REMOVE_TOURNAMENT,
    id,
  }),

  GAME: {
    open: true,
    type: formTypes.GAME,
    item: null,
    isShowTooltip: true,
  },
  EDIT_GAME: id => ({
    open: true,
    type: formTypes.EDIT_GAME,
    id,
    isShowTooltip: true,
  }),
  OPEN_GAME_COMMENT: (id, typeComment, idGame) => ({
    type: mediaTypes.OPEN_GAME_COMMENT,
    open: true,
    typeComment: typeComment,
    id,
    idGame,
  }),

  TOURNAMENT: {
    open: true,
    type: formTypes.TOURNAMENT,
    item: null,
    isShowTooltip: true,
  },
  EDIT_TOURNAMENT: id => ({
    open: true,
    type: formTypes.EDIT_TOURNAMENT,
    id,
    isShowTooltip: true,
  }),
  EDIT_ACHIEVEMENT: id => ({
    open: true,
    type: formTypes.EDIT_ACHIEVEMENT,
    id,
    isShowTooltip: true,
  }),
};
