import React from 'react';
import { connect } from 'react-redux';
import { modal } from '../../redux/selectors/selectors';
import { changeStatus } from '../../redux/actions/modalActions';
import { removeCategory } from '../../redux/operations/categoryOperation';
import { removeTeam } from '../../redux/operations/teamOperations';

import { removeAchievement } from '../../redux/operations/achievementOperation';
import { removePlayer } from '../../redux/operations/playerOperation';
import { removeGame } from '../../redux/operations/gameOperation';
import { removeTournament } from '../../redux/operations/tournamentOperation';

import {
  formTypes,
  confirmTypes,
  mediaTypes,
  news,
  about,
} from '../../consts/consts';
import Modal from './Modal';

//components for render in modal
import Confirm from '../Confirm/Confirm';
import FormAchievement from '../Forms/FormAchievement/FormAchievement';
import FormPlayer from '../Forms/FormPlayer/FormPlayer';
import FormCategory from '../Forms/FormCategory/FormCategory';
import FormGame from '../Forms/FormGame/FormGame';
import FormTournament from '../Forms/FormTournament/FormTournament';
import UploaderVideos from '../uploaderVideos/uploaderVideos';
import {
  removeAlbum,
  removeVideo,
} from '../../redux/operations/mediaOperations';
import Album from '../album/album';
import CreatorAlbum from '../creatorAlbum/creatorAlbum';
import Comment from '../comment/comment';
import FormNews from '../Forms/FormNews/formNews';
import FormTeam from '../Forms/FormTeam/FormTeam';

import TeamPlayers from '../TeamPlayers/TeamPlayersContainer';
import {
  removePartner,
  removeStaff,
} from '../../redux/operations/aboutOperation';
import { removeArticle } from '../../redux/operations/newsOperations';
import StaffmateCreator from '../staffmateCreator/staffmateCreator';
import PartnerCreator from '../partnerCreator/partnerCreator';
import LinksManagement from '../Forms/LinksManagement/LinksManagement';

const RenderModal = ({
  modalStatus,
  changeStatus,
  removeCategory,
  removeAchievement,
  removePlayer,
  removeVideo,
  removeAlbum,
  removeTeam,
  removePartner,
  removeStaff,
  removeArticle,
  removeGame,
  removeTournament,
}) => {
  const { open, type, id, typeVideo, isOpponent, item } = modalStatus;
  const getModalBody = () => {
    switch (type) {
      case confirmTypes.PLAYER:
        return (
          <Confirm
            type={type}
            id={id}
            isOpponent={isOpponent}
            remove={removePlayer}
            onClose={changeStatus}
            title={'player'}
          />
        );
      case confirmTypes.ACHIEVEMENT:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeAchievement}
            onClose={changeStatus}
            title={'achievement'}
          />
        );
      case confirmTypes.CATEGORY:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeCategory}
            onClose={changeStatus}
            title={'category'}
          />
        );
      case confirmTypes.TEAM:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeTeam}
            isOpponent={isOpponent}
            onClose={changeStatus}
            title={'team'}
          />
        );

      case formTypes.CATEGORIES:
        return <FormCategory />;
      case formTypes.LINKS_MANAGEMENT:
        return <LinksManagement item={item} />;

      case formTypes.ACHIEVEMENT:
        return <FormAchievement />;
      case formTypes.PLAYER:
        return <FormPlayer isOpponent={false} />;
      case formTypes.PLAYER_OPPONENT:
        return <FormPlayer isOpponent={true} />;
      case formTypes.TEAM:
        return <FormTeam isOpponent={false} />;
      case formTypes.OPPONENT_TEAM:
        return <FormTeam isOpponent={true} />;
      case formTypes.EDIT_TEAM_PLAYERS:
        return <TeamPlayers idTeam={id} isOpponent={false} />;
      case formTypes.EDIT_OPPONENT_TEAM_PLAYERS:
        return <TeamPlayers idTeam={id} isOpponent={true} />;
      case mediaTypes.UPLOAD_VIDEO:
        return (
          <UploaderVideos
            id={modalStatus.id}
            edit={modalStatus.edit}
            typeVideo={modalStatus.typeVideo}
            onClose={changeStatus}
          />
        );
      case mediaTypes.CREATE_ALBUM:
        return <CreatorAlbum close={changeStatus} />;
      case mediaTypes.CONFIRM_REMOVE_VIDEO:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeVideo}
            onClose={changeStatus}
            title={'Video'}
            typeVideo={typeVideo}
          />
        );
      case mediaTypes.CONFIRM_REMOVE_ALBUM:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeAlbum}
            onClose={changeStatus}
            title={'Album'}
          />
        );
      case mediaTypes.OPEN_ALBUM:
        return <Album id={id} onClose={changeStatus} />;
      case mediaTypes.OPEN_VIDEO_COMMENT:
        return (
          <Comment
            id={id}
            type={modalStatus.typeComment}
            onClose={changeStatus}
            idAlbum={modalStatus.idAlbum}
          />
        );
      case news.OPEN_NEWS_FORM:
        return (
          <Modal onClose={changeStatus}>
            <FormNews
              edit={true}
              article={modalStatus.content}
              onClose={() => {
                changeStatus();
              }}
            />
          </Modal>
        );
      case news.OPEN_EDIT_NEWS_FORM:
        return (
          <Modal onClose={changeStatus}>
            <FormNews
              edit={false}
              article={modalStatus.content}
              onClose={() => {
                changeStatus();
              }}
            />
          </Modal>
        );
      case formTypes.EDIT_PLAYER:
        return (
          <Modal onClose={changeStatus}>
            <FormPlayer type="edit" idUser={id} isOpponent={false} />
          </Modal>
        );

      case formTypes.EDIT_PLAYER_OPPONENT:
        return (
          <Modal onClose={changeStatus}>
            <FormPlayer type="edit" idUser={id} isOpponent={true} />
          </Modal>
        );
      case formTypes.EDIT_ACHIEVEMENT:
        return (
          <Modal onClose={changeStatus}>
            <FormAchievement type="edit" id={id} />
          </Modal>
        );
      case formTypes.EDIT_CATEGORY:
        return (
          <Modal onClose={changeStatus}>
            <FormCategory type="edit" id={id} />
          </Modal>
        );
      case news.CONFIRM_REMOVE_ARTICLE:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeArticle}
            onClose={changeStatus}
            title={'article'}
          />
        );
      case about.CONFIRM_REMOVE_PARTNER:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removePartner}
            onClose={changeStatus}
            title={'partner'}
          />
        );
      case about.CONFIRM_REMOVE_STAFF:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeStaff}
            onClose={changeStatus}
            title={'staff'}
          />
        );

      case about.OPEN_ADD_STAFF_FORM:
        return <StaffmateCreator closeCreator={changeStatus} />;
      case about.OPEN_EDIT_PARTNER_FORM:
        return (
          <PartnerCreator edit={true} id={id} closeCreator={changeStatus} />
        );
      case about.OPEN_ADD_PARTNER_FORM:
        return <PartnerCreator edit={false} closeCreator={changeStatus} />;
      case confirmTypes.CONFIRM_REMOVE_GAME:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeGame}
            onClose={changeStatus}
            title={'game'}
          />
        );
      case formTypes.GAME:
        return <FormGame />;

      case formTypes.EDIT_GAME:
        return (
          <Modal onClose={changeStatus}>
            <FormGame type="edit" idGame={id} />
          </Modal>
        );
      case mediaTypes.OPEN_GAME_COMMENT:
        return <Comment type={type} id={id} />;

      case confirmTypes.CONFIRM_REMOVE_TOURNAMENT:
        return (
          <Confirm
            type={type}
            id={id}
            remove={removeTournament}
            onClose={changeStatus}
            title={'tournament'}
          />
        );
      case formTypes.TOURNAMENT:
        return <FormTournament />;

      case formTypes.EDIT_TOURNAMENT:
        return (
          <Modal onClose={changeStatus}>
            <FormTournament type="edit" idTournament={id} />
          </Modal>
        );

      default:
        return null;
    }
  };
  const getModal = () => (
    <Modal
      onClose={() => {
        document.querySelector('body').style.overflow = 'auto';
        changeStatus();
      }}
    >
      {getModalBody()}
    </Modal>
  );
  return open ? getModal() : null;
};

const mapStateToProps = state => ({
  modalStatus: modal(state),
});

const mapDispatchToProps = {
  changeStatus,
  removeCategory,
  removeAchievement,
  removePlayer,
  removeArticle,
  removeAlbum,
  removeVideo,
  removePartner,
  removeStaff,
  removeTeam,
  removeGame,
  removeTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderModal);
