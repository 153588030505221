import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import {
  sendAchievement,
  editAchievement,
} from '../../../redux/operations/achievementOperation';
import { changeStatus } from '../../../redux/actions/modalActions';
import { errorNotification } from '../../../redux/operations/notificationOperation';

import * as validators from '../../../utils/validators';
import * as normalize from '../../../utils/normalize';

import ValidationInput from '../../common/validationComponents/ValidationInput';
import ValidationSelect from '../../common/validationComponents/ValidationSelect';
import RecomendationInfo from '../../Recomendation/Recomendation';
import { modalTypes } from '../../../consts/consts';
import { getAllTeam } from '../../../api/team-service/team-service';
import { getAchievementById } from '../../../api/achievement-service/achievement-service';
import {
  getNormalizeAchievementData,
  putNormalizeAchievementData,
} from '../../../utils/changeDataFormat';

import FileLoader from '../../fileLoader/fileLoader';

import './styles.scss';

let FormAchievement = ({
  handleSubmit = () => {},
  initialize,
  type = 'create',
  id = '',
}) => {
  const [error, setError] = useState(null);
  const [emptyImage, changeEmptyImage] = useState(false);
  const [uploadImages, changeUploadImage] = useState([]);
  const [defaultImage, setDefaultImage] = useState(null);
  const [team, setTeam] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllTeam({ isOpponent: false })
      .then(({ items }) => setTeam(items))
      .catch(({ message }) => dispatch(errorNotification(message)));
  }, [dispatch]);

  useEffect(() => {
    if (type === 'edit') {
      getAchievementById(id)
        .then(achievement => {
          const normaliredAchievementData =
            getNormalizeAchievementData(achievement);
          initialize(normaliredAchievementData);
          setDefaultImage(achievement.photo);
        })
        .catch(({ message }) => dispatch(errorNotification(message)));
    }
  }, []);

  const submitForm = (data = {}) => {
    delete data.endDate;
    if (!uploadImages.length && !defaultImage) {
      return changeEmptyImage(true);
    }

    const normalizedData = putNormalizeAchievementData({
      ...data,
      photo: uploadImages[0],
    });

    type === 'edit'
      ? dispatch(editAchievement(id, normalizedData))
      : dispatch(sendAchievement(normalizedData));
  };

  const callbackError = value => {
    setError(value);
  };

  const callbackValue = value => {
    changeUploadImage(value);
    changeEmptyImage(false);
    setDefaultImage('');
  };

  return (
    <form className={'form-achievement'} onSubmit={handleSubmit(submitForm)}>
      {emptyImage && (
        <p className={'form-achievement__error'}>Need to add an image</p>
      )}
      {error && <p className={'form-achievement__error'}>{error}</p>}
      <RecomendationInfo
        position="bottom"
        text="Recommendation: upload images no more than 415px width and 233px high"
      />
      <FileLoader
        errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
        callbackError={callbackError}
        callbackValue={callbackValue}
        defaultImage={defaultImage ? [defaultImage] : []}
      />
      <div>
        <label>Title</label>
        <Field
          className={'form-achievement__input'}
          name="title"
          component={ValidationInput}
          type="text"
          validate={[validators.required, validators.maxLength64]}
          placeholder="Tournament name"
        />
      </div>
      <div>
        <label>Location of the tournament</label>
        <Field
          className={'form-achievement__input'}
          name="location"
          component={ValidationInput}
          type="text"
          validate={[validators.required]}
          placeholder="location"
        />
      </div>
      <div>
        <label>Type</label>
        <Field
          className={'form-achievement__input'}
          name="kind"
          component={ValidationSelect}
          type="number"
          validate={[validators.required]}
          placeholder="Place №"
        >
          <option></option>
          <option>ONLINE</option>
          <option>OFFLINE</option>
        </Field>
      </div>
      <div>
        <label>Prize pool $ </label>
        <Field
          min="0"
          className={'form-achievement__input'}
          name="prize"
          component={ValidationInput}
          type="text"
          validate={[
            validators.number,
            validators.required,
            validators.minValue1,
          ]}
          placeholder="prize pool $"
          normalize={(...args) => normalize.normalizeNumber(...args)}
          inputmode="numeric"
        />
      </div>
      <div>
        <label>Date</label>
        <Field
          className={'form-event__input'}
          name="dateTime"
          component={ValidationInput}
          type="date"
          validate={[validators.required]}
          placeholder="startDate"
        />
      </div>
      <div>
        <label>Team</label>
        <Field
          className={'form-event__input'}
          name="team"
          component={ValidationSelect}
          validate={[validators.required]}
          placeholder="our team"
        >
          <option></option>
          {team.map(({ name, _id: id }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Field>
      </div>

      <div className={'form-achievement__actions'}>
        <button className="button" type="submit">
          submit
        </button>
        <button
          onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
          className="button"
          type="button"
        >
          cancel
        </button>
      </div>
    </form>
  );
};

FormAchievement = reduxForm({
  form: 'achievement',
})(FormAchievement);

export default FormAchievement;
