import types from '../types/errorHandlerReducers';

const initialState = {
  errorMessage: '',
};

const ErrorHandlerReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADD_ERROR_TEXT:
      return { ...state, errorMessage: payload };
    case types.REMOVE_ERROR_TEXT:
      return { ...state, errorMessage: initialState.errorMessage };
    default:
      return state;
  }
};

export default ErrorHandlerReducers;
