import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

import { ReactComponent as InformationIcon } from '../../assets/image/info.svg';
import './styles.scss';

const RecomendationInfo = ({ text = '', position = 'right' }) => {
  return (
    <div className="recomendation">
      <Tooltip message={text} position={position}>
        <InformationIcon className="recomendation-icon" />
      </Tooltip>
    </div>
  );
};

export default RecomendationInfo;
