import { useState } from 'react';
import { useDispatch } from 'react-redux';

import FileLoader from '../fileLoader/fileLoader';
import TagsSelector from '../tagsSelector/tagsSelector';
import { createAlbum } from '../../redux/operations/mediaOperations';

import './styles.scss';

const CreatorAlbum = ({ close }) => {
  const [images, setImages] = useState([]);
  const [tag, setTag] = useState([]);
  const [titleEn, setTitleEn] = useState('');
  const [titleRu, setTitleRU] = useState('');
  const [titleAz, setTitleAz] = useState('');

  const dispatch = useDispatch();

  const isValidFormData =
    titleEn.trim() &&
    titleRu.trim() &&
    titleAz.trim() &&
    tag.length &&
    images.length;

  const createAlbumFormData = () => {
    const formData = new FormData();

    images.forEach(img => {
      formData.append('photos', img);
    });

    formData.append('tags', JSON.stringify(tag));
    formData.append('title-en', titleEn);
    formData.append('title-ru', titleRu);
    formData.append('title-az', titleAz);

    return formData;
  };

  const saveAlbum = () => {
    if (isValidFormData) {
      const formData = createAlbumFormData();
      dispatch(createAlbum(formData));
      close();
    }
  };

  return (
    <div className="galleryMedia">
      <div className="galleryMedia__body">
        <div className="galleryMedia__input-container">
          <input
            className="input galleryMedia__input"
            placeholder={'titleEn'}
            onChange={({ target: { value } }) => setTitleEn(value)}
          />
          <input
            className="input galleryMedia__input"
            placeholder={'titleRu'}
            onChange={({ target: { value } }) => setTitleRU(value)}
          />
          <input
            className="input galleryMedia__input"
            placeholder={'titleAz'}
            onChange={({ target: { value } }) => setTitleAz(value)}
          />
          <TagsSelector setTag={setTag} />
        </div>
        <div className="galleryMedia__container">
          <FileLoader
            errorInfo={{ quantity: 50, size: 10000000, type: 'image' }}
            callbackValue={img => setImages(img)}
            callbackError={e => console.log(e)}
            type={''}
          />
        </div>
      </div>
      <div className="galleryMedia__key-container">
        <div
          className={isValidFormData ? 'button' : 'button button-disabled'}
          onClick={saveAlbum}
        >
          save
        </div>
        <div className={'button'} onClick={close}>
          cancel
        </div>
      </div>
    </div>
  );
};

export default CreatorAlbum;
