import React from 'react';

import { modalTypes } from '../../consts/consts';

import './styles.scss';

const Confirm = ({
  title,
  onClose,
  remove,
  type,
  id,
  typeVideo,
  isOpponent,
}) => {
  return (
    <div className={'confirm'}>
      <div>
        <p className={'confirm-title'}>
          Are you sure you want to delete this {title}?
        </p>
      </div>
      <div className="confirm-buttons">
        <button
          onClick={() => {
            remove(id, typeVideo ? typeVideo : isOpponent);
            onClose(modalTypes.CLOSE);
          }}
          className="button button-remove"
        >
          delete
        </button>
        <button onClick={() => onClose(modalTypes.CLOSE)} className="button">
          cancel
        </button>
      </div>
    </div>
  );
};

export default Confirm;
