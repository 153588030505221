import { createFormData } from './formDataOperations';
import dateFormat from 'dateformat';

export const normalizeEventDateAndTimeFormat = (dataAndTime, type) => {
  const date = new Date(dataAndTime).toLocaleDateString();
  const hours = new Date(dataAndTime).getUTCHours();
  const minutes = new Date(dataAndTime).getUTCMinutes();
  const chekQuantityHours = hours < 10 ? `0${hours}` : hours;
  const chekQuantityMinutes = minutes < 10 ? `0${minutes}` : minutes;

  if (type === 'edit') {
    return `${dateFormat(
      dataAndTime,
      'yyyy-mm-dd',
    )}T${chekQuantityHours}:${chekQuantityMinutes}`;
  }

  return `${date} ${chekQuantityHours}:${chekQuantityMinutes}`;
};

export const postNormalizeData = data => {
  const cloneData = Object.assign({}, data);
  const newData = removeEmptyFields(cloneData);

  return createFormData(newData);
};

export const normalizeData = data => {
  const cloneData = Object.assign({}, data);
  const newData = removeEmptyFields(cloneData);

  return createFormData(newData);
};

export const putNormalizePlayerData = data => {
  const cloneData = Object.assign({}, data);

  const optional = ['vk', 'instagram', 'facebook', 'twitter', 'youtube'];

  // this is a temporary solution. We do not have functionality for adding photos, so we don`t send photos
  delete cloneData.photos;
  //

  const newData = removeEmptyFields(cloneData, optional);
  return createFormData(newData);
};

export const getNormalizePlayerData = data => {
  const cloneData = Object.assign({}, data);

  const firstNameLocalization = normalizeLocalizationData(
    cloneData.name.first,
    'first',
  );
  const lastNameLocalization = normalizeLocalizationData(
    cloneData.name.last,
    'last',
  );
  const bioLocalization = normalizeLocalizationData(cloneData.bio, 'bio');

  const newData = {
    ...cloneData,
    ...firstNameLocalization,
    ...lastNameLocalization,
    ...bioLocalization,
    nick: cloneData.name.nick,
  };

  delete newData.name;
  delete newData._id;
  delete newData.bio;
  delete newData.games;
  newData.games = data.games[0] ? data.games[0]._id : '';
  newData.birthday = newData.birthday
    ? dateFormat(newData.birthday, 'yyyy-mm-dd')
    : undefined;
  newData.experience = newData.experience
    ? dateFormat(newData.experience, 'yyyy-mm-dd')
    : undefined;
  newData.dateInGMT = newData.dateInGMT
    ? dateFormat(newData.dateInGMT, 'yyyy-mm-dd')
    : undefined;

  return newData;
};

export const putNormalizeGame = game => {
  const cloneGame = Object.assign({}, game);

  const normalizedObj = {
    ...cloneGame,
    date: normalizeEventDateAndTimeFormat(cloneGame.date),
  };

  return normalizedObj;
};

export const getNormalizeGameData = game => {
  const cloneGame = Object.assign(
    { discipline: {}, firstTeam: {}, secondTeam: {} },
    game,
  );

  const normalizedObj = {
    title: cloneGame.title,
    video: cloneGame.video,
    description: cloneGame.description,
    date: normalizeEventDateAndTimeFormat(cloneGame.date, 'edit'),
    discipline: cloneGame.discipline._id,
    firstTeam: cloneGame.firstTeam?._id,
    secondTeam: cloneGame.secondTeam?._id,
    photo: cloneGame.photo,
  };

  return normalizedObj;
};

export const getNormalizeEventData = ({ items, count }) => {
  const normalizeData = items.reduce((acc, current) => {
    const { date } = current;
    const normalizeDate = normalizeEventDateAndTimeFormat(date);
    const newItem = { ...current, date: normalizeDate };
    acc.push(newItem);
    return acc;
  }, []);

  return { items: normalizeData, count };
};

const removeEmptyFields = (obj, optional) => {
  const newObj = Object.assign({}, obj);

  for (let key in newObj) {
    if (key === 'isOpponent') {
      continue;
    }
    if (optional && optional.length && optional.includes(key)) {
      continue;
    }
    if (!Boolean(newObj[key])) {
      delete newObj[key];
    }
  }
  return newObj;
};

const normalizeLocalizationData = (data = {}, name = '') => {
  const cloneData = Object.assign({}, data);
  return {
    [name + 'En']: cloneData.en,
    [name + 'Ru']: cloneData.ru,
    [name + 'Az']: cloneData.az,
  };
};

export const putNormalizeTournament = tournament => {
  const cloneTournament = Object.assign({}, tournament);

  const normalizedObj = {
    ...cloneTournament,
    date: normalizeEventDateAndTimeFormat(cloneTournament.date),
  };

  return normalizedObj;
};

export const getNormalizeTournamentData = tournament => {
  const cloneTournament = Object.assign({}, tournament);

  const normalizedObj = {
    logo: cloneTournament.logo,
    background: cloneTournament.background,
    title: cloneTournament.title,
    locationEn: cloneTournament.location?.en,
    locationAz: cloneTournament.location?.az,
    locationRu: cloneTournament.location?.ru,
    descriptionEn: cloneTournament.description?.en,
    descriptionAz: cloneTournament.description?.az,
    descriptionRu: cloneTournament.description?.ru,
    discipline: cloneTournament.discipline._id,
    startDate: normalizeEventDateAndTimeFormat(
      cloneTournament.startDate,
      'edit',
    ),
    endDate: normalizeEventDateAndTimeFormat(cloneTournament.endDate, 'edit'),
    format: cloneTournament.format,
  };

  return normalizedObj;
};

//Achievements

export const getNormalizeAchievementData = data => {
  const clone = Object.assign({}, data);
  delete clone._id;
  clone.dateTime = dateFormat(clone.dateTime, 'yyyy-mm-dd');
  return clone;
};

export const putNormalizeAchievementData = data => {
  const cloneData = Object.assign({}, data);
  const newData = removeEmptyFields(cloneData);

  return createFormData(newData);
};
