import { Field } from 'redux-form';

import Tooltip from '../Tooltip/Tooltip';

import { ReactComponent as InformationIcon } from '../../assets/image/info.svg';
import './styles.scss';

function LocalizationTabs({ config = {}, syncErrors = {}, labelText = '' }) {
  const { listOfFieldsName, listOfFields } = config;

  const checkValidationKeys = (names, errors) => {
    const errorsKeys = Object.keys(errors);

    return names.reduce((acc, current) => {
      return errorsKeys.includes(current) ? [...acc, current] : acc;
    }, []);
  };

  const errorsInformation = checkValidationKeys(listOfFieldsName, syncErrors);

  return (
    <div>
      <div className="localization-tabs__head">
        <label>{labelText}</label>
        <div className="localization-tabs__head-info">
          {errorsInformation.length ? (
            <Tooltip
              message={`${labelText} must be completed in all languages`}
              position={'bottom'}
            >
              <InformationIcon className="localization-tabs__icon" />
            </Tooltip>
          ) : null}
        </div>
      </div>

      <div className="container">
        <div className="content-tabs">
          {listOfFields.map(
            (
              { name, component, placeholder, className, validate, type },
              index,
            ) => (
              <div key={name + index}>
                <Field
                  name={name}
                  component={component}
                  type={type}
                  placeholder={placeholder}
                  className={className}
                  validate={validate}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
}

export default LocalizationTabs;
