import { authenticationReducer } from './authenticationReducer';
import { combineReducers } from 'redux';
import { bannerReducer } from './banerReducer';
import { reducer as formReducer } from 'redux-form';
import modalReducer from './modalReducer';
import achievementReducer from './achievementReducer';
import playerReducer from './playerReducer';
import categoryReducer from './categoryReducer';
import newsReducers from './newsReducers';
import mediaReducers from './mediaReducers';
import teamReducer from './teamReducer';
import aboutReducers from './aboutReducers';
import ErrorHandlerReducers from './errorHandlerReducers';
import eventReducer from './eventReducer';
import notifyReducer from './notifyReducer';
import shopReducer from './shopReducer';

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  banner: bannerReducer,
  form: formReducer,
  news: newsReducers,
  modal: modalReducer,
  achievements: achievementReducer,
  players: playerReducer,
  categories: categoryReducer,
  media: mediaReducers,
  team: teamReducer,
  event: eventReducer,
  about: aboutReducers,
  errorHandler: ErrorHandlerReducers,
  notify: notifyReducer,
  shop: shopReducer,
});
