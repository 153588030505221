import types from '../types/gameTypes';

export const postGameRequest = () => ({
  type: types.POST_GAME_REQUEST,
});

export const postGameSuccess = (data = {}) => ({
  type: types.POST_GAME_SUCCESS,
  payload: data,
});

export const postGameError = (error = '') => ({
  type: types.POST_GAME_ERROR,
  payload: error,
});

export const getGameListRequest = () => ({
  type: types.GET_GAME_LIST_REQUEST,
});

export const getGameListSuccess = (data = {}) => ({
  type: types.GET_GAME_LIST_SUCCESS,
  payload: data,
});

export const getGameListError = (error = '') => ({
  type: types.GET_GAME_LIST_ERROR,
  payload: error,
});

export const getGameRequest = () => ({
  type: types.GET_GAME_REQUEST,
});

export const getGameSuccess = (data = {}) => ({
  type: types.GET_GAME_SUCCESS,
  payload: data,
});

export const getGameError = (error = '') => ({
  type: types.GET_GAME_ERROR,
  payload: error,
});

export const deleteGameRequest = () => ({
  type: types.DELETE_GAME_REQUEST,
});

export const deleteGameSuccess = id => ({
  type: types.DELETE_GAME_SUCCESS,
  payload: id,
});

export const deleteGameError = (error = '') => ({
  type: types.DELETE_GAME_ERROR,
  payload: error,
});

export const deleteGameCommentRequest = () => ({
  type: types.DELETE_GAME_COMMENT_REQUEST,
});

export const deleteGameCommentSuccess = list => ({
  type: types.DELETE_GAME_COMMENT_SUCCESS,
  payload: list,
});

export const deleteGameCommentError = (error = '') => ({
  type: types.DELETE_GAME_COMMENT_ERROR,
  payload: error,
});

export const getSortedGameListRequest = () => ({
  type: types.GET_SORTED_GAME_LIST_REQUEST,
});

export const getSortedGameListSuccess = (data = {}) => ({
  type: types.GET_SORTED_GAME_LIST_SUCCESS,
  payload: data,
});

export const getSortedGameListError = (error = '') => ({
  type: types.GET_SORTED_GAME_LIST_ERROR,
  payload: error,
});

export const updateGameSortingParams = (params = {}) => ({
  type: types.UPDATE_GAME_SORTING_PARAMS,
  payload: params,
});

export const editGameRequest = () => ({
  type: types.EDIT_GAME_REQUEST,
});

export const editGameSuccess = (data = {}) => ({
  type: types.EDIT_GAME_SUCCESS,
  payload: data,
});

export const editGameError = (error = '') => ({
  type: types.EDIT_GAME_ERROR,
  payload: error,
});

export const editGameLogoRequest = () => ({
  type: types.EDIT_GAME_LOGO_REQUEST,
});

export const editGameLogoSuccess = (data = {}) => ({
  type: types.EDIT_GAME_LOGO_SUCCESS,
  payload: data,
});
export const editGameLogoError = (error = '') => ({
  type: types.EDIT_GAME_LOGO_ERROR,
  payload: error,
});

export const changeGameInnerFormStatus = status => ({
  type: types.CHANGE_GAME_INNER_FORM_STATUS,
  payload: status,
});
