import { reset } from 'redux-form';
import { changeStatus } from '../actions/modalActions';
import * as tournamentActions from '../actions/tournamentActions';
import * as api from '../../api/event-service/event-service';
import {
  tournamentSortingParamsSelector,
  tournamentCountSelector,
  tournamentListSelector,
} from '../selectors/selectors';
import { updateEventList } from '../../utils/updateEventList';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';
import { createFormData } from '../../utils/formDataOperations';

export const fetchTournamentList = () => dispatch => {
  dispatch(tournamentActions.getTournamentListRequest());
  return api
    .getTournamentList()
    .then(response => {
      dispatch(tournamentActions.getTournamentListSuccess(response));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const fetchTournament = id => dispatch => {
  dispatch(tournamentActions.getTournamentRequest());

  return api
    .getTournamentById(id)
    .then(response => {
      return response;
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};

export const sortTournament = (type, offset) => dispatch => {
  dispatch(tournamentActions.getSortedTournamentListRequest());
  dispatch(tournamentActions.updateTournamentSortingParams({ type, offset }));
  return api
    .getSortedTournamentList(type, offset)
    .then(response => {
      const payloadData = {
        ...response,
        pageNumber: Math.ceil(response.count / 15) - 1,
      };

      dispatch(tournamentActions.getSortedTournamentListSuccess(payloadData));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    })
    .finally(() => dispatch(reset('tournament')));
};

export const updateTournamentsSortingParams = params => (
  dispatch,
  getState,
) => {
  dispatch(tournamentActions.updateTournamentSortingParams(params));

  const { sortType, offset } = tournamentSortingParamsSelector(getState());

  dispatch(sortTournament(sortType, offset));
};

export const sendTournament = (data = {}, { logo, background }) => (
  dispatch,
  getState,
) => {
  dispatch(tournamentActions.postTournamentRequest());
  const { sortType, offset } = tournamentSortingParamsSelector(getState());

  const formData = createFormData({ logo, background });

  return api
    .postTournament(data)
    .then(tournament => {
      api
        .postTournamentLogo(tournament._id, formData)
        .then(response => {
          dispatch(sortTournament(sortType, offset));
          dispatch(reset('tournament'));
          dispatch(changeStatus(false));
          dispatch(successNotification(successfulMessage.TOURNAMENT_ADDED));
        })
        .catch(({ message }) => {
          dispatch(errorNotification(message));
        });
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const editTournamentAll = (id, data = {}, { logo, background }) => (
  dispatch,
  getState,
) => {
  dispatch(tournamentActions.editTournamentRequest());
  const tournamentsItems = tournamentListSelector(getState());

  const cloneTournament = Object.assign({}, data);
  delete cloneTournament.background;
  delete cloneTournament.logo;

  const formData = createFormData({ logo, background });

  api
    .postTournamentLogo(id, formData)
    .then(() => {
      api
        .putTournament(id, cloneTournament)
        .then(tournament => {
          dispatch(
            tournamentActions.editTournamentSuccess(
              updateEventList(tournament, tournamentsItems),
            ),
          );
          dispatch(changeStatus(false));
          dispatch(reset('tournament'));
          dispatch(successNotification(successfulMessage.TOURNAMENT_CHANGED));
        })
        .catch(({ message }) => {
          dispatch(errorNotification(message));
        });
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const editTournamentForm = (id, data) => (dispatch, getState) => {
  const tournamentsItems = tournamentListSelector(getState());

  dispatch(tournamentActions.editTournamentRequest());

  const cloneTournament = Object.assign({}, data);
  delete cloneTournament.logo;
  delete cloneTournament.background;

  return api
    .putTournament(id, cloneTournament)
    .then(tournament => {
      dispatch(
        tournamentActions.editTournamentSuccess(
          updateEventList(tournament, tournamentsItems),
        ),
      );
      dispatch(changeStatus(false));
      dispatch(reset('tournament'));
      dispatch(successNotification(successfulMessage.TOURNAMENT_CHANGED));
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};

export const editTornamentLogo = (id, { logo, background }) => (
  dispatch,
  getState,
) => {
  dispatch(tournamentActions.editTournamentRequest());
  const tournamentsItems = tournamentListSelector(getState());

  const formData = createFormData({ logo, background });

  return api
    .postTournamentLogo(id, formData)
    .then(tournament => {
      dispatch(
        tournamentActions.editTournamentSuccess(
          updateEventList(tournament, tournamentsItems),
        ),
      );
      dispatch(changeStatus(false));
      dispatch(reset('tournament'));
      dispatch(successNotification(successfulMessage.TOURNAMENT_LOGO_CHANGED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removeTournament = id => (dispatch, getState) => {
  dispatch(tournamentActions.deleteTournamentRequest());
  const { sortType, offset } = tournamentSortingParamsSelector(getState());
  const count = tournamentCountSelector(getState());
  const isFirstPage = offset === 0;
  const isDeleteLastEventOfPage = count - 1 === offset;

  return api
    .deleteTournament(id)
    .then(() => {
      if (!isFirstPage && isDeleteLastEventOfPage) {
        const newOffset = offset - 15;
        dispatch(sortTournament(sortType, newOffset));
        dispatch(
          tournamentActions.updateTournamentSortingParams({
            sortType,
            offset: newOffset,
          }),
        );
        dispatch(tournamentActions.deleteTournamentSuccess());
      } else {
        dispatch(sortTournament(sortType, offset));
        dispatch(successNotification(successfulMessage.TOURNAMENT_REMOVED));
        dispatch(tournamentActions.deleteTournamentSuccess());
      }
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
      dispatch(tournamentActions.deleteTournamentError(message));
    });
};
