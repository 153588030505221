import { useEffect } from 'react';
import { connect } from 'react-redux';
import { categoriesSelector } from '../../redux/selectors/selectors';
import { changeStatus } from '../../redux/actions/modalActions';
import {
  fetchCategories,
  removeCategory,
} from '../../redux/operations/categoryOperation';

import CategoriesList from './CategoriesList';

const CategoriesListContainer = ({
  categoriesData,
  fetchCategories,
  removeCategory,
  changeStatus,
}) => {
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <CategoriesList
      categoriesData={categoriesData}
      removeCategory={removeCategory}
      changeStatus={changeStatus}
    />
  );
};

const mapStateToProps = state => ({
  categoriesData: categoriesSelector(state),
});

const mapDispatchToProps = {
  changeStatus,
  fetchCategories,
  removeCategory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesListContainer);
