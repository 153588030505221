import types from '../types/playerTypes';

const initialState = { count: 0, items: [] };

const playerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PLAYERS_SUCCESS:
      return payload;
    case types.ADD_PLAYER_SUCCESS:
      return [...state, payload];
    case types.DELETE_PLAYER_SUCCESS:
      return state.filter(player => player._id !== payload);
    case types.EDIT_PLAYER_SUCCESS:
      return state.map(player =>
        player._id === payload._id ? payload : player,
      );

    default:
      return state;
  }
};

export default playerReducer;
