import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from 'app/redux/actions/authenticationActions';

import { navigationList, activeNavigationStyles } from './config';

import logo from 'app/assets/image/new-logo.svg';
import logoutIcon from 'app/assets/image/logout.png';

import './styles.scss';

const LeftPanel = ({ logout }) => {
  const location = useLocation();
  const history = useHistory();

  const headerView = type => location.pathname.includes(type);

  return (
    <div className="leftPanel">
      <div>
        <img className="leftPanel__logo" src={logo} alt="gmt" />
      </div>
      <ul>
        {Object.values(navigationList).map(({ text, link, key }) => (
          <Link key={key} to={link}>
            <p
              className="leftPanel__item"
              style={headerView(key) ? activeNavigationStyles : {}}
            >
              {text}
            </p>
          </Link>
        ))}

        <li className="leftPanel__logout" onClick={() => logout(history)}>
          <p className="leftPanel__logout-text">Logout</p>
          <img
            className="leftPanel__logout-icon"
            src={logoutIcon}
            alt="logout"
          />
        </li>
      </ul>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    logout: history => dispatch(logout(history)),
  };
};
export default connect(null, mapDispatchToProps)(LeftPanel);
