import types from '../types/achievementTypes';

const achievementReducer = (state = [], { type, payload }) => {
  switch (type) {
    case types.GET_ACHIEVEMENT_SUCCESS:
      return payload;
    case types.ADD_ACHIEVEMENT_SUCCESS:
      return [...state, payload];
    case types.DELETE_ACHIEVEMENT_SUCCESS:
      return state.filter(achievement => achievement._id !== payload);
    case types.EDIT_ACHIEVEMENT_SUCCESS:
      return state.map(achievement =>
        achievement._id === payload._id ? payload : achievement,
      );

    default:
      return state;
  }
};

export default achievementReducer;
