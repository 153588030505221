import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import FileLoader from '../../fileLoader/fileLoader';

import * as validators from '../../../utils/validators';
import { getNormalizeGameData } from '../../../utils/changeDataFormat';
import ValidationInput from '../../common/validationComponents/ValidationInput';
import ValidationSelect from '../../common/validationComponents/ValidationSelect';
import { getCategory } from '../../../api/category-service/category-service';
import { getAllTeam } from '../../../api/team-service/team-service';
import { modalTypes } from '../../../consts/consts';
import { changeStatus } from '../../../redux/actions/modalActions';
import { gameInnerFormSelector } from '../../../redux/selectors/selectors';
import { changeGameInnerFormStatus } from '../../../redux/actions/gameActions';
import { errorNotification } from '../../../redux/operations/notificationOperation';

import {
  sendGame,
  fetchGame,
  editGame,
} from '../../../redux/operations/gameOperation';

import { getErrorMessage } from '../../../redux/actions/errorHandlerAction';

import './styles.scss';
import FormTeam from '../FormTeam/FormTeam';
import RecomendationInfo from '../../Recomendation/Recomendation';

let FormGame = ({ handleSubmit, initialize, type, idGame }) => {
  const [category, setCategory] = useState([]);
  const [ourTeamList, setOurTeamList] = useState([]);
  const [opponentTeam, setOpponentTeam] = useState([]);
  const [error, setError] = useState(null);
  const [uploadImages, changeUploadImage] = useState([]);
  const [emptyImage, changeEmptyImage] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const isOpenInnerForm = useSelector(state => gameInnerFormSelector(state));
  const dispatch = useDispatch();
  useEffect(() => {
    if (type) {
      dispatch(fetchGame(idGame))
        .then(game => {
          const normalizedData = getNormalizeGameData(game);
          setDefaultImage(normalizedData.photo);
          delete normalizedData.photo;
          return initialize(normalizedData);
        })
        .catch(({ message }) => dispatch(getErrorMessage(message)));
    }

    getCategory()
      .then(({ items }) => setCategory(items))
      .catch(({ message }) => dispatch(errorNotification(message)));

    getAllTeam({ isOpponent: false, limit: 5000 })
      .then(({ items }) => setOurTeamList(items))
      .catch(({ message }) => dispatch(errorNotification(message)));

    return () => dispatch(changeGameInnerFormStatus(false));
  }, [dispatch, idGame, initialize, type]);

  useEffect(() => {
    getAllTeam({ isOpponent: true, limit: 5000 })
      .then(({ items }) => setOpponentTeam(items))
      .catch(({ message }) => dispatch(errorNotification(message)));
  }, [isOpenInnerForm]);

  const handleSubmitForm = (data = {}) => {
    type
      ? dispatch(editGame(idGame, data, uploadImages[0]))
      : dispatch(sendGame(data, uploadImages));
  };

  //FileLoader
  const callbackError = value => {
    setError(value);
  };

  const callbackValue = value => {
    changeUploadImage(value);
    changeEmptyImage(false);
    setDefaultImage('');
  };

  return (
    <div className="form-event-container">
      {isOpenInnerForm && (
        <div className="form-event__opponent-form">
          <FormTeam isOpponent={true} renderType={'gameFormCreate'} />
        </div>
      )}
      <form className={'form-event'} onSubmit={handleSubmit(handleSubmitForm)}>
        <div>
          <label>Event Name</label>
          <Field
            className={'form-event__input'}
            name="title"
            component={ValidationInput}
            type="text"
            validate={[validators.required]}
            placeholder="event name"
          />
        </div>
        <div>
          <label>Description game event</label>
          <Field
            className={'form-event__input'}
            name="description"
            component={ValidationInput}
            type="text"
            validate={[validators.required]}
            placeholder="description game event"
          />
        </div>
        <div>
          <label>Tag</label>
          <Field
            className={'form-event__input'}
            name="discipline"
            component={ValidationSelect}
            validate={[validators.required]}
            placeholder="select"
          >
            <option></option>
            {category.map(({ name, _id: id }) => (
              <option key={name} value={id}>
                {name}
              </option>
            ))}
          </Field>
        </div>
        <div>
          <label>Video</label>
          <Field
            className={'form-event__input'}
            name="video"
            component={ValidationInput}
            type="url"
            validate={[validators.url]}
            placeholder="link to video"
          />
        </div>
        <div>
          <RecomendationInfo text="Recommendation: upload images no more than 918px wide and 515px" />
          <label>Default image</label>
          <FileLoader
            errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
            callbackError={callbackError}
            callbackValue={callbackValue}
            defaultImage={defaultImage ? [defaultImage] : []}
          />
        </div>
        <div>
          <label>GMT team</label>
          <Field
            className={'form-event__input'}
            name="firstTeam"
            component={ValidationSelect}
            validate={[validators.required]}
            placeholder="our team"
          >
            <option></option>
            {ourTeamList.map(({ name, _id: id }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Field>
        </div>
        <div>
          <label>Opponent team</label>
          <Field
            className={'form-event__input'}
            name="secondTeam"
            component={ValidationSelect}
            validate={[validators.required]}
            placeholder="our team"
          >
            <option></option>
            {opponentTeam.map(({ name, _id: id }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Field>
        </div>
        <button
          className="button"
          type="button"
          onClick={() => dispatch(changeGameInnerFormStatus(true))}
        >
          Add opponent team
        </button>
        <div>
          <label>Date/Time</label>
          <Field
            className={'form-event__input'}
            name="date"
            component={ValidationInput}
            type="datetime-local"
            validate={[validators.required, validators.eventDate]}
            placeholder="date"
          />
        </div>
        <div className={'form-event__actions'}>
          <button className="button" type="submit">
            submit
          </button>
          <button
            onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
            className="button"
            type="button"
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );
};

FormGame = reduxForm({
  form: 'event',
})(FormGame);

export default FormGame;
