const types = {
  POST_GAME_REQUEST: 'POST_GAME_REQUEST',
  POST_GAME_SUCCESS: 'POST_GAME_SUCCESS',
  POST_GAME_ERROR: 'POST_GAME_ERROR',
  GET_GAME_LIST_REQUEST: 'GET_GAME_LIST_REQUEST',
  GET_GAME_LIST_SUCCESS: 'GET_GAME_LIST_SUCCESS',
  GET_GAME_LIST_ERROR: 'GET_GAME_LIST_ERROR',
  GET_GAME_REQUEST: 'GET_GAME_REQUEST',
  GET_GAME_SUCCESS: 'GET_GAME_SUCCESS',
  GET_GAME_ERROR: 'GET_GAME_ERROR',
  GET_SORTED_GAME_LIST_REQUEST: 'GET_SORTED_GAME_LIST_REQUEST',
  GET_SORTED_GAME_LIST_SUCCESS: 'GET_SORTED_GAME_LIST_SUCCESS',
  GET_SORTED_GAME_LIST_ERROR: 'GET_SORTED_GAME_LIST_ERROR',
  DELETE_GAME_REQUEST: 'DELETE_GAME_REQUEST',
  DELETE_GAME_SUCCESS: 'DELETE_GAME_SUCCESS',
  DELETE_GAME_ERROR: 'DELETE_GAME_ERROR',
  EDIT_GAME_REQUEST: 'EDIT_GAME_REQUEST',
  EDIT_GAME_SUCCESS: 'EDIT_GAME_SUCCESS',
  EDIT_GAME_ERROR: 'EDIT_GAME_ERROR',
  EDIT_GAME_LOGO_REQUEST: 'EDIT_GAME_LOGO_REQUEST',
  EDIT_GAME_LOGO_SUCCESS: 'EDIT_GAME_LOGO_SUCCESS',
  EDIT_GAME_LOGO_ERROR: 'EDIT_GAME_LOGO_ERROR',
  UPDATE_GAME_SORTING_PARAMS: 'UPDATE_GAME_SORTING_PARAMS',
  DELETE_GAME_COMMENT_REQUEST: 'DELETE_GAME_COMMENT_REQUEST',
  DELETE_GAME_COMMENT_SUCCESS: 'DELETE_GAME_COMMENT_SUCCESS',
  DELETE_GAME_COMMENT_ERROR: 'DELETE_GAME_COMMENT_ERROR',
  CHANGE_GAME_INNER_FORM_STATUS: 'CHANGE_GAME_INNER_FORM_STATUS',
};

export default types;
