const types = {
  ADD_TEAM_REQUEST: 'ADD_TEAM_REQUEST',
  ADD_TEAM_SUCCESS: 'ADD_TEAM_SUCCESS',
  ADD_TEAM_ERROR: 'ADD_TEAM_ERROR',
  ADD_OPPONENT_TEAM_REQUEST: 'ADD_OPPONENT_TEAM_REQUEST',
  ADD_OPPONENT_TEAM_SUCCESS: 'ADD_OPPONENT_TEAM_SUCCESS',
  ADD_OPPONENT_TEAM_ERROR: 'ADD_OPPONENT_TEAM_ERROR',
  GET_ALL_TEAMS_REQUEST: 'GET_ALL_TEAMS_REQUEST',
  GET_ALL_TEAMS_SUCCESS: 'GET_ALL_TEAMS_SUCCESS',
  GET_ALL_TEAMS_ERROR: 'GET_ALL_TEAMS_ERROR',
  GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
  GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
  GET_TEAM_ERROR: 'GET_TEAM_ERROR',
  DELETE_TEAM_REQUEST: 'DELETE_TEAM_REQUEST',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_ERROR: 'DELETE_TEAM_ERROR',
};

export default types;
