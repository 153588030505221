export const required = value => (value ? null : 'Required');

export const url = value => {
  const regex =
    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  return value && !regex.test(value) ? 'Please enter valid URL.' : undefined;
};

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const latinAlphabetLetters = value =>
  value && /[^a-zA-Z ]/i.test(value)
    ? 'Link label should consist only latin characters'
    : undefined;

export const latinAlphabetLettersAndNumbers = value =>
  value && /[^a-zA-Z-0-9 ]/i.test(value)
    ? 'Link label should consist only latin characters or (and) numbers'
    : undefined;

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue1 = minValue(1);

export const minLength2 = minLength(2);
export const maxLength16 = maxLength(16);
export const maxLength64 = maxLength(64);

export const maxLength14 = maxLength(14);
export const maxLength2000 = maxLength(2000);

export const date = value => {
  const varDate = new Date(value); //dd-mm-YYYY
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (varDate >= today) {
    return 'Invalid date';
  } else {
    return undefined;
  }
};

export const eventDate = value => {
  const varDate = new Date(value); //dd-mm-YYYY
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (varDate <= today) {
    return 'Invalid date';
  } else {
    return undefined;
  }
};

export const spaces = string =>
  /\s/g.test(string) ? `spaces are not allowed in the string` : undefined;
