import types from '../types/modalTypes';

export const changeStatus = (status = false) => ({
  type: types.MODAL_STATUS,
  payload: status,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
