import PlatersList from '../../component/PlayersList/PlayerListContainer';
// import AchievementList from '../../component/AchievementList/AchievementListContainer';
import TeamList from '../../component/TeamList/TeamListContainer';

import './styles.scss';

const Team = () => {
  return (
    <div className="teams">
      <PlatersList />
      {/* <AchievementList /> */}
      <TeamList isOpponent={false} />
    </div>
  );
};

export default Team;
