import ValidationInput from '../../common/validationComponents/ValidationInput';
import ValidationTextarea from '../../common/validationComponents/ValidationTextarea';

import * as validators from '../../../utils/validators';

export const firstConfig = {
  listOfFieldsName: ['firstEn'],
  listOfFields: [
    {
      className: 'form-player__input',
      name: 'firstEn',
      component: ValidationInput,
      type: 'text',
      validate: [
        validators.required,
        validators.minLength2,
        validators.maxLength14,
        validators.spaces,
      ],
      placeholder: 'first name EN',
    },
  ],
};
export const lastConfig = {
  listOfFieldsName: ['lastEn'],
  listOfFields: [
    {
      className: 'form-player__input',
      name: 'lastEn',
      component: ValidationInput,
      type: 'text',
      validate: [
        validators.required,
        validators.minLength2,
        validators.maxLength14,
        validators.spaces,
      ],
      placeholder: 'last name EN',
    },
  ],
};

export const bioConfig = {
  listOfFieldsName: ['bioEn'],
  listOfFields: [
    {
      className: 'form-player__textarea',
      name: 'bioEn',
      component: ValidationTextarea,
      type: 'text',
      validate: [validators.maxLength2000],
      placeholder: 'BIO description EN',
    },
  ],
};
