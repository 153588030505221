import React from 'react';

import TeamList from '../../component/TeamList/TeamListContainer';
import PlatersList from '../../component/PlayersList/PlayerListContainer';

import './styles.scss';

const Opponents = (isOpenModal, removePlayer) => {
  return (
    <div>
      <PlatersList isOpponent={true} />
      <TeamList isOpponent={true} />
    </div>
  );
};

export default Opponents;
