import {
  USER_LOGIN_ERROR,
  USER_LOGOUT,
  USER_SAVE_TOKEN,
  USER_UPDATE_STATUS,
} from '../types/types';

import { login, updateToken } from '../../api/auth-service/auth-service';

export function updateUserStatus(status) {
  return {
    type: USER_UPDATE_STATUS,
    payload: status,
  };
}

export function logout() {
  updateUserStatus(false);
  localStorage.clear();
  return {
    type: USER_LOGOUT,
  };
}

export function createErrorResponse(error) {
  return {
    type: USER_LOGIN_ERROR,
    payload: error,
  };
}

const update = refreshToken => dispatch => {
  updateToken({ refreshToken })
    .then(response => {
      localStorage.setItem('user', JSON.stringify(response));
      dispatch(startRefreshTimeout());
    })
    .catch(() => {
      dispatch(logout());
      dispatch(updateUserStatus(false));
    });
};

export const startRefreshTimeout = () => dispatch => {
  const data = localStorage.getItem('user');

  if (!data) return dispatch(updateUserStatus(false));

  dispatch(updateUserStatus(true));

  const { expirationDate, refreshToken } = JSON.parse(data);

  const expiration = new Date(expirationDate);
  const current = new Date();
  const expirationTime = expiration - current;

  window.updateTokenTimeoutId = setTimeout(() => {
    dispatch(update(refreshToken));
  }, expirationTime - 900000);
};

const saveToken = tokens => dispatch => {
  localStorage.setItem('user', JSON.stringify(tokens));

  return {
    type: USER_SAVE_TOKEN,
    payload: tokens,
  };
};

export function loginUser(userData) {
  return async dispatch => {
    login(userData)
      .then(body => {
        dispatch(saveToken(body));

        // refresh fn
        dispatch(startRefreshTimeout());

        dispatch(updateUserStatus(true));
      })
      .catch(({ message }) => {
        dispatch(createErrorResponse(message));
        setTimeout(() => {
          dispatch(createErrorResponse(null));
        }, 5000);
      });
  };
}
