import { DOWNLOAD_BANNER, SELECT_BANNER, UPLOAD_BANNER } from '../types/types';

const initialState = {
  allBanner: [],
  uploadBanner: [],
};

export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_BANNER:
      return { ...state, allBanner: action.payload };
    case SELECT_BANNER:
      return { ...state, allBanner: action.payload };
    case UPLOAD_BANNER:
      return state;
    default:
      return state;
  }
};
