import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TeamPlayers from '../TeamPlayers/TeamPlayers';
import {
  getTeam,
  deletePlayerFromTeam,
  addPlayerToTeam,
} from '../../api/team-service/team-service';

import { errorNotification } from '../../redux/operations/notificationOperation';
import { changeStatus } from '../../redux/actions/modalActions';
import { getPlayers } from '../../api/players-service/players-service';

const TeamPlayersContainer = ({
  idTeam,
  changeStatus,
  errorNotification,
  isOpponent,
}) => {
  const [teamName, setTeamName] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [limit, setLimit] = useState(15);
  const [players, setPlayers] = useState({ items: [], count: 0 });

  useEffect(() => {
    getTeam(idTeam)
      .then(({ players, name }) => {
        const selectedPlayersList = players.reduce((acc, current) => {
          const { _id } = current;
          return [...acc, _id];
        }, []);

        setSelectedPlayers(selectedPlayersList);
        setTeamName(name);
      })
      .catch(({ message }) => errorNotification(message));
  }, [idTeam, errorNotification]);

  useEffect(() => {
    getPlayers({ isOpponent })
      .then(r => {
        setPlayers(r);
      })
      .catch(({ message }) => errorNotification(message));
  }, [errorNotification]);

  //handlers
  const handleRemovePlayer = id => {
    deletePlayerFromTeam(idTeam, id).then(() => {
      const newArr = selectedPlayers.filter(item => item !== id);
      setSelectedPlayers(newArr);
    });
  };

  const handleAddPlayer = id => {
    addPlayerToTeam(idTeam, id).then(() => {
      setSelectedPlayers([...selectedPlayers, id]);
    });
  };

  const handlePlayerChange = (status, id) => {
    status === false && handleRemovePlayer(id);
    status === true && handleAddPlayer(id);
  };

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (
      Math.ceil(scrollHeight) - Math.ceil(scrollTop) ===
        Number.parseInt(clientHeight) &&
      players.count > limit
    ) {
      getPlayers({ isOpponent, offset: limit })
        .then(r => {
          setPlayers(({ items }) => {
            return { count: r.count, items: items.concat(r.items) };
          });
        })
        .catch(({ message }) => errorNotification(message));
      setLimit(prev => prev + 15);
    }
  };
  return (
    <div>
      <TeamPlayers
        changeStatus={changeStatus}
        players={players.items}
        selectedPlayers={selectedPlayers}
        teamName={teamName}
        handleRemovePlayer={handleRemovePlayer}
        handleAddPlayer={handleAddPlayer}
        handlePlayerChange={handlePlayerChange}
        handleScroll={handleScroll}
      />
    </div>
  );
};

const mapDispatchToProps = {
  changeStatus,
  errorNotification,
};

export default connect(null, mapDispatchToProps)(TeamPlayersContainer);
