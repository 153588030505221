import { post } from '../apiService';

export function login(body) {
  return post('/auth/signin', body);
}

export function updateToken(body) {
  return post('/auth/update-token', body);
}
export function logout() {
  return post('/auth/update-token');
}
