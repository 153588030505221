import types from '../types/newsTypes';

const initialState = {
  allNews: {
    items: [],
    count: 0,
  },
  editArticle: {},
  commitsArticle: {},
  activeOffsetVideo: 0,
};

const newsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADD_NEWS_LIST_REQUEST:
      return { ...state, allNews: payload };
    case types.SET_ACTIVE_NEWS_OFFSET:
      return { ...state, activeOffsetVideo: payload };
    case types.ADD_COMMENT_REQUEST:
      return { ...state, allNews: payload };
    default:
      return state;
  }
};

export default newsReducers;
