export const successfulMessage = {
  PLAYER_ADDED: 'Player was successfully added',
  PLAYER_REMOVED: 'Player was successfully removed',
  PLAYER_CHANGED: 'Player was successfully changed',
  TEAM_ADDED: 'Team was successfully added',
  TEAM_REMOVED: 'Team was successfully removed',
  TEAM_EDIT: 'Team was successfully changed',
  CATEGORY_ADDED: 'Category was successfully added',
  CATEGORY_REMOVED: 'Category was successfully removed',
  CATEGORY_CHANGED: 'Category was successfully changed',
  ACHIEVEMENT_ADDED: 'Achievement was successfully added',
  ACHIEVEMENT_CHANGED: 'Achievement was successfully changed',
  ACHIEVEMENT_REMOVED: 'Achievement was successfully removed',
  EVENT_ADDED: 'Event was successfully added',
  EVENT_REMOVED: 'Event was successfully removed',
  PARTNER_ADDED: 'Partner was successfully added',
  PARTNER_REMOVED: 'Partner was successfully removed',
  PARTNER_CHANGED: 'Partner was successfully changed',
  VIDEO_ADDED: 'Video was successfully added',
  VIDEO_REMOVED: 'Video was successfully removed',
  VIDEO_CHANGED: 'Video was successfully changed',
  STREAM_ADDED: 'Stream was successfully added',
  STREAM_REMOVED: 'Stream was successfully removed',
  STREAM_CHANGED: 'Stream was successfully changed',
  ALBUM_ADDED: 'Album was successfully created',
  ALBUM_REMOVED: 'Album was successfully removed',
  ALBUM_CHANGED: 'Album was successfully changed',
  ARTICLE_ADDED: 'Article was successfully added',
  ARTICLE_REMOVED: 'Article was successfully removed',
  ARTICLE_CHANGED: 'Article was successfully changed',
  GAME_ADDED: 'Game was successfully added',
  GAME_REMOVED: 'Game was successfully removed',
  GAME_CHANGED: 'Game was successfully changed',
  TOURNAMENT_ADDED: 'Tournament was successfully added',
  TOURNAMENT_REMOVED: 'Tournament was successfully removed',
  TOURNAMENT_CHANGED: 'Tournament was successfully changed',
  TOURNAMENT_LOGO_CHANGED: 'Tournament logo was successfully changed',
  STAFFMATE_ADDED: 'Staffmate was successfully added',
  STAFFMATE_REMOVED: 'Staffmate was successfully removed',
  STAFFMATE_CHANGED: 'Staffmate was successfully changed',
};
