import React from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { closeErrorMessage } from '../../redux/actions/errorHandlerAction';

const ErrorMessage = ({ errorMessage, clearMessage }) => {
  return (
    <div
      className="error-handler styleKey"
      onClick={() => {
        clearMessage();
      }}
    >
      {errorMessage ? (
        <p className="error-handler-message">{errorMessage}</p>
      ) : (
        <p />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    clearMessage: value => dispatch(closeErrorMessage()),
  };
};
const mapStateToProps = state => ({
  errorMessage: state.errorHandler.errorMessage,
});
export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
