import {
  USER_LOGIN_ERROR,
  USER_LOGOUT,
  USER_SAVE_TOKEN,
  USER_UPDATE_LOGIN,
  USER_UPDATE_STATUS,
} from '../types/types';

const initialState = {
  loginInfo: {
    expirationData: null,
    refreshToken: null,
    token: null,
  },
  userIsLogin: false,
  userInfo: {
    userName: null,
    password: null,
  },
  errorMessage: '',
  tokens: {},
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        ...state,
        userIsLogin: false,
        userInfo: initialState.userIsLogin,
      };
    case USER_UPDATE_STATUS:
      return { ...state, userIsLogin: action.payload };
    case USER_UPDATE_LOGIN:
      return state;
    case USER_LOGIN_ERROR:
      return { ...state, errorMessage: action.payload };
    case USER_SAVE_TOKEN:
      return { ...state, tokens: action.payload };
    default:
      return state;
  }
};
