import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';

import { getPlayers } from '../../api/players-service/players-service';
import { twitchAPI } from '../../api/twitchApi/twitchApi';
import {
  getVideo,
  postVideo,
  putVideo,
  uploadCoverImage,
  deleteCoverImage,
} from '../../api/media-service/media-service';

import { changeStatus, closeModal } from '../../redux/actions/modalActions';
import { getErrorMessage } from '../../redux/actions/errorHandlerAction';
import { initAllVideo } from '../../redux/operations/mediaOperations';
import { createFormData } from '../../utils/formDataOperations';
import {
  successNotification,
  errorNotification,
} from '../../redux/operations/notificationOperation';

import FileLoader from '../../component/fileLoader/fileLoader';
import RecomendationInfo from '../Recomendation/Recomendation';
import TagsSelector from '../tagsSelector/tagsSelector';

import './style.scss';

const UploaderVideos = ({
  id,
  edit,
  changeStatus,
  onClose,
  typeVideo,
  activeOffsetVideo,
  activeOffsetStream,
}) => {
  const [newCategory, setNewCategory] = useState('');
  const [newLink, setNewLink] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [playersOption, setPlayersOption] = useState([]);
  const [players, setPlayers] = useState([]);

  const [titleEn, setTitleEn] = useState('');

  const [twitchUserLogin, setTwitchUserLogin] = useState('');

  const [newPlatform, setPlatform] = useState('YOUTUBE');
  const [validationsInfo, setValidationsInfo] = useState({
    players: false,
    tags: false,
    titleEn: false,
    url: false,
    platform: false,
    descriptionEn: false,
    kind: false,
    cover: false,
    twitchUserLogin: false,
  });
  const [allIsValid, setAllIsValid] = useState(false);
  const [bodyDefault, setBodyDefault] = useState({});
  const [error, setError] = useState('');
  const [uploadImages, changeUploadImage] = useState([]);
  const [defaultImage, setDefaultImage] = useState(null);
  const [emptyImage, changeEmptyImage] = useState(false);

  const uploadCoverTypes = ['STREAM', 'STREAM-EDIT'];

  const dispatch = useDispatch();

  useEffect(() => {
    getPlayers({ isOpponent: false, offset: 0, limit: 1000 })
      .then(({ items }) =>
        setPlayersOption(
          items.map(({ _id, name: { nick } }) => {
            return {
              value: _id,
              label: nick,
            };
          }),
        ),
      )
      .catch(({ message }) => dispatch(getErrorMessage(message)));

    if (edit) {
      getVideo(id)
        .then(item => {
          const {
            tags,
            players,
            description: { en: descriptionEn },
            title: { en: titleEn },
            url,
            platform,
            cover,
            twitchUserLogin,
          } = item;

          setDefaultImage(cover);
          setBodyDefault(item);
          setNewCategory(tags);
          setPlayers(players);
          setNewLink(url);
          setDescriptionEn(descriptionEn);
          setTitleEn(titleEn);
          setPlatform(platform);
          setTwitchUserLogin(twitchUserLogin);
        })
        .catch(({ message }) => dispatch(getErrorMessage(message)));
    }
  }, []);

  const setTag = allTeg => {
    validations(allTeg, 'tags');
    setNewCategory(allTeg);
  };

  const getImage = (url, body) => {
    twitchAPI(url)
      .then(data => {
        body.titleImage = data.preview.large;
        requestVideo(body);
      })
      .catch(err => {
        console.log(err);
      });
  };

  //FileLoader
  const callbackError = value => {
    setError(value);
  };

  const callbackValue = value => {
    changeUploadImage(value);
    setDefaultImage('');
    changeEmptyImage(false);
    validations(value, 'cover');
  };

  const getTitleImage = body => {
    if (newLink.includes('https://www.youtube.com/watch?v=')) {
      body.titleImage =
        'https://i.ytimg.com/vi/' +
        body.url.split('https://www.youtube.com/watch?v=')[1] +
        '/maxresdefault.jpg';
      body.platform = 'YOUTUBE';
      requestVideo(body);
    } else if (newLink.includes('https://www.twitch.tv/videos/')) {
      const ApiUrl =
        'https://api.twitch.tv/kraken/videos/' + body.url.split('/videos/')[1];
      body.platform = 'TWITCH';
      return getImage(ApiUrl, body);
    } else if (newLink.includes('https://www.twitch.tv/')) {
      body.titleImage = `https://static-cdn.jtvnw.net/previews-ttv/live_user_${
        body.url.split('https://www.twitch.tv/')[1]
      }-444x333.jpg`;
      body.platform = 'TWITCH';
      requestVideo(body);
    } else {
      errorCreator('link is not correct');
    }
  };

  const requestVideo = body => {
    if (edit) editVideoItems(body);
    else createVideoItems(body);
  };

  const errorCreator = text => {
    setError(text);
    setNewLink('');

    setTimeout(() => {
      setError('');
    }, 5000);
  };

  const createVideoItems = body => {
    if (uploadCoverTypes.includes(typeVideo)) {
      if (!uploadImages.length && !defaultImage) return changeEmptyImage(true);
    }

    const isVideo = typeVideo === 'VIDEO';
    const offset = isVideo ? activeOffsetVideo : activeOffsetStream;

    postVideo(body)
      .then(({ _id }) => {
        dispatch(initAllVideo(typeVideo, offset));

        if (uploadImages[0]) {
          const cover = uploadImages[0];
          const logo = createFormData({ cover });

          uploadCoverImage(_id, logo)
            .then(() => {
              onClose();
              dispatch(successNotification('Stream was successful added'));
            })
            .catch(({ message }) => dispatch(getErrorMessage(message)));
        } else {
          onClose();
          dispatch(successNotification('Video was successful added'));
        }
      })
      .catch(({ message }) => dispatch(getErrorMessage(message)));
  };

  const editVideoItems = body => {
    const isVideo = typeVideo === 'VIDEO';
    const offset = isVideo ? activeOffsetVideo : activeOffsetStream;

    if (uploadCoverTypes.includes(typeVideo)) {
      if (!uploadImages.length && !defaultImage) return changeEmptyImage(true);
    }

    if (Object.keys(body).length) {
      putVideo(bodyDefault._id, body)
        .then(() => dispatch(initAllVideo(typeVideo, offset)))
        .catch(({ message }) => dispatch(getErrorMessage(message)));
    }

    if (uploadImages[0]) {
      const cover = uploadImages[0];
      const logo = createFormData({ cover });

      uploadCoverImage(id, logo)
        .then(response => response)
        .catch(({ message }) => dispatch(getErrorMessage(message)));
    }

    if (!defaultImage && !uploadImages[0]) {
      deleteCoverImage(id)
        .then(response => response)
        .catch(({ message }) => dispatch(errorNotification(message)));
    }

    dispatch(onClose());
  };

  const trainingBody = () => {
    if (allIsValid) {
      if (edit) {
        const body = {};
        if (validationsInfo.tags) {
          body.tags = newCategory;
        }
        if (validationsInfo.players) {
          body.players = players;
        }
        if (validationsInfo.url) {
          body.url = newLink;
        }
        if (validationsInfo.descriptionEn) {
          body.descriptionEn = descriptionEn;
        }
        if (validationsInfo.titleEn) {
          body.titleEn = titleEn;
        }

        if (validationsInfo.platform) {
          body.platform = [newPlatform];
        }

        if (validationsInfo.twitchUserLogin && typeVideo.includes('STREAM')) {
          body.twitchUserLogin = twitchUserLogin;
        }

        if (body.url) {
          getTitleImage(body);
        } else {
          requestVideo(body);
        }
      } else {
        const currentVideoData = {
          titleEn: titleEn,

          descriptionEn: descriptionEn,

          url: newLink,
          kind: typeVideo,
          tags: newCategory,
          players: players,
          platform: newPlatform,
          // lang: lang,
          titleImage: 'titleImage',
        };
        getTitleImage(
          typeVideo.includes('STREAM')
            ? { ...currentVideoData, twitchUserLogin: twitchUserLogin }
            : currentVideoData,
        );
      }
    }
  };
  const validations = (value, type) => {
    let validations = validationsInfo;

    if (edit) {
      if (type === 'tags') {
        validations[type] =
          JSON.stringify(value.sort()) !==
          JSON.stringify(bodyDefault[type].sort());
      } else {
        validations[type] = value && value !== bodyDefault[type];
      }
      setValidationsInfo(validations);
    }
    isValid();
  };

  const isValid = () => {
    if (edit) {
      const a = Object.keys(validationsInfo).some(
        item => validationsInfo[item],
      );
      setAllIsValid(a);
    } else {
      setAllIsValid(newLink && players && titleEn && descriptionEn);
    }
  };

  const handleChangePlayers = selectedPlayers => {
    if (!selectedPlayers) return setPlayers([]);
    setPlayers(selectedPlayers.map(({ value }) => value));
    validations(
      selectedPlayers.map(({ value }) => value),
      'players',
    );
  };

  return (
    <div className="uploaderVideos">
      <div className="uploaderVideos__inputs">
        <div className="uploaderVideos__inputs__container">
          {uploadCoverTypes.includes(typeVideo) && (
            <div>
              <RecomendationInfo text="Recommendation: upload images no more than 860px width and 483px high" />
              <label>Cover image</label>
              <FileLoader
                errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
                callbackError={callbackError}
                callbackValue={callbackValue}
                defaultImage={defaultImage ? [defaultImage] : []}
              />
            </div>
          )}

          <label>
            <input
              className="input"
              placeholder="title english"
              onChange={({ target }) => {
                setTitleEn(target.value);
                validations(target.value, 'titleEn');
              }}
              value={titleEn ? titleEn : ''}
            />
          </label>
          <label>
            <input
              className="input"
              placeholder={'url ' + typeVideo.toLowerCase()}
              value={newLink ? newLink : ''}
              onChange={({ target }) => {
                setNewLink(target.value);
                setError('');
                setPlatform(
                  target.value.includes('https://www.twitch.tv/')
                    ? 'TWITCH'
                    : 'YOUTUBE',
                );

                validations(target.value, 'url');
              }}
            />
          </label>
          {typeVideo.includes('STREAM') && (
            <label>
              <input
                className="input"
                placeholder="Twitch user login"
                value={twitchUserLogin ? twitchUserLogin : ''}
                onChange={({ target }) => {
                  setTwitchUserLogin(target.value);
                  validations(target.value, 'twitchUserLogin');
                }}
              />
            </label>
          )}
          <label>
            <input
              className="input"
              placeholder={'description english'}
              value={descriptionEn ? descriptionEn : ''}
              onChange={({ target }) => {
                setDescriptionEn(target.value);
                validations(target.value, 'descriptionEn');
              }}
            />
          </label>
          {typeVideo.includes('VIDEO') && (
            <label>
              <Select
                isMulti
                placeholder="PLAYERS"
                className="basic-multi-select"
                classNamePrefix="react-select"
                options={playersOption}
                value={playersOption.filter(({ value }) =>
                  players?.includes(value),
                )}
                onChange={selectedPlayers =>
                  handleChangePlayers(selectedPlayers)
                }
              />
            </label>
          )}
        </div>
      </div>
      <div>
        <TagsSelector activeTag={bodyDefault.tags} setTag={setTag} />
      </div>
      <div className="uploaderVideos__key">
        <div
          className={`button ${!allIsValid ? 'button-disabled' : null}`}
          onClick={trainingBody}
        >
          save
        </div>
        <div
          className="button "
          onClick={() => {
            onClose();
          }}
        >
          cancel
        </div>
      </div>
      {emptyImage && (
        <p className={'uploaderVideos__error'}>Need to add an image</p>
      )}
      {error ? (
        <div className="uploaderVideos__error">
          <p className="uploaderVideos__error-text">{error}</p>
        </div>
      ) : null}
    </div>
  );
};
const mapDispatchToProps = {
  closeModal,
  changeStatus,
};
const mapStateToProps = state => ({
  activeOffsetStream: state.media.activeOffsetStream,
  activeOffsetVideo: state.media.activeOffsetVideo,
});
export default connect(mapStateToProps, mapDispatchToProps)(UploaderVideos);
