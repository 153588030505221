import React from 'react';

import './styles.scss';

const TeamPlayers = ({
  players,
  teamName,
  selectedPlayers,
  handlePlayerChange,
  handleScroll,
}) => (
  <div className="team-container-body" onScroll={handleScroll}>
    <p>Select a player to remove from the team "{teamName}"</p>
    <table>
      <thead>
        <tr>
          <th>Photo</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>
            <div className="team-container-body__info">
              Add / Remove
              <div className="team-container-body__info-item">
                i
                <div className="team-container-body__info-item-content">
                  Select checkbox to add player, unselect to delete
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {players.map(
          ({
            name: {
              first: { en: firstEn },
              last: { en: lastEn },
            },
            _id: id,
            avatar,
          }) => (
            <tr key={id}>
              <td>
                <img className={'icon'} src={avatar} alt="remove icon" />
              </td>
              <td>{firstEn}</td>
              <td>{lastEn}</td>

              <td>
                <input
                  onChange={e => {
                    handlePlayerChange(e.target.checked, id);
                  }}
                  type="checkbox"
                  style={{ width: '20px', height: '20px' }}
                  checked={selectedPlayers.includes(id)}
                />
              </td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  </div>
);

export default TeamPlayers;
