import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TextEditor from '../../textEditor/textEditor';
import FileLoader from '../../fileLoader/fileLoader';
import TagsSelector from '../../tagsSelector/tagsSelector';
import {
  postArticle,
  putArticle,
} from '../../../redux/operations/newsOperations';

import RecomendationInfo from '../../Recomendation/Recomendation';
import { uploadImagesConfig } from './FormNews.config';
import './style.scss';

const FormNews = ({ edit, article, onClose }) => {
  const [lang, setLang] = useState(article ? article.lang : 'EN');
  const [title, setTitle] = useState(article ? article.title : '');
  const [format, setFormat] = useState(
    article ? (article.format ? article.format : 'ARTICLE') : 'ARTICLE',
  );
  const [textArticle, setTextArticle] = useState(article ? article.body : '');
  const [images, setImages] = useState({
    titleImage_FULLHD: article ? article.titleImage_FULLHD : '',
    titleImage_DESKTOP: article ? article.titleImage_DESKTOP : '',
    titleImage_LAPTOP: article ? article.titleImage_LAPTOP : '',
    titleImage_TABLET: article ? article.titleImage_TABLET : '',
    titleImage_BIGPHONE: article ? article.titleImage_BIGPHONE : '',
    titleImage_PHONE: article ? article.titleImage_PHONE : '',
    titleImage_SMALLPHONE: article ? article.titleImage_SMALLPHONE : '',
  });
  const [isBannerVisible, setIsBannerVisible] = useState(
    article ? !!article.isBannerVisible : false,
  );
  useEffect(() => {
    if (edit) {
      document.querySelector('body').style.overflow = 'hidden';
    }
  }, []);
  const [activeCategory, setActiveCategory] = useState();
  const [isValid, setIsValid] = useState(
    edit
      ? {
          title: false,
          body: false,
          titleImage_FULLHD: false,
          titleImage_DESKTOP: false,
          titleImage_LAPTOP: false,
          titleImage_TABLET: false,
          titleImage_BIGPHONE: false,
          titleImage_PHONE: false,
          titleImage_SMALLPHONE: false,
          tags: false,
          format: false,
          lang: false,
        }
      : false,
  );

  const dispatch = useDispatch();

  const {
    titleImage_FULLHD,
    titleImage_DESKTOP,
    titleImage_LAPTOP,
    titleImage_TABLET,
    titleImage_BIGPHONE,
    titleImage_PHONE,
    titleImage_SMALLPHONE,
  } = images;

  const createArticle = body => {
    dispatch(postArticle(body));
    setTitle('');
    setTextArticle('');
  };
  const submitArticle = () => {
    const formData = new FormData();

    if (edit) {
      if (isValid.textArticle) {
        formData.append('body', textArticle);
      }
      if (isValid.titleImage_FULLHD) {
        formData.append('titleImage_FULLHD', titleImage_FULLHD[0]);
        formData.append('titleImage_DESKTOP', titleImage_FULLHD[0]);
        formData.append('titleImage_LAPTOP', titleImage_FULLHD[0]);
        formData.append('titleImage_TABLET', titleImage_FULLHD[0]);
        formData.append('titleImage_BIGPHONE', titleImage_FULLHD[0]);
        formData.append('titleImage_PHONE', titleImage_FULLHD[0]);
        formData.append('titleImage_SMALLPHONE', titleImage_FULLHD[0]);
      }

      if (isValid.tags) {
        formData.append('tags', JSON.stringify(activeCategory));
      }
      if (isBannerVisible !== article.isBannerVisible) {
        formData.append('isBannerVisible', JSON.stringify(isBannerVisible));
      }
      if (isValid.lang) {
        formData.append('lang', lang);
      }

      formData.append('format', format);
      formData.append('title', title);

      dispatch(putArticle(article._id, formData));
      document.querySelector('body').style.overflow = 'auto';

      onClose();
    } else {
      if (getKeyClassName) {
        formData.append('title', title);
        formData.append('format', format);
        formData.append('body', textArticle);
        formData.append('titleImage_FULLHD', titleImage_FULLHD[0]);
        formData.append('titleImage_DESKTOP', titleImage_FULLHD[0]);
        formData.append('titleImage_LAPTOP', titleImage_FULLHD[0]);
        formData.append('titleImage_TABLET', titleImage_FULLHD[0]);
        formData.append('titleImage_BIGPHONE', titleImage_FULLHD[0]);
        formData.append('titleImage_PHONE', titleImage_FULLHD[0]);
        formData.append('titleImage_SMALLPHONE', titleImage_FULLHD[0]);
        formData.append('tags', JSON.stringify(activeCategory));
        formData.append('lang', lang);
        formData.append('isBannerVisible', JSON.stringify(isBannerVisible));
      }
      createArticle(formData);
    }
  };

  const validation = (value, type) => {
    const valid = { ...isValid };

    if (edit) {
      switch (type) {
        case 'title':
          valid[type] = value && value !== article.title;
          break;
        case 'format':
          valid[type] = true;
          break;
        case 'textArticle':
          valid[type] = value && value.length !== article.body.length;
          break;
        case 'titleImage_FULLHD':
          valid[type] = value.length && Array.isArray(value);
          break;
        // case 'titleImage_DESKTOP':
        //   valid[type] = value.length && Array.isArray(value);
        //   break;
        // case 'titleImage_LAPTOP':
        //   valid[type] = value.length && Array.isArray(value);
        //   break;
        // case 'titleImage_TABLET':
        //   valid[type] = value.length && Array.isArray(value);
        //   break;
        // case 'titleImage_BIGPHONE':
        //   valid[type] = value.length && Array.isArray(value);
        //   break;
        // case 'titleImage_PHONE':
        //   valid[type] = value.length && Array.isArray(value);
        //   break;
        // case 'titleImage_SMALLPHONE':
        //   valid[type] = value.length && Array.isArray(value);
        //   break;
        case 'lang':
          valid[type] = value.length && value !== article.lang;
          break;
        case 'tags':
          const tagsId = article.tags.map(item => {
            return item._id;
          });
          valid[type] =
            value.length &&
            JSON.stringify(tagsId.sort()) !== JSON.stringify(value.sort());
          break;
        default:
          new Error(
            'is not correct type for validation on form news component',
          );
      }
      setIsValid(valid);
    }
  };
  const getKeyClassName = () => {
    if (edit) {
      const {
        title,
        textArticle,
        titleImage_FULLHD,
        // titleImage_DESKTOP,
        // titleImage_LAPTOP,
        // titleImage_TABLET,
        // titleImage_BIGPHONE,
        // titleImage_PHONE,
        // titleImage_SMALLPHONE,
        tags,
        format,
        lang,
      } = isValid;

      return (
        title ||
        textArticle ||
        titleImage_FULLHD ||
        // titleImage_DESKTOP ||
        // titleImage_LAPTOP ||
        // titleImage_TABLET ||
        // titleImage_BIGPHONE ||
        // titleImage_PHONE ||
        // titleImage_SMALLPHONE ||
        tags ||
        format ||
        lang ||
        isBannerVisible !== article.isBannerVisible
      );
    } else {
      return (
        lang && title && textArticle && titleImage_FULLHD.length
        // titleImage_DESKTOP.length &&
        // titleImage_LAPTOP &&
        // titleImage_TABLET &&
        // titleImage_BIGPHONE &&
        // titleImage_PHONE &&
        // titleImage_SMALLPHONE
      );
    }
  };

  return (
    <div className="formNews">
      <h1>Create News</h1>

      <div className="formNews__select-container">
        <label className="formNews__label">Select format</label>
        <select
          className="formNews__select"
          name="country"
          placeholder="select"
          onChange={({ target }) => {
            validation('format');
            setFormat(target.value);
          }}
          value={format}
        >
          <option>ARTICLE</option>
          <option>REPORT</option>
        </select>
      </div>
      <div className="formNews__title">
        <input
          className="input formNews__input"
          placeholder={'title'}
          value={title}
          onChange={({ target }) => {
            validation(target.value, 'title');
            setTitle(target.value);
          }}
        />
        <div className="lang-selector">
          <label>
            <p>En</p>
            <input
              name="lang"
              type="radio"
              value="EN"
              defaultChecked={lang === 'EN'}
              onChange={({ target: { value } }) => {
                validation(value, 'lang');
                setLang(value);
              }}
            />
          </label>
        </div>
      </div>

      <div className="formNews__images">
        {uploadImagesConfig.map(({ width, height, size, image }) => (
          <div className="formNews__images-item">
            <RecomendationInfo
              text={`Recommendation: upload images no more than ${width}px wide and ${height}px high`}
            />
            <FileLoader
              name={'banner'}
              type="news"
              errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
              callbackError={e => console.log(e)}
              callbackValue={e => {
                validation(e, image);
                setImages({
                  ...images,
                  [image]: e,
                });
              }}
              defaultImage={article && article[image] ? [article[image]] : []}
            />
            for {size}
          </div>
        ))}
      </div>

      <div className="formNews__uploading-teg">
        <label className="formNews__isBannerVisible">
          <input
            type="checkbox"
            name="option2"
            value="a2"
            checked={isBannerVisible}
            onClick={() => setIsBannerVisible(!isBannerVisible)}
          />{' '}
          <p> is banner visible</p>
        </label>

        <div>
          <TagsSelector
            activeTag={article ? article.tags : []}
            setTag={value => {
              validation(value, 'tags');
              setActiveCategory(value);
            }}
          />
        </div>
      </div>

      <div>
        <TextEditor
          onChange={value => {
            validation(value, 'textArticle');
            setTextArticle(value);
          }}
          value={textArticle}
        />
      </div>

      <div className="formNews__buttons">
        <button
          className={
            getKeyClassName()
              ? 'styleKey button'
              : 'styleKey button  button-disabled'
          }
          disabled={!getKeyClassName()}
          onClick={submitArticle}
        >
          submit
        </button>
        {edit ? (
          <button
            className="styleKey button  formNews__uploading-active"
            onClick={() => {
              document.querySelector('body').style.overflow = 'auto';
              onClose();
            }}
          >
            close
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default FormNews;
