import types from '../types/modalTypes';

const initialState = {
  open: false,
  type: null,
  item: null,
  isShowTooltip: false,
};

const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.MODAL_STATUS:
      return payload;
    case types.CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
