import types from '../types/tournamentTypes';

// get
export const getTournamentListRequest = () => ({
  type: types.GET_TOURNAMENT_LIST_REQUEST,
});

export const getTournamentListSuccess = (data = {}) => ({
  type: types.GET_TOURNAMENT_LIST_SUCCESS,
  payload: data,
});

export const getTournamentListError = (error = '') => ({
  type: types.GET_TOURNAMENT_LIST_ERROR,
  payload: error,
});

export const getTournamentRequest = () => ({
  type: types.GET_TOURNAMENT_REQUEST,
});

export const getTournamentSuccess = (data = {}) => ({
  type: types.GET_TOURNAMENT_SUCCESS,
  payload: data,
});

export const getTournamentError = (error = '') => ({
  type: types.GET_TOURNAMENT_ERROR,
  payload: error,
});

export const getSortedTournamentListRequest = () => ({
  type: types.GET_SORTED_TOURNAMENT_LIST_REQUEST,
});

export const getSortedTournamentListSuccess = (data = {}) => ({
  type: types.GET_SORTED_TOURNAMENT_LIST_SUCCESS,
  payload: data,
});

export const getSortedTournamentListError = (error = '') => ({
  type: types.GET_SORTED_TOURNAMENT_LIST_ERROR,
  payload: error,
});

export const updateTournamentSortingParams = (params = {}) => ({
  type: types.UPDATE_TOURNAMENT_SORTING_PARAMS,
  payload: params,
});

// post
export const postTournamentRequest = () => ({
  type: types.POST_TOURNAMENT_REQUEST,
});

export const postTournamentSuccess = (data = {}) => ({
  type: types.POST_TOURNAMENT_SUCCESS,
  payload: data,
});

export const postTournamentError = (error = '') => ({
  type: types.POST_TOURNAMENT_ERROR,
  payload: error,
});

// put
export const editTournamentRequest = () => ({
  type: types.EDIT_TOURNAMENT_REQUEST,
});

export const editTournamentSuccess = (data = {}) => ({
  type: types.EDIT_TOURNAMENT_SUCCESS,
  payload: data,
});

export const editTournamentError = (error = '') => ({
  type: types.EDIT_TOURNAMENT_ERROR,
  payload: error,
});

// delete
export const deleteTournamentRequest = () => ({
  type: types.DELETE_TOURNAMENT_REQUEST,
});

export const deleteTournamentSuccess = id => ({
  type: types.DELETE_TOURNAMENT_SUCCESS,
  payload: id,
});

export const deleteTournamentError = (error = '') => ({
  type: types.DELETE_TOURNAMENT_ERROR,
  payload: error,
});
