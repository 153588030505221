const types = {
  // get list of links
  GET_SHOP_LINKS_LIST_REQUEST: 'GET_SHOP_LINKS_LIST_REQUEST',
  GET_SHOP_LINKS_LIST_SUCCESS: 'GET_SHOP_LINKS_LIST_SUCCESS',
  GET_SHOP_LINKS_LIST_ERROR: 'GET_SHOP_LINKS_LIST_ERROR',

  // remove link from list
  REMOVE_LINK_FROM_LIST_REQUEST: 'REMOVE_LINK_FROM_LIST_REQUEST',
  REMOVE_LINK_FROM_LIST_SUCCESS: 'REMOVE_LINK_FROM_LIST_SUCCESS',

  // update link from list
  UPDATE_LINK_FROM_LIST_REQUEST: 'UPDATE_LINK_FROM_LIST_REQUEST',
  UPDATE_LINK_FROM_LIST_SUCCESS: 'UPDATE_LINK_FROM_LIST_SUCCESS',

  // create link from list
  CREATE_LINK_FROM_LIST_REQUEST: 'CREATE_LINK_FROM_LIST_REQUEST',
  CREATE_LINK_FROM_LIST_SUCCESS: 'CREATE_LINK_FROM_LIST_SUCCESS',

  // change link order
  CHANGE_LINK_ORDER_REQUEST: 'CHANGE_LINK_ORDER_REQUEST',
  CHANGE_LINK_ORDER_SUCCESS: 'CHANGE_LINK_ORDER_SUCCESS',
};

export default types;
