const book = Object.freeze({
  home: '/main',
  about: '/about',
  events: '/events',
  media: '/media',
  shop: '/shop',
  team: '/team',
  news: '/news',
  opponents: '/opponents',
});

export default book;
