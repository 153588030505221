import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteCommentPhoto,
  deleteCommentVideo,
  getPhoto,
  getVideo,
} from '../../api/media-service/media-service';
import './style.scss';

import { typeComment } from './typeComment';

import {
  deleteArticleComment,
  getArticle,
} from '../../api/news-service/news-service';

import {
  getGameById,
  deleteGameCommentById,
} from '../../api/event-service/event-service';

import { removeGameComment } from '../../redux/operations/gameOperation';

const Comment = ({ id, type, idAlbum }) => {
  const dispatch = useDispatch();

  const [body, setBody] = useState({});
  useEffect(() => {
    update();
  }, []);

  const updateVideo = () => {
    getVideo(id).then(response => {
      setBody(response);
    });
  };

  const deleteVideoComment = idComment => {
    deleteCommentVideo(id, idComment).then(() => {
      updateVideo();
    });
  };

  const updateNews = () => {
    getArticle(id).then(response => {
      if (response) {
        setBody(response);
      }
    });
  };

  const deleteNewsComment = idComment => {
    deleteArticleComment(id, idComment).then(() => {
      updateNews();
    });
  };

  const updatePhoto = () => {
    getPhoto(id).then(response => {
      if (response) {
        setBody(response);
      }
    });
  };

  const updateGame = () => {
    getGameById(id).then(response => {
      if (response) {
        setBody(response);
      }
    });
  };

  const deleteGameComment = commentId => {
    dispatch(removeGameComment(id, commentId)).then(() => {
      updateGame();
    });
  };

  const deletePhotoComment = idPhoto => {
    deleteCommentPhoto(id, idPhoto).then(() => {
      updatePhoto();
    });
  };
  const update = () => {
    switch (type) {
      case typeComment.GAME_COMMENT:
        updateGame();
        break;

      case typeComment.PHOTO_COMMENT:
        updatePhoto();
        break;
      case typeComment.NEWS_COMMENT:
        updateNews();
        break;
      case typeComment.VIDEO_COMMENT:
        updateVideo();
        break;
    }
  };

  const deleteComment = idComment => {
    switch (type) {
      case typeComment.GAME_COMMENT:
        deleteGameComment(idComment);
        break;
      case typeComment.PHOTO_COMMENT:
        deletePhotoComment(idComment);
        break;
      case typeComment.NEWS_COMMENT:
        deleteNewsComment(idComment);
        break;
      case typeComment.VIDEO_COMMENT:
        deleteVideoComment(idComment);
        break;
    }
  };
  const getFormatData = dateCreate => {
    const date = new Date(dateCreate);
    return (
      ('0' + date.getDate()).slice(-2) +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      date.getFullYear()
    );
  };
  const getLinkToSite = () => {
    if (body._id) {
      switch (type) {
        case typeComment.GAME_COMMENT:
          return `http://gmt-client.lampawork.com/game/${body._id}`;
        case typeComment.PHOTO_COMMENT:
          return `http://gmt-client.lampawork.com/media/galery/album/${idAlbum}`;
        case typeComment.NEWS_COMMENT:
          return `http://gmt-client.lampawork.com/news/${body._id}`;
        case typeComment.VIDEO_COMMENT:
          return body.kind === 'VIDEO'
            ? `http://gmt-client.lampawork.com/videos/${body._id}`
            : `http://gmt-client.lampawork.com/streams/${body._id}`;
      }
    }
  };

  const getCommentsList = () => {
    if (body.comments && body.comments.length) {
      return body.comments.map((item, index) => (
        <div className="comment__item" key={index + 'Comments' + item._id}>
          <div className="comment__container">
            <div className="comment__container-info">
              <div className="comment__name">
                {
                  (item.user.name.first,
                  item.user.name.lest ? item.user.name.first : '')
                }
              </div>
              <div className="comment__date">
                {getFormatData(item.dateCreate)}
              </div>
            </div>
          </div>
          <textarea
            className="comment__text"
            value={item.text}
            readOnly={true}
          />
          <div className="comment__lest-element">
            <div
              className="button button-remove styleKey"
              onClick={() => {
                deleteComment(item._id);
              }}
            >
              delete
            </div>
            <div className="comment__lest-element-like">
              <div>like / dislike</div>
              <div>
                {item.likesCount}/{item.dislikesCount}
              </div>
            </div>
          </div>
        </div>
      ));
    } else if (Array.isArray(body.comments) && !body.comments.length) {
      return (
        <div>
          <div className="comment__absent">
            <p>Sorry but comments is absent</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="comment scrollElement">
      <a href={getLinkToSite()} target="_blank" rel="noreferrer">
        <div className="button">Open content on web site client</div>
      </a>
      {getCommentsList()}
    </div>
  );
};
export default Comment;
