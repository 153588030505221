const types = {
  // get
  GET_TOURNAMENT_LIST_REQUEST: 'GET_TOURNAMENT_LIST_REQUEST',
  GET_TOURNAMENT_LIST_SUCCESS: 'GET_TOURNAMENT_LIST_SUCCESS',
  GET_TOURNAMENT_LIST_ERROR: 'GET_TOURNAMENT_LIST_ERROR',
  GET_TOURNAMENT_REQUEST: 'GET_TOURNAMENT_REQUEST',
  GET_TOURNAMENT_SUCCESS: 'GET_TOURNAMENT_SUCCESS',
  GET_TOURNAMENT_ERROR: 'GET_TOURNAMENT_ERROR',
  GET_SORTED_TOURNAMENT_LIST_REQUEST: 'GET_SORTED_TOURNAMENT_LIST_REQUEST',
  GET_SORTED_TOURNAMENT_LIST_SUCCESS: 'GET_SORTED_TOURNAMENT_LIST_SUCCESS',
  GET_SORTED_TOURNAMENT_LIST_ERROR: 'GET_SORTED_TOURNAMENT_LIST_ERROR',
  UPDATE_TOURNAMENT_SORTING_PARAMS: 'UPDATE_TOURNAMENT_SORTING_PARAMS',

  // post
  POST_TOURNAMENT_REQUEST: 'POST_TOURNAMENT_REQUEST',
  POST_TOURNAMENT_SUCCESS: 'POST_TOURNAMENT_SUCCESS',
  POST_TOURNAMENT_ERROR: 'POST_TOURNAMENT_ERROR',

  // put
  EDIT_TOURNAMENT_REQUEST: 'EDIT_TOURNAMENT_REQUEST',
  EDIT_TOURNAMENT_SUCCESS: 'EDIT_TOURNAMENT_SUCCESS',
  EDIT_TOURNAMENT_ERROR: 'EDIT_TOURNAMENT_ERROR',

  // delete
  DELETE_TOURNAMENT_REQUEST: 'DELETE_TOURNAMENT_REQUEST',
  DELETE_TOURNAMENT_SUCCESS: 'DELETE_TOURNAMENT_SUCCESS',
  DELETE_TOURNAMENT_ERROR: 'DELETE_TOURNAMENT_ERROR',
};

export default types;
