import * as requests from '../../api/shop-service/shop-service';
import * as actions from '../actions/shopAction';
import { changeStatus } from '../actions/modalActions';
import {
  successNotification,
  errorNotification,
} from './notificationOperation';
import { modalTypes } from '../../consts/consts';

export const getShopLinksListOperation = () => dispatch => {
  dispatch(actions.getShopLinksListRequest());
  requests
    .getShopLinksList()
    .then(response => {
      dispatch(actions.getShopLinksListSuccess(response.items));
    })
    .catch(({ message }) => {
      dispatch(actions.getShopLinksListError(message));
    });
};

export const removeLinkFromListOperation =
  (params = {}) =>
  dispatch => {
    dispatch(actions.removeLinkFromListRequest());
    requests
      .removeShopLink(params)
      .then(() => {
        dispatch(successNotification('Link was successfully deleted'));
        dispatch(actions.removeLinkFromListSuccess(params.id));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };

export const updateLinkFromListOperation =
  (params = {}, reqBody = {}) =>
  dispatch => {
    dispatch(actions.updateLinkFromListRequest());
    requests
      .updateShopLink(params, reqBody)
      .then(response => {
        dispatch(successNotification('Link was successfully updated'));
        dispatch(actions.updateLinkFromListSuccess(response));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      })
      .finally(() => dispatch(changeStatus(modalTypes.CLOSE)));
  };

export const createLinkFromListOperation =
  (params = {}) =>
  dispatch => {
    dispatch(actions.createLinkFromListRequest());
    requests
      .createShopLink(params)
      .then(response => {
        dispatch(successNotification('Link was successfully created'));
        dispatch(actions.createLinkFromListSuccess({ ...response }));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      })
      .finally(() => dispatch(changeStatus(modalTypes.CLOSE)));
  };

export const updateLinkOrderOperation =
  (params = {}, reqBody = {}) =>
  dispatch => {
    dispatch(actions.changeLinkOrderRequest());
    requests
      .updateShopLink(params, reqBody)
      .then(() => {
        dispatch(actions.changeLinkOrderSuccess({ ...params, ...reqBody }));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };
