import React from 'react';
import './styles.scss';
import removeImg from '../../assets/image/media/rmove.png';
import editImg from '../../assets/image/media/edit.png';
import { checkAndTrimStringForDisplay } from '../../utils/stringMethods';

const Partner = ({ value, edit, remove }) => {
  const { _id, site, name, logo, background } = value;

  const changeLengthToDisplay = (text, displayLength) => {
    return text
      ? text.trim().length > displayLength
        ? text.slice(0, displayLength) + '...'
        : text
      : '---';
  };
  return (
    <div className="partners">
      <div className="partners__img-item partners-center">
        <img className="partners-logo" src={logo} alt="partner logo" />
      </div>
      <div className="partners__img-item partners-center">
        <img
          className="partners-banner"
          src={background}
          alt="partner banner"
        />
      </div>
      <div className="partners__name partners-center">
        {checkAndTrimStringForDisplay(name)}
      </div>
      <div className="partners__site partners-center">
        <a href={site}>{checkAndTrimStringForDisplay(site, 35)}</a>
      </div>
      <div
        className="partners__button partners-center"
        onClick={() => {
          edit(_id);
        }}
      >
        <img className="partners__button-img styleKey" src={editImg} />
      </div>
      <div
        className="partners__button styleKey partners__button-remove partners-center"
        onClick={() => {
          remove(_id);
        }}
      >
        <img className="partners__button-img styleKey" src={removeImg} />
      </div>
    </div>
  );
};
export default Partner;
