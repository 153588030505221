import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { createFormData } from '../../utils/formDataOperations';
import { addStaff } from '../../redux/operations/aboutOperation';
import { firstConfig, lastConfig, positionConfig } from './config';
import LocalizationTabs from '../../component/LocalizationTabs/LocalizationTabs';
import FileLoader from '../fileLoader/fileLoader';
import RecomendationInfo from '../Recomendation/Recomendation';

import './styles.scss';

let StaffmateCreator = ({ handleSubmit, closeCreator }) => {
  const [error, setError] = useState({});
  const [photo, setPhoto] = useState({});
  const [emptyImage, changeEmptyImage] = useState(false);

  //redux actions
  const stuff = useSelector(({ form }) => form.stuff);
  const dispatch = useDispatch();

  const callbackError = value => {
    setError(value);
  };

  const callbackValue = (value, type) => {
    setPhoto(value);
    changeEmptyImage(false);
  };

  const submitForm = data => {
    if (!photo.length) {
      return changeEmptyImage(true);
    }

    const formData = createFormData({ photo: photo[0], ...data });
    dispatch(addStaff(formData));
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} className={'partnerCreator'}>
      <div className="partnerCreator__item">
        <LocalizationTabs
          syncErrors={stuff && stuff.syncErrors}
          config={firstConfig}
          labelText={'First name'}
        />
      </div>
      <div className="partnerCreator__item">
        <LocalizationTabs
          syncErrors={stuff && stuff.syncErrors}
          config={lastConfig}
          labelText={'Last name'}
        />
      </div>
      <div className="partnerCreator__item">
        <LocalizationTabs
          syncErrors={stuff && stuff.syncErrors}
          config={positionConfig}
          labelText={'Position'}
        />
      </div>
      <div className={'partnerCreator__item'}>
        {emptyImage && (
          <p className={'partnerCreator__error'}>Need to add an image</p>
        )}

        <RecomendationInfo text="Recommendation: upload images no more than 415px wide and 405px high" />
        <label>Photo:</label>
        <FileLoader
          name={'photo'}
          errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
          callbackError={callbackError}
          callbackValue={callbackValue}
        />
        <p>{error.error}</p>
      </div>

      <div className={'partnerCreator__button-container'}>
        <button type="submit" className="button styleKey">
          Add staff
        </button>
        <button
          className={'button styleKey'}
          onClick={() => {
            closeCreator();
          }}
        >
          Close
        </button>
      </div>
    </form>
  );
};

StaffmateCreator = reduxForm({
  form: 'stuff',
})(StaffmateCreator);

export default StaffmateCreator;
