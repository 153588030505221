import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
  sendCategory,
  editCategory,
} from '../../../redux/operations/categoryOperation';
import { changeStatus } from '../../../redux/actions/modalActions';

import * as validators from '../../../utils/validators';
import ValidationInput from '../../common/validationComponents/ValidationInput';
import FileLoader from '../../fileLoader/fileLoader';
import { modalTypes } from '../../../consts/consts';
import { normalizeData } from '../../../utils/changeDataFormat';

import { getErrorMessage } from '../../../redux/actions/errorHandlerAction';
import { getCategoryById } from '../../../api/category-service/category-service';

import { SketchPicker } from 'react-color';

import './styles.scss';

let FormCategory = ({ handleSubmit, initialize, type, id }) => {
  const [error, setError] = useState(null);
  const [selectColor, changeColor] = useState('#000');
  const [emptyImage, changeEmptyImage] = useState(false);
  const [uploadImages, changeUploadImage] = useState([]);
  const [defaultImage, setDefaultImage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type) {
      getCategoryById(id)
        .then(({ color, icon, name }) => {
          changeColor(color);
          setDefaultImage(icon);
          initialize({ name });
        })
        .catch(({ message }) => {
          dispatch(getErrorMessage(message));
        });
    }
  }, []);

  const submitForm = ({ name }) => {
    if (!uploadImages.length && !defaultImage) {
      return changeEmptyImage(true);
    }

    const sendCategoryData = {
      icon: uploadImages[0],
      name,
      color: selectColor.hex,
    };

    const nomalizedData = normalizeData(sendCategoryData);

    type === 'edit'
      ? dispatch(editCategory(id, nomalizedData))
      : dispatch(sendCategory(nomalizedData));
  };

  const callbackError = value => {
    setError(value);
  };

  const callbackValue = value => {
    changeUploadImage(value);
    changeEmptyImage(false);
    setDefaultImage('');
  };

  return (
    <form className={'form-category'} onSubmit={handleSubmit(submitForm)}>
      {emptyImage && (
        <p className={'form-category__error'}>Need to add an image</p>
      )}
      {error && <p className={'form-category__error'}>{error}</p>}

      <FileLoader
        name={'media'}
        errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
        callbackError={callbackError}
        callbackValue={callbackValue}
        defaultImage={defaultImage ? [defaultImage] : []}
      />
      <div className={'form-category__picker'}>
        <SketchPicker color={selectColor} onChangeComplete={changeColor} />
      </div>
      <div>
        <label>Name</label>
        <Field
          className={'form-category__input'}
          name="name"
          component={ValidationInput}
          type="text"
          validate={[validators.required, validators.maxLength14]}
          placeholder="name"
        />
      </div>
      <div className={'form-achievement__actions'}>
        <button className="button" type="submit">
          submit
        </button>
        <button
          onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
          className="button"
          type="button"
        >
          cancel
        </button>
      </div>
    </form>
  );
};

FormCategory = reduxForm({
  form: 'category',
})(FormCategory);

export default FormCategory;
