import React from 'react';
import './styles.scss';

const validationSelect = ({ input, meta, ...props }) => {
  const hasError = meta.touched && meta.error;

  return (
    <div className={`${'formControl'} ${hasError ? 'error' : ''}`}>
      <div className={'inputContainer'}>
        <select className={'input'} {...input} {...props} />
      </div>
      {hasError && <span>{meta.error}</span>}
    </div>
  );
};

export default validationSelect;
