import { post, get, remove, putFormData, put } from '../apiService';

export const getAllTeam = ({ isOpponent, offset = 0, limit = 18 }) => {
  const opponentQuery = `?isOpponent=${isOpponent}`;
  const offsetTeam = `&offset=${offset}`;
  const limitTeam = `&limit=${limit}`;

  return get('/team/' + opponentQuery + offsetTeam + limitTeam);
};
export const getTeam = id => {
  return get(`/team/${id}/`);
};

export const postTeam = body => {
  return post('/team/', body);
};

export const postOpponentTeamLogo = (id, body) => {
  return putFormData(`/team/${id}/upload-logo`, body);
};

export const editTeam = (id, body) => {
  return put(`/team/${id}`, body);
};

export const editOpponentTeamLogo = (id, body) => {
  return putFormData(`/team/${id}/upload-logo`, body);
};

export const deleteTeam = id => {
  return remove(`/team/${id}`);
};

export const deletePlayerFromTeam = (id, playerId) => {
  return remove(`/team/${id}/player/${playerId}`);
};

export const addPlayerToTeam = (idTeam, idPlayer) => {
  return post(`/team/${idTeam}/player/${idPlayer}`);
};
