import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { teamSelector } from '../../redux/selectors/selectors';
import { changeStatus } from '../../redux/actions/modalActions';
import {
  fetchAllTeam,
  removeTeam,
} from '../../redux/operations/teamOperations';
import TeamList from './TeamtList';
import ReactPaginate from 'react-paginate';

const TeamListContainer = ({
  teamData,
  removeTeam,
  fetchAllTeam,
  changeStatus,
  isOpponent,
}) => {
  useEffect(() => {
    fetchAllTeam({ isOpponent });
  }, [fetchAllTeam]);

  const getNextPage = ({ selected }) => {
    fetchAllTeam({ isOpponent, offset: selected * 18 });
  };

  return (
    <>
      <TeamList
        teamData={teamData.items}
        removeTeam={removeTeam}
        changeStatus={changeStatus}
        isOpponent={isOpponent}
      />
      <ReactPaginate
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(teamData.count / 18)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={getNextPage}
      />
    </>
  );
};

const mapStateToProps = state => ({
  teamData: teamSelector(state),
});

const mapDispatchToProps = {
  changeStatus,
  fetchAllTeam,
  removeTeam,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamListContainer);
