import React from 'react';
import './styles.scss';

const SelectPlayers = ({ players, handleSelectPlayers, handleScroll }) => {
  return (
    <div className="select-container-body" onScroll={handleScroll}>
      <table>
        <thead>
          <tr>
            <th>Photo</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {players.map(({ name, avatar, _id: id }) => (
            <tr key={id}>
              <td>
                <img className={'icon'} src={avatar} alt="remove icon" />
              </td>
              <td>{name.first.en}</td>
              <td>{name.last.en}</td>

              <td>
                <input
                  className="select-players__checkbox"
                  onChange={handleSelectPlayers}
                  type="checkbox"
                  value={id}
                  name="players"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default SelectPlayers;
