const types = {
  ADD_PLAYER_REQUEST: 'ADD_PLAYER_REQUEST',
  ADD_PLAYER_SUCCESS: 'ADD_PLAYER_SUCCESS',
  ADD_PLAYER_ERROR: 'ADD_PLAYER_ERROR',
  GET_PLAYERS_REQUEST: 'GET_PLAYERS_REQUEST',
  GET_PLAYERS_SUCCESS: 'GET_PLAYERS_SUCCESS',
  GET_PLAYERS_ERROR: 'GET_PLAYERS_ERROR',
  GET_PLAYER_REQUEST: 'GET_PLAYER_REQUEST',
  GET_PLAYER_SUCCESS: 'GET_PLAYER_SUCCESS',
  GET_PLAYER_ERROR: 'GET_PLAYER_ERROR',
  DELETE_PLAYER_REQUEST: 'DELETE_PLAYER_REQUEST',
  DELETE_PLAYER_SUCCESS: 'DELETE_PLAYER_SUCCESS',
  DELETE_PLAYER_ERROR: 'DELETE_PLAYER_ERROR',
  EDIT_PLAYER_REQUEST: 'EDIT_PLAYER_REQUEST',
  EDIT_PLAYER_SUCCESS: 'EDIT_PLAYER_SUCCESS',
  EDIT_PLAYER_ERROR: 'EDIT_PLAYER_ERROR',
};

export default types;
