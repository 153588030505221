import { connect } from 'react-redux';
import Tooltip from '../Tooltip/Tooltip';
import { modal as modalSelector } from '../../redux/selectors/selectors';

import './styles.scss';
import { ReactComponent as CloseIcon } from '../../assets/image/close.svg';

const Modal = ({ children, onClose, modalInformation: { isShowTooltip } }) => {
  const handleCloseClick = e => {
    onClose(false);
  };

  return (
    <div className={'backdrop'}>
      <div className="modal__close" onClick={handleCloseClick}>
        {isShowTooltip ? (
          <Tooltip
            message={'If you close the form, changes will not be saved  '}
            position={'bottom'}
          >
            <CloseIcon className="modal__close-icon" />
          </Tooltip>
        ) : (
          <CloseIcon className="modal__close-icon" />
        )}
      </div>

      <div className={'modal'}>{children}</div>
    </div>
  );
};

const mapStateToProps = state => ({
  modalInformation: modalSelector(state),
});

export default connect(mapStateToProps, null)(Modal);
