import React, { useEffect, useState } from 'react';
import './style.scss';
import { getArticle } from '../../api/news-service/news-service';
import comments from '../../assets/image/media/coment.png';
import remove from '../../assets/image/media/rmove.png';
import edit from '../../assets/image/media/edit.png';
import FormNews from '../../component/Forms/FormNews/formNews';
import { connect, useDispatch } from 'react-redux';
import { updateArticle } from '../../redux/operations/newsOperations';
import { changeStatus } from '../../redux/actions/modalActions';
import { modalTypes } from '../../consts/consts';
import { typeComment } from '../../component/comment/typeComment';
import { getErrorMessage } from '../../redux/actions/errorHandlerAction';
import ReactPaginate from 'react-paginate';
import { saveNewsOffset } from '../../redux/actions/newsActions';
import dateFormat from 'dateformat';

const News = ({ allArticle }) => {
  const { items, count } = allArticle;
  const [listLanguage, setListLanguage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticle(null, listLanguage));
  }, [listLanguage]);

  const getArticleActive = idArticile => {
    getArticle(idArticile)
      .then(item => {
        openEditForm(item);
      })
      .catch(({ message }) => {
        dispatch(getErrorMessage(message));
      });
  };
  const openComment = (id, commentsCount) => {
    if (commentsCount) {
      dispatch(
        changeStatus(
          modalTypes.OPEN_VIDEO_COMMENT(id, typeComment.NEWS_COMMENT),
        ),
      );
    }
  };
  const openEditForm = id => {
    dispatch(
      changeStatus(modalTypes.OPEN_NEWS_FORM(id, typeComment.NEWS_COMMENT)),
    );
  };
  const removeArticle = id => {
    dispatch(changeStatus(modalTypes.CONFIRM_REMOVE_ARTICLE(id)));
  };
  const handleNextNewsList = ({ selected }) => {
    dispatch(updateArticle(10 * selected, listLanguage));
    dispatch(saveNewsOffset(10 * selected));
  };
  const renderListArticles = () => {
    if (items) {
      return items.map(({ title, dateCreate, _id, commentsCount, lang }) => (
        <div className="news__item" key={_id}>
          <div className="news__item-lang">{lang}</div>

          <div className="news__item-name">{title}</div>
          <div className="news__item-date">
            {dateFormat(dateCreate, 'dd.mm.yyyy')}
          </div>

          <img
            className="news__item-img styleKey"
            src={edit}
            onClick={() => getArticleActive(_id)}
            alt="edit"
          />
          <img
            className="news__item-img styleKey"
            src={remove}
            onClick={() => removeArticle(_id)}
            alt="remove"
          />
          <img
            className="news__item-img styleKey"
            src={comments}
            onClick={() => openComment(_id, commentsCount)}
            alt="comments"
          />
        </div>
      ));
    }
  };

  return (
    <div className="news">
      <div className="news-news-list">
        <div className="news__head">
          <div className="formNews__select-container">
            <label className="formNews__label">Select news language</label>
            <select
              className="formNews__select"
              name="country"
              placeholder="select"
              onChange={({ target }) => {
                setListLanguage(target.value);
              }}
              value={listLanguage}
            >
              <option value="">All</option>
              <option value="EN">English</option>
              <option value="RU">Russian</option>
              <option value="AZ">Azerbaijani</option>
            </select>
          </div>
          <button
            className="button"
            onClick={() =>
              dispatch(changeStatus(modalTypes.OPEN_EDIT_NEWS_FORM))
            }
          >
            add news
          </button>
        </div>
        <h1>News list</h1>
        {renderListArticles()}
        <ReactPaginate
          forcePage={0}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(count / 10)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleNextNewsList}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  allArticle: state.news.allNews,
});
export default connect(mapStateToProps, null)(News);
