import { modalTypes } from '../../consts/consts';

import './styles.scss';
import removeIcon from '../../assets/image/remove.png';
import editIcon from '../../assets/image/edit.png';

const PlayerItem = ({ item, changeStatus, isOpponent }) => {
  const {
    _id: id,
    avatar,
    name: {
      first: { en: firstName },
      last: { en: lastName },
    },
  } = item;

  return (
    <li className={'player-item'}>
      <div className={'player-item__inner-wrap'}>
        <div className={'player-item__img'}>
          <img
            className={'player-item__img-player'}
            src={avatar}
            alt="player"
          />
        </div>
        <div className={'player-item__controls'}>
          <img
            className={'player-item__controls-remove'}
            src={removeIcon}
            alt="remove"
            onClick={() =>
              changeStatus(modalTypes.CONFIRM_REMOVE_PLAYER(id, isOpponent))
            }
          />
          <img
            className={'player-item__controls-edit'}
            onClick={() =>
              changeStatus(
                isOpponent
                  ? modalTypes.EDIT_PLAYER_OPPONENT(id)
                  : modalTypes.EDIT_PLAYER(id),
              )
            }
            src={editIcon}
            alt="edit"
          />
        </div>
      </div>
      <div>
        <p>
          <span className={'player-item__name'}>Name: </span> {firstName}{' '}
          {lastName}
        </p>
      </div>
    </li>
  );
};

export default PlayerItem;
