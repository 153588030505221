import { modalTypes } from '../../consts/consts';

import removeIcon from '../../assets/image/remove.png';
import editIcon from '../../assets/image/edit.png';
import './styles.scss';

const CategoriesItem = ({ item, changeStatus }) => {
  const { icon, name, _id, color } = item;

  const handleRemoveItem = () => {
    changeStatus(modalTypes.CONFIRM_REMOVE_CATEGORY(_id));
  };

  const handleEditItem = () => {
    changeStatus(modalTypes.EDIT_CATEGORY(_id));
  };

  const styleColor = {
    background: color,
  };

  return (
    <li className={'categories-item'}>
      <div className={'categories-item__inner-wrap'}>
        <div className={'categories-item__img'}>
          <img
            className={'categories-item__img-category'}
            src={icon}
            alt="category"
          />
        </div>
        <div className={'categories-item__controls'}>
          <img
            className={'categories-item__img-remove'}
            onClick={handleEditItem}
            src={editIcon}
            alt="edit"
          />
          <img
            className={'categories-item__img-remove'}
            onClick={handleRemoveItem}
            src={removeIcon}
            alt="edit"
          />
        </div>
      </div>
      <div>
        <span className={'categories-item__name'}>color: </span>
        <div style={styleColor} className={'categories-item__color'}></div>
      </div>
      <p>
        <span className={'categories-item__name'}>name: </span> {name}
      </p>
    </li>
  );
};

export default CategoriesItem;
