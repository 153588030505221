export default {
  ADD_CATEGORY_REQUEST: 'ADD_CATEGORY_REQUEST',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',
  EDIT_CATEGORY_REQUEST: 'EDIT_CATEGORY_REQUEST',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_ERROR: 'EDIT_CATEGORY_ERROR',
};
