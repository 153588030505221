import {
  postFormData,
  post,
  get,
  remove,
  put,
  putFormData,
} from '../apiService';

export const getEvent = () => {
  return get('/event/?limit=15');
};
export const getSortedEvent = (sortType, offset) => {
  const base = `/event/?limit=15`;
  const offsetEvent = `&offset=${offset || 0}`;
  const sortEvent = `&sortBy=${sortType || 0}`;
  const url = base + offsetEvent + sortEvent;

  return get(url);
};

// export const getOffsetEvent = type => {
//   return get(`/event/?sortBy=${type}`);
// };

export const postEvent = body => {
  return postFormData('/event/', body);
};

export const deleteEvent = id => {
  return remove(`/event/${id}`);
};

//game

export const getGameList = () => {
  return get('/game/?limit=15');
};

export const getGameById = id => {
  return get(`/game/${id}/`);
};

export const deleteGameCommentById = (id, commentId) => {
  return remove(`/game/${id}/comment/${commentId}`);
};

export const getSortedGameList = (sortType, offset) => {
  const base = `/game/?limit=15`;
  const offsetGame = `&offset=${offset || 0}`;
  const sortGame = `&sortBy=${sortType || 0}`;
  const url = base + offsetGame + sortGame;

  return get(url);
};

export const postGame = body => {
  return post('/game/', body);
};

export const putGamePhoto = (id, body) => {
  return putFormData(`/game/${id}/upload-photo`, body);
};

export const deleteGame = id => {
  return remove(`/game/${id}`);
};

export const putGame = (id, body) => {
  return put(`/game/${id}`, body);
};

//tournament
export const getTournamentList = () => {
  return get('/tournament/?limit=15');
};

export const getTournamentById = id => {
  return get(`/tournament/${id}/`);
};

export const getSortedTournamentList = (sortType, offset) => {
  const base = `/tournament/?limit=15`;
  const offsetTournament = `&offset=${offset || 0}`;
  const sortTournament = `&sortBy=${sortType || 0}`;
  const url = base + offsetTournament + sortTournament;

  return get(url);
};

export const postTournament = body => {
  return post('/tournament/', body);
};

export const deleteTournament = id => {
  return remove(`/tournament/${id}`);
};

export const putTournament = (id, body) => {
  return put(`/tournament/${id}`, body);
};

export const postTournamentLogo = (id, formData) => {
  return putFormData(`/tournament/${id}/upload-logo`, formData);
};
