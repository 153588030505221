const types = {
  ADD_PARTNERS_LIST_REQUEST: 'ADD_PARTNERS_LIST_REQUEST',
  ADD_STAFF_LIST_REQUEST: 'ADD_STAFF_LIST_REQUEST',
  ADD_ACTIVE_PARTNERS_REQUEST: 'ADD_ACTIVE_PARTNERS_REQUEST',
  REMOVE_ACTIVE_PARTNERS: 'REMOVE_ACTIVE_PARTNERS',
  SET_ACTIVE_PARTNERS_OFFSET: 'SET_ACTIVE_PARTNERS_OFFSET',
  SET_ACTIVE_STAFF_OFFSET: 'SET_ACTIVE_STAFF_OFFSET',
};

export default types;
