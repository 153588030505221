import { reset } from 'redux-form';
import { changeStatus } from '../actions/modalActions';
import * as achievementActions from '../actions/achievementActions';
import * as api from '../../api/achievement-service/achievement-service';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';

import { successfulMessage } from '../../consts/notifySuccessfulMessage';

export const fetchAchievements = () => dispatch => {
  dispatch(achievementActions.getAchievementsRequest());
  return api
    .getAchievements()
    .then(({ items }) =>
      dispatch(achievementActions.getAchievementsSuccess(items)),
    )
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const sendAchievement = (data = {}) => dispatch => {
  dispatch(achievementActions.addAchievementRequest());
  return api
    .postAchievement(data)
    .then(achievement => {
      dispatch(achievementActions.addAchievementSuccess(achievement));
      dispatch(changeStatus(false));
      dispatch(reset('achievement'));
      dispatch(successNotification(successfulMessage.ACHIEVEMENT_ADDED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const editAchievement = (id, data = {}) => dispatch => {
  dispatch(achievementActions.editAchievementsRequest());

  return api
    .editAchievementById(id, data)
    .then(achievement => {
      dispatch(achievementActions.editAchievementsSuccess(achievement));
      dispatch(changeStatus(false));
      dispatch(reset('achievement'));
      dispatch(successNotification(successfulMessage.ACHIEVEMENT_ADDED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removeAchievement = id => dispatch => {
  dispatch(achievementActions.deleteAchievementsRequest());
  return api
    .deleteAchievement(id)
    .then(() => {
      dispatch(achievementActions.deleteAchievementsSuccess(id));
      dispatch(successNotification(successfulMessage.ACHIEVEMENT_REMOVED));
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};
