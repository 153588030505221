import types from '../types/mediaTypes';

const initialState = {
  album: {
    items: [],
    count: 0,
  },
  video: {
    items: [],
    count: 0,
  },
  streamList: {
    items: [],
    count: 0,
  },
  albumsItem: [],
  countAlbum: null,
  activeOffsetAlbum: 0,
  activeOffsetVideo: 0,
  activeOffsetStream: 0,

  countVideo: null,
};

const mediaReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADD_ALBUM_LIST_REQUEST:
      return { ...state, album: payload };
    case types.ADD_VIDEO_LIST_REQUEST:
      return { ...state, video: payload };
    case types.ADD_STREAM_LIST_REQUEST:
      return { ...state, streamList: payload };
    case types.ADD_ALBUM_ITEM_REQUEST:
      return { ...state, albumsItem: payload };
    case types.SET_ACTIVE_VIDEO_OFFSET:
      return { ...state, activeOffsetVideo: payload };
    case types.SET_ACTIVE_STREAM_OFFSET:
      return { ...state, activeOffsetStream: payload };
    case types.SET_ACTIVE_ALBUM_OFFSET:
      return { ...state, activeOffsetAlbum: payload };
    default:
      return state;
  }
};

export default mediaReducers;
