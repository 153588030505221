import types from '../types/categoryTypes';

const categoryReducer = (state = [], { type, payload }) => {
  switch (type) {
    case types.GET_CATEGORIES_SUCCESS:
      return payload;
    case types.ADD_CATEGORY_SUCCESS:
      return [...state, payload];
    case types.DELETE_CATEGORY_SUCCESS:
      return state.filter(category => category._id !== payload);
    case types.EDIT_CATEGORY_SUCCESS:
      return state.map(category =>
        category._id === payload._id ? payload : category,
      );
    default:
      return state;
  }
};

export default categoryReducer;
