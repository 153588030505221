import React, { useEffect, useState } from 'react';
import SelectPlayers from '../SelectPlayers/SelectPlayers';
import { getPlayers } from '../../api/players-service/players-service';
import { errorNotification } from '../../redux/operations/notificationOperation';
import { useDispatch } from 'react-redux';

const SelectPlayersContainer = ({ addPlayerToTeam, isOpponent }) => {
  const [players, setPlayers] = useState({ items: [], count: 0 });
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [limit, setLimit] = useState(15);
  const dispatch = useDispatch();
  useEffect(() => {
    getPlayers({ isOpponent })
      .then(r => {
        setPlayers(r);
      })
      .catch(({ message }) => dispatch(errorNotification(message)));
  }, []);

  const handleSelectPlayers = e => {
    const check = selectedPlayers.find(playerId => e.target.value === playerId);

    if (!check) {
      setSelectedPlayers([...selectedPlayers, e.target.value]);
      addPlayerToTeam([...selectedPlayers, e.target.value]);
    } else {
      const newArr = selectedPlayers.filter(idUser => idUser !== check);
      setSelectedPlayers([...newArr]);
      addPlayerToTeam([...newArr]);
    }
  };
  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && players.count > limit) {
      getPlayers({ isOpponent, offset: limit })
        .then(r => {
          setPlayers(({ items }) => {
            return { count: r.count, items: items.concat(r.items) };
          });
        })
        .catch(({ message }) => dispatch(errorNotification(message)));
      setLimit(prev => prev + 15);
    }
  };
  return (
    <SelectPlayers
      handleSelectPlayers={handleSelectPlayers}
      handleScroll={handleScroll}
      players={players.items}
    />
  );
};

export default SelectPlayersContainer;
