import book from '../../router/book';

export const navigationList = {
  main: {
    text: 'Main page',
    link: book.home,
    key: 'main',
  },
  about: {
    text: 'About',
    link: book.about,
    key: 'about',
  },
  media: {
    text: 'Media',
    link: book.media,
    key: 'media',
  },
  events: {
    text: 'Events',
    link: book.events,
    key: 'events',
  },
  shop: {
    text: 'Shop',
    link: book.shop,
    key: 'shop',
  },
  team: {
    text: 'Team',
    link: book.team,
    key: 'team',
  },
  news: {
    text: 'News',
    link: book.news,
    key: 'news',
  },
  // opponent: {
  //   text: 'Opponents',
  //   link: book.opponents,
  //   key: 'opponents',
  // },
};

export const activeNavigationStyles = {
  background: '#fff',
  color: '#000',
  opacity: 1,
};
