import {
  post,
  postFormData,
  get,
  put,
  putFormData,
  remove,
} from '../apiService';

export function getVideoList({ type, params = {} }) {
  const { language = '', active = 0, search = '', sortBy = '' } = params;

  const languageInfo = language ? '&lang=' + language : '';
  const activeInfo = active ? '&offset=' + active : '&offset=0';
  const limitInfo = '&limit=6';
  const sortInfo = `?sortBy=${sortBy}`;
  const kind = '&kind=' + type;

  const searchInfo = `&search=${search}`;

  const url =
    '/video/' +
    sortInfo +
    activeInfo +
    limitInfo +
    kind +
    languageInfo +
    searchInfo;

  return get(url);
}

export function postVideo(body) {
  return post('/video', body);
}

export function getVideo(id) {
  return get('/video/' + id);
}

export function putVideo(id, body) {
  return put('/video/' + id, body);
}

export function uploadCoverImage(id, body) {
  const url = '/video/' + id + '/upload-cover';
  return putFormData(url, body);
}

export function deleteCoverImage(id) {
  const url = '/video/' + id + '/delete-cover';
  return remove(url);
}

export function deleteVideo(id) {
  return remove('/video/' + id);
}

export function getAlbumList(active, limit) {
  const activeInfo = active ? '?offset=' + active : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=5';
  return get('/album/' + activeInfo + limitInfo);
}

export function getAlbum(id) {
  return get('/album/' + id);
}

export function postAlbum(formData) {
  return postFormData('/album', formData);
}

export function putAlbum(id, formData) {
  return putFormData('/album/' + id, formData);
}

export function deleteAlbum(id) {
  return remove('/album/' + id);
}

export function deletePhoto(idAlbum, idPhoto) {
  return remove('/album/' + idAlbum + '/photo/' + idPhoto);
}

export function deleteCommentPhoto(idPhoto, idComment) {
  return remove('/photo/' + idPhoto + '/comment/' + idComment);
}

export function getPhoto(idPhoto) {
  return get('/photo/' + idPhoto);
}

export function deleteCommentVideo(idVideo, idComment) {
  return remove('/video/' + idVideo + '/comment/' + idComment);
}
