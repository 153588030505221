import React from 'react';
import './style.scss';
import { getCategory } from '../../api/category-service/category-service';
import { getErrorMessage } from '../../redux/actions/errorHandlerAction';
import { connect } from 'react-redux';

class TagsSelector extends React.Component {
  state = {
    category: [],
  };

  componentDidMount() {
    getCategory()
      .then(response => {
        if (response.items) {
          this.setState({ category: response.items });
          if (this.props.activeTag) {
            this.updateCategory(response.items);
          }
        }
      })
      .catch(({ message }) => {
        this.props.errorMessage(message);
      });
  }

  updateCategory = category => {
    const categoryActtiveId = this.props.activeTag.map(value => value._id);
    const newCategory = category.map(value => {
      value.selected = categoryActtiveId.includes(value._id);
      return value;
    });
    this.setState({ category: newCategory });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeTag !== this.props.activeTag) {
      const update = prevProps.activeTag
        ? JSON.stringify(prevProps.activeTag.sort()) !==
          JSON.stringify(this.props.activeTag.sort())
        : true;
      if (update) {
        this.updateCategory(this.state.category);
      }
    }
  }

  checkingValue = index => {
    const newCategory = [...this.state.category];
    newCategory[index].selected = !this.state.category[index].selected;
    this.setState({ category: newCategory });

    const newCategoryId = newCategory.filter(item => item.selected);
    this.props.setTag(newCategoryId.map(e => e._id));
  };
  renderCategory = () => {
    return this.state.category.map((item, index) => {
      return (
        <div
          className={
            item.selected
              ? 'tagsSelector__item-active'
              : 'tagsSelector__item ' + item.selected
          }
          onClick={() => {
            this.checkingValue(index);
          }}
          key={item._id}
        >
          <img className={'tagsSelector__icon'} src={item.icon} alt={'icon'} />
        </div>
      );
    });
  };

  render() {
    return <div className="tagsSelector">{this.renderCategory()}</div>;
  }
}

const mapDispatchToProps = dispatch => ({
  errorMessage: payload => dispatch(getErrorMessage(payload)),
});

export default connect(null, mapDispatchToProps)(TagsSelector);
