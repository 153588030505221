import types from '../types/aboutTypes';

const initialState = {
  staff: {
    items: [],
    count: 0,
  },
  partners: {
    items: [],
    count: 0,
  },
  activePartners: {},
  activeOffsetStaff: 0,
  activeOffsetPartners: 0,
};

const aboutReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_ACTIVE_STAFF_OFFSET:
      return { ...state, activeOffsetStaff: payload };
    case types.SET_ACTIVE_PARTNERS_OFFSET:
      return { ...state, activeOffsetPartners: payload };
    case types.ADD_PARTNERS_LIST_REQUEST:
      return { ...state, partners: payload };
    case types.ADD_STAFF_LIST_REQUEST:
      return { ...state, staff: payload };
    case types.ADD_ACTIVE_PARTNERS_REQUEST:
      return { ...state, activePartners: payload };
    case types.REMOVE_ACTIVE_PARTNERS:
      return { ...state, activePartners: initialState.activePartners };
    default:
      return state;
  }
};

export default aboutReducers;
