import { postFormData, get, putFormData, remove } from '../apiService';

export function getNewsAll(active, language) {
  const activeInfo = active ? '?offset=' + active : '?offset=0';
  const limitInfo = '&limit=10';
  const languageInfo = language ? '&lang=' + language : '';
  return get('/news/' + activeInfo + limitInfo + languageInfo);
}

export function getArticle(idArticle) {
  return get('/news/' + idArticle);
}

export function postNewsArticle(body) {
  return postFormData('/news', body);
}

export function putNewsArticle(idArticle, body) {
  return putFormData('/news/' + idArticle, body);
}

export function deleteArticle(idArticle) {
  return remove('/news/' + idArticle);
}

export function deleteArticleComment(idArticle, idComment) {
  return remove('/news/' + idArticle + '/comment/' + idComment);
}
