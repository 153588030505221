import { connect } from 'react-redux';
import { closeNotify } from '../../redux/actions/notifyActions';
import { notifySelector } from '../../redux/selectors/selectors';

import './styles.scss';

const Notify = ({ notifyMessage: { type, message }, clearMessage }) => (
  <div
    className={`handler ${
      type === 'success' ? 'handler__success' : 'handler__error'
    } styleKey`}
    onClick={() => {
      clearMessage();
    }}
  >
    {type && <p className="handler-message">{message}</p>}
  </div>
);

const mapDispatchToProps = dispatch => {
  return {
    clearMessage: () => dispatch(closeNotify()),
  };
};
const mapStateToProps = state => ({
  notifyMessage: notifySelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notify);
