import { createSelector } from 'reselect';

export const playersSelector = state => state.players;
export const achievementsSelector = state => state.achievements;
export const teamSelector = state => state.team;
export const categoriesSelector = state => state.categories;
export const modal = state => state.modal;

// events game
export const gameSelector = state => state.event.game;
export const gameSortingParamsSelector = state =>
  state.event.game.sortingParams;
export const gameCountSelector = state => state.event.game.count;
export const gameListSelector = state => state.event.game.items;
export const gameInnerFormSelector = state => state.event.game.isOpenInnerForm;

export const notifySelector = state => state.notify;

export const getItemToEditSelector = playerId =>
  createSelector([playersSelector], playersList => {
    const editPlayer = playersList.find(({ id }) => playerId === id);

    return editPlayer;
  });

//opponent
export const opponentGamesSelector = state => state.opponent.teams;
export const opponentTeamsListSelector = state => state.opponent.teams.items;

// events tournament
export const tournamentSelector = state => state.event.tournament;
export const tournamentSortingParamsSelector = state =>
  state.event.tournament.sortingParams;
export const tournamentCountSelector = state => state.event.tournament.count;
export const tournamentListSelector = state => state.event.tournament.items;

//
export const albumItemSelector = state => state.media.albumsItem;

//auth
export const authErrorSelector = state => state.authentication.errorMessage;

//media
export const mediaAlbumSelector = state => state.media.album;
export const mediaAlbumVideo = state => state.media.video;
export const mediaAlbumStream = state => state.media.streamList;
export const activeOffsetAlbumSelector = state => state.media.activeOffsetAlbum;
export const activeOffsetVideoSelector = state => state.media.activeOffsetVideo;

// shop links
export const shopLinksSelector = state => state.shop;
