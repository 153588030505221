import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { loginUser } from '../../redux/actions/authenticationActions';
import { authErrorSelector } from '../../redux/selectors/selectors';
import book from '../../router/book';

import './styles.scss';

const LoginForm = ({ loginUser, errorMessage, history }) => {
  const [email, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const emailReq = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,6}$/i;

  const userLogin = e => {
    e.preventDefault();
    const isValid = emailIsValid && passwordIsValid;
    isValid &&
      loginUser({ password, email }).then(() => history.push(book.home));
  };

  const checkingInput = ({ target }) => {
    const { name, value } = target;
    const isValidPasswordLength = value.length >= 6;
    const isValidEmail = emailReq.test(value);

    if (name === 'login') {
      setEmailIsValid(isValidEmail ? true : false);
      setLogin(value);
    } else {
      setPasswordIsValid(isValidPasswordLength ? true : false);
      setPassword(value);
    }
  };

  const getButtonStatus = () =>
    emailIsValid && passwordIsValid ? 'button' : 'button button-disabled';

  return (
    <form className="login-form">
      <label className="login-form__label">
        <input
          className="input login-form__input"
          placeholder="login"
          name={'login'}
          onChange={checkingInput}
          value={email}
        />
      </label>
      <label className="login-form__label">
        <input
          className="input"
          placeholder="Password"
          name={'password'}
          type="password"
          onChange={checkingInput}
          value={password}
        />
      </label>
      <button type="submit" className={getButtonStatus()} onClick={userLogin}>
        Login
      </button>

      <div className="login-form__error">
        {errorMessage && (
          <p className="login-form__error-text"> {errorMessage}</p>
        )}
      </div>
    </form>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: value => dispatch(loginUser(value)),
  };
};
const mapStateToProps = state => ({
  errorMessage: authErrorSelector(state),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginForm));
