import types from '../types/errorHandlerReducers';

export const getErrorMessage = value => ({
  type: types.ADD_ERROR_TEXT,
  payload: value,
});

export const closeErrorMessage = () => ({
  type: types.REMOVE_ERROR_TEXT,
});
