export const optionsHistory = {
  mode: 'classic',
  rtl: false,
  katex: 'window.katex',
  font: ['Arial', 'tahoma', 'Courier New,Courier'],
  fontSize: [8, 10, 14, 18, 24, 36],
  formats: ['p', 'blockquote', 'h1', 'h2', 'h3'],
  colorList: [
    [
      '#ff0000',
      '#ff4000',
      '#ff8000',
      '#ffbf00',
      '#ffff00',
      '#bfff00',
      '#80ff00',
      '#40ff00',
      '#00ff00',
      '#00ff40',
      '#00ff80',
      '#00ffbf',
      '#00ffff',
      '#00bfff',
      '#0080ff',
      '#0040ff',
      '#0000ff',
      '#4000ff',
      '#8000ff',
      '#bf00ff',
      '#ff00ff',
      '#ff00bf',
      '#ff0080',
      '#ff0040',
      '#ff0000',
    ],
  ],
  imageMultipleFile: true,
  videoFileInput: false,
  tabDisable: false,
  lineHeights: [
    {
      text: 'Single',
      value: 1,
    },
    {
      text: 'Double',
      value: 2,
    },
  ],
  paragraphStyles: [
    'spaced',
    {
      name: 'Box',
      class: '__se__customClass',
    },
  ],
  textStyles: [
    'translucent',
    {
      name: 'Emphasis',
      style: '-webkit-text-emphasis: filled;',
      tag: 'span',
    },
  ],
  buttonList: [
    [
      'undo',
      'redo',
      'font',
      'fontSize',
      'formatBlock',
      'paragraphStyle',
      'blockquote',
      'bold',
      'underline',
      'italic',
      'strike',
      'subscript',
      'superscript',
      'fontColor',
      'hiliteColor',
      'textStyle',
      'removeFormat',
      'outdent',
      'indent',
      'align',
      'horizontalRule',
      'list',
      'lineHeight',
      'table',
      'link',
      'image',
      'video',
      'math',
      'fullScreen',
      'showBlocks',
      'codeView',
    ],
  ],
  'lang(In nodejs)': 'en',
};
