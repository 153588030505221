import {
  deleteArticle,
  getNewsAll,
  postNewsArticle,
  putNewsArticle,
} from '../../api/news-service/news-service';
import { saveArticleList } from '../actions/newsActions';
import { store } from '../store/store';

import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';
import { changeStatus } from '../actions/modalActions';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';

export const updateArticle = (active, language) => {
  return async dispatch => {
    getNewsAll(active, language)
      .then(items => {
        dispatch(saveArticleList(items));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };
};

export const removeArticle = id => (dispatch, getState) => {
  const { news } = store.getState();
  deleteArticle(id)
    .then(e => {
      dispatch(updateArticle(news.activeOffsetVideo));
      dispatch(successNotification(successfulMessage.ARTICLE_REMOVED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const putArticle = (id, body) => dispatch => {
  const { news } = store.getState();
  putNewsArticle(id, body)
    .then(e => {
      dispatch(updateArticle(news.activeOffsetVideo));
      dispatch(successNotification(successfulMessage.ARTICLE_CHANGED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const postArticle = body => dispatch => {
  const { news } = store.getState();
  postNewsArticle(body)
    .then(() => {
      dispatch(updateArticle(news.activeOffsetVideo));
      dispatch(successNotification(successfulMessage.ARTICLE_ADDED));
      dispatch(changeStatus(false));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};
