import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShopLinksTable from './ShopLinksTable';

import { shopLinksSelector } from 'app/redux/selectors/selectors';
import { changeStatus } from 'app/redux/actions/modalActions';
import { modalTypes } from 'app/consts/consts';
import {
  getShopLinksListOperation,
  removeLinkFromListOperation,
  updateLinkOrderOperation,
} from 'app/redux/operations/shopOperation';

import './styles.scss';

const ShopLinksTableContainer = () => {
  const dispatch = useDispatch();

  const { list, loading, error } = useSelector(shopLinksSelector);

  const onEditButton = useCallback(
    id => {
      dispatch(changeStatus(modalTypes.LINKS_MANAGEMENT(id)));
    },
    [dispatch],
  );

  const onDeleteButton = useCallback(
    id => {
      dispatch(removeLinkFromListOperation({ id }));
    },
    [dispatch],
  );

  const onManageOrder = useCallback(
    params => {
      const { _id, order } = params;

      dispatch(updateLinkOrderOperation({ _id }, { order }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getShopLinksListOperation());
  }, [dispatch]);

  return (
    <div>
      {Boolean(error) && <span>{error}</span>}

      {loading && <span>Loading...</span>}

      {!loading && !Boolean(list.length) && <span>Nothing to show</span>}

      {!loading && Boolean(list.length) && (
        <ShopLinksTable
          items={list}
          handleEditButton={onEditButton}
          handleDeleteButton={onDeleteButton}
          manageOrder={onManageOrder}
        />
      )}
    </div>
  );
};

export default ShopLinksTableContainer;
