import React from 'react';

import CategoriesList from '../../component/CategoriesList/CategoriesListContainer';

import './styles.scss';

const Home = () => {
  return <CategoriesList />;
};

export default Home;
