import { reset } from 'redux-form';
import { changeStatus } from '../actions/modalActions';
import * as categoryActions from '../actions/categoryActions';
import * as api from '../../api/category-service/category-service';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';

export const fetchCategories = () => dispatch => {
  dispatch(categoryActions.getCategoryRequest());
  return api
    .getCategory()
    .then(({ items }) => {
      dispatch(categoryActions.getCategorySuccess(items));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const sendCategory = data => dispatch => {
  dispatch(categoryActions.addCategoryRequest());
  dispatch(changeStatus(false));

  return api
    .postCategory(data)
    .then(response => {
      dispatch(fetchCategories());
      dispatch(successNotification(successfulMessage.CATEGORY_ADDED));
      return response;
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    })
    .finally(() => dispatch(reset('category')));
};

export const editCategory = (id, data) => dispatch => {
  dispatch(categoryActions.editCategoryRequest());

  return api
    .putCategory(id, data)
    .then(category => {
      dispatch(categoryActions.editCategorySuccess(category));
      dispatch(changeStatus(false));
      dispatch(successNotification(successfulMessage.CATEGORY_CHANGED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    })
    .finally(() => dispatch(reset('category')));
};

export const removeCategory = id => dispatch => {
  dispatch(categoryActions.deleteCategoryRequest());

  return api
    .deleteCategory(id)
    .then(() => {
      dispatch(categoryActions.deleteCategorySuccess(id));
      dispatch(successNotification(successfulMessage.CATEGORY_REMOVED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};
