import types from '../types/notifyTypes';

export const getErrorMessage = value => ({
  type: types.ADD_ERROR_MESSAGE,
  payload: { type: 'error', message: value },
});

export const getSuccessMessage = value => ({
  type: types.ADD_SUCCESS_MESSAGE,
  payload: { type: 'success', message: value },
});

export const closeNotify = () => ({
  type: types.CLOSE_NOTIFY,
});
