import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import validationInput from 'app/component/common/validationComponents/ValidationInput';

import { changeStatus } from 'app/redux/actions/modalActions';
import { modalTypes } from 'app/consts/consts';
import * as validators from 'app/utils/validators';
import {
  createLinkFromListOperation,
  updateLinkFromListOperation,
} from 'app/redux/operations/shopOperation';
import { shopLinksSelector } from 'app/redux/selectors/selectors';

import './styles.scss';

let LinksManagement = ({ handleSubmit, item, initialize }) => {
  const dispatch = useDispatch();
  const { list } = useSelector(shopLinksSelector);

  const convertLinkItemForAPI = data => {
    const { createdAt, updatedAt, _id, ...rest } = data;

    return rest;
  };

  const submitForm = data => {
    if (item) {
      dispatch(
        updateLinkFromListOperation(
          { _id: data._id },
          convertLinkItemForAPI(data),
        ),
      );
    } else {
      dispatch(
        createLinkFromListOperation({ ...data, order: list.length + 1 }),
      );
    }
  };

  useEffect(() => {
    item && initialize(item);
  }, [item, initialize]);

  return (
    <form className={'links-management'} onSubmit={handleSubmit(submitForm)}>
      <div className="links-management__field-row">
        <label>Link label</label>
        <Field
          name="name"
          component={validationInput}
          type="text"
          validate={[
            validators.required,
            validators.latinAlphabetLettersAndNumbers,
          ]}
          placeholder="Enter link label"
        />
      </div>
      <div className="links-management__field-row">
        <label>Link URL</label>
        <Field
          name="url"
          component={validationInput}
          type="text"
          validate={[validators.required, validators.url]}
          placeholder="Enter link destination URL"
        />
      </div>
      <div className={'links-management__actions'}>
        <button className="button" type="submit">
          Submit
        </button>
        <button
          onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
          className="button"
          type="button"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

LinksManagement = reduxForm({
  form: 'links-management',
})(LinksManagement);

export default LinksManagement;
