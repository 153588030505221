import types from '../types/notifyTypes';

const initialState = {
  type: null,
  message: null,
};

const notifyReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADD_ERROR_MESSAGE:
      return { ...state, ...payload };
    case types.ADD_SUCCESS_MESSAGE:
      return { ...state, ...payload };
    case types.CLOSE_NOTIFY:
      return initialState;
    default:
      return state;
  }
};

export default notifyReducers;
