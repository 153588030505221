import React from 'react';
import ReactDOM from 'react-dom';
import Main from './app/router/router';
import './app/assets/styles/styles.scss';
import { Provider } from 'react-redux';
import { store } from './app/redux/store/store';
import Modal from './app/component/Modal/RenderModals';
import ErrorBoundary from './app/component/ErrorBoundary/ErrorBoundary';
import ErrorMessage from './app/component/ErrorMessage/ErrorMessage';
import Notify from './app/component/Notify/Notify';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Main />
      <Modal />
      <ErrorMessage />
      <Notify />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
