import types from '../types/teamTypes';

const initialState = { count: 0, items: [] };

const teamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TEAM_SUCCESS:
      return payload;
    case types.ADD_TEAM_SUCCESS:
      return [...state, payload];
    case types.DELETE_TEAM_SUCCESS:
      return state.filter(team => team._id !== payload);

    default:
      return state;
  }
};

export default teamReducer;
