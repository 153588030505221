import { modalTypes } from '../../consts/consts';
import PlayerItem from '../PlayerItem/PlayerItem';

import './styles.scss';

const PlayerList = ({
  changeStatus,
  playersData,
  removePlayer,
  isOpponent,
}) => {
  return (
    <div>
      <div className="teams__head">
        <h1>Players</h1>
        <button
          className={'button'}
          onClick={() =>
            changeStatus(
              isOpponent ? modalTypes.PLAYER_OPPONENT : modalTypes.PLAYER,
            )
          }
        >
          add new player
        </button>
      </div>
      <div className={'player'}>
        <ul className={'player__list'}>
          {playersData?.map(item => (
            <PlayerItem
              key={item._id}
              item={item}
              isOpponent={isOpponent}
              removePlayer={removePlayer}
              changeStatus={changeStatus}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlayerList;
