import types from '../types/aboutTypes';

export const addPartnerList = value => ({
  type: types.ADD_PARTNERS_LIST_REQUEST,
  payload: value,
});

export const addStaffList = value => ({
  type: types.ADD_STAFF_LIST_REQUEST,
  payload: value,
});

export const setPartner = value => ({
  type: types.ADD_ACTIVE_PARTNERS_REQUEST,
  payload: value,
});

export const removeActivePartners = () => ({
  type: types.REMOVE_ACTIVE_PARTNERS,
});

export const setActiveOffsetPartners = value => ({
  type: types.SET_ACTIVE_PARTNERS_OFFSET,
  payload: value,
});

export const setActiveOffsetStaff = value => ({
  type: types.SET_ACTIVE_STAFF_OFFSET,
  payload: value,
});
