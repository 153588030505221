import { postFormData, get, remove, putFormData } from '../apiService';

export const postCategory = body => {
  return postFormData('/tag/', body);
};

export const getCategory = () => {
  return get('/tag/');
};

export const getCategoryById = id => {
  return get(`/tag/${id}`);
};

export const putCategory = (id, data) => {
  return putFormData(`/tag/${id}`, data);
};

export const deleteCategory = id => {
  return remove(`/tag/${id}`);
};
