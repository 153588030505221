import types from '../types/categoryTypes';

export const addCategoryRequest = () => ({
  type: types.ADD_CATEGORY_REQUEST,
});

export const addCategorySuccess = (data = {}) => ({
  type: types.ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const addCategoryError = (error = '') => ({
  type: types.ADD_CATEGORY_ERROR,
  payload: error,
});

export const getCategoryRequest = () => ({
  type: types.GET_CATEGORIES_REQUEST,
});

export const getCategorySuccess = (data = {}) => ({
  type: types.GET_CATEGORIES_SUCCESS,
  payload: data,
});

export const getCategoryError = (error = '') => ({
  type: types.GET_CATEGORIES_ERROR,
  payload: error,
});

export const editCategoryRequest = () => ({
  type: types.EDIT_CATEGORY_REQUEST,
});

export const editCategorySuccess = id => ({
  type: types.EDIT_CATEGORY_SUCCESS,
  payload: id,
});

export const editCategoryError = (error = '') => ({
  type: types.EDIT_CATEGORY_ERROR,
  payload: error,
});

export const deleteCategoryRequest = () => ({
  type: types.DELETE_CATEGORY_REQUEST,
});

export const deleteCategorySuccess = id => ({
  type: types.DELETE_CATEGORY_SUCCESS,
  payload: id,
});

export const deleteCategoryError = (error = '') => ({
  type: types.DELETE_CATEGORY_ERROR,
  payload: error,
});
