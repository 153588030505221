export const twitchAPI = url => {
  return fetch(url, {
    headers: {
      'client-id': 'l1dijbw5memfetvj6byb7o4pzb5z1a',
      accept: 'application/vnd.twitchtv.v5+json',
    },
  }).then(response => {
    return response.json();
  });
};
