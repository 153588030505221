import { reset } from 'redux-form';
import { changeStatus } from '../actions/modalActions';
import * as teamActions from '../actions/teamActions';
import * as api from '../../api/team-service/team-service';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';
import { changeGameInnerFormStatus } from '../actions/gameActions';
import { modalTypes } from '../../consts/consts';
import { createFormData } from '../../utils/formDataOperations';

const dispatchActionDependingType = renderType => {
  return renderType === 'gameFormCreate'
    ? changeGameInnerFormStatus(false)
    : changeStatus(modalTypes.CLOSE);
};

export const fetchAllTeam =
  (params = {}) =>
  dispatch => {
    dispatch(teamActions.getTeamRequest());
    return api
      .getAllTeam(params)
      .then(r => dispatch(teamActions.getTeamSuccess(r)))
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };

export const fetchTeam = id => dispatch => {
  dispatch(teamActions.getTeamRequest());
  return api
    .getTeam(id)
    .then(response => {
      return response;
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const sendTeam =
  (data = {}, renderType) =>
  dispatch => {
    dispatch(teamActions.addTeamRequest());

    return api
      .postTeam({ ...data, isOpponent: false })
      .then(() => {
        dispatch(dispatchActionDependingType(renderType));
        dispatch(fetchAllTeam({ isOpponent: false }));
        dispatch(reset('team'));
        dispatch(successNotification(successfulMessage.TEAM_ADDED));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };

export const sendOpponentTeam =
  (data = {}, renderType, uploadImages) =>
  dispatch => {
    dispatch(teamActions.addOpponentTeamRequest());

    return api
      .postTeam({ ...data, isOpponent: true })
      .then(({ _id }) => {
        const imageFormData = createFormData({ logo: uploadImages[0] });

        api
          .postOpponentTeamLogo(_id, imageFormData)
          .then(() => {
            dispatch(dispatchActionDependingType(renderType));
            dispatch(fetchAllTeam({ isOpponent: true }));
          })
          .catch(({ message }) => {
            dispatch(errorNotification(message));
          });

        dispatch(reset('team'));
        dispatch(successNotification(successfulMessage.TEAM_ADDED));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };

export const removeTeam = (id, isOpponent) => dispatch => {
  dispatch(teamActions.deleteTeamRequest());
  return api
    .deleteTeam(id)
    .then(() => {
      dispatch(fetchAllTeam({ isOpponent }));
      dispatch(successNotification(successfulMessage.TEAM_REMOVED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};
