import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { connect, useDispatch } from 'react-redux';

import Partner from '../../component/partner/partner';
import {
  cleatActivePartners,
  updatePartnerList,
  updateStaffList,
} from '../../redux/operations/aboutOperation';
import { changeStatus } from '../../redux/actions/modalActions';
import { modalTypes } from '../../consts/consts';
// import Staffmate from '../../component/staffmate/staffmate';
import {
  setActiveOffsetPartners,
  // setActiveOffsetStaff,
} from '../../redux/actions/aboutAction';

import './styles.scss';

const About = ({ staff, partners }) => {
  const dispatch = useDispatch();
  useState(() => {
    dispatch(updatePartnerList());
    dispatch(updateStaffList());
  });

  // const openAddStaff = () => {
  //   dispatch(changeStatus(modalTypes.OPEN_ADD_STAFF_FORM()));
  // };

  const openAddPartner = () => {
    dispatch(cleatActivePartners());
    dispatch(changeStatus(modalTypes.OPEN_ADD_PARTNER_FORM()));
  };

  const removePartner = id => {
    dispatch(changeStatus(modalTypes.CONFIRM_REMOVE_PARTNER(id)));
  };

  // const removeStaff = id => {
  //   dispatch(changeStatus(modalTypes.CONFIRM_REMOVE_STAFF(id)));
  // };
  const openEditPartner = id => {
    dispatch(changeStatus(modalTypes.OPEN_EDIT_PARTNER_FORM(id)));
  };

  const getPartners = () => {
    return partners.items.map(item => (
      <Partner
        value={item}
        remove={removePartner}
        edit={openEditPartner}
        key={item._id}
      />
    ));
  };
  // const getStaffList = () => {
  //   return staff.items.map(item => (
  //     <Staffmate value={item} remove={removeStaff} key={item._id} />
  //   ));
  // };
  const handleNextPartnersList = ({ selected }) => {
    dispatch(setActiveOffsetPartners(6 * selected));
    dispatch(updatePartnerList(6 * selected));
  };
  // const handleNextStaffList = ({ selected }) => {
  //   dispatch(setActiveOffsetStaff(10 * selected));
  //   dispatch(updateStaffList(10 * selected));
  // };
  return (
    <div className={'about'}>
      <div className="about__partners">
        <div className="about__head-table">
          <h1>Partners</h1>
          <button className="button styleKey" onClick={openAddPartner}>
            Add new partner
          </button>
        </div>

        <div className="about__partners-body">{getPartners()}</div>
        <ReactPaginate
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(partners.count / 6)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleNextPartnersList}
        />
      </div>
      {/* <div className="about__partners">
        <div className="about__head-table">
          <h1>Staff</h1>
          <button className={'button styleKey'} onClick={openAddStaff}>
            Add new staff
          </button>
        </div>
        <div className="about__stuff">
          <div className="about__stuff-body">{getStaffList()}</div>
          <ReactPaginate
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(staff.count / 10)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleNextStaffList}
          />
        </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = state => ({
  staff: state.about.staff,
  partners: state.about.partners,
});
export default connect(mapStateToProps, null)(About);
