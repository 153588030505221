import Tooltip from '../Tooltip/Tooltip';

import { modalTypes } from '../../consts/consts';

import removeIcon from '../../assets/image/remove.png';
import teamPlayersIcon from '../../assets/image/team-players.svg';
import './styles.scss';

const TeamItem = ({ id, logo, game, name, changeStatus, isOpponent }) => {
  return (
    <li className={'team-item'}>
      <div className={'team-item__inner-wrap'}>
        <div className="team-item__game">
          <img className={'team-item__game-icon'} src={game} alt="remove" />
        </div>
        <div className={'team-item__img'}>
          <img className={'team-item__img-game'} src={logo} alt="icon game" />
        </div>
        <div className={'team-item__controls'}>
          <img
            className={'team-item__controls-remove'}
            src={removeIcon}
            alt="remove"
            onClick={() =>
              changeStatus(modalTypes.CONFIRM_REMOVE_TEAM(id, isOpponent))
            }
          />
          <Tooltip
            message={'You can change the players of the same team'}
            position={'bottom'}
          >
            <img
              className={'team-item__controls-remove'}
              src={teamPlayersIcon}
              alt="remove"
              onClick={() =>
                changeStatus(
                  isOpponent
                    ? modalTypes.EDIT_OPPONENT_TEAM_PLAYERS(id)
                    : modalTypes.EDIT_TEAM_PLAYERS(id),
                )
              }
            />
          </Tooltip>
        </div>
      </div>

      <p>
        <span className={'team-item__name'}>Name: </span> {name}
      </p>
    </li>
  );
};

export default TeamItem;
