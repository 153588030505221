import { useDispatch } from 'react-redux';

import ShopLinksTableContainer from 'app/component/ShopLinksTable/ShopLinksTableContainer';

import { modalTypes } from 'app/consts/consts';
import { changeStatus } from 'app/redux/actions/modalActions';

import './styles.scss';

const Shop = () => {
  const dispatch = useDispatch();

  const onClick = () => dispatch(changeStatus(modalTypes.LINKS_MANAGEMENT()));

  return (
    <div className="shop-page">
      <div className="shop-page__header">
        <h1>Shop</h1>
        <button className="button" onClick={onClick}>
          Add link
        </button>
      </div>
      <ShopLinksTableContainer />
    </div>
  );
};

export default Shop;
