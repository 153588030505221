import types from '../types/teamTypes';

export const addTeamRequest = () => ({
  type: types.ADD_TEAM_REQUEST,
});

export const addTeamSuccess = (data = {}) => ({
  type: types.ADD_TEAM_SUCCESS,
  payload: data,
});

export const addTeamError = (error = '') => ({
  type: types.ADD_TEAM_ERROR,
  payload: error,
});

//

export const addOpponentTeamRequest = () => ({
  type: types.ADD_OPPONENT_TEAM_REQUEST,
});

export const addOpponentTeamSuccess = (data = {}) => ({
  type: types.ADD_OPPONENT_TEAM_SUCCESS,
  payload: data,
});

export const addOpponentTeamError = (error = '') => ({
  type: types.ADD_OPPONENT_TEAM_ERROR,
  payload: error,
});

//

export const getAllTeamsRequest = () => ({
  type: types.GET_ALL_TEAMS_REQUEST,
});

export const getAllTeamsSuccess = (data = {}) => ({
  type: types.GET_ALL_TEAMS_SUCCESS,
  payload: data,
});

export const getAllTeamsError = (error = '') => ({
  type: types.GET_ALL_TEAMS_ERROR,
  payload: error,
});

export const getTeamRequest = () => ({
  type: types.GET_TEAM_REQUEST,
});

export const getTeamSuccess = (data = {}) => ({
  type: types.GET_TEAM_SUCCESS,
  payload: data,
});

export const getTeamError = (error = '') => ({
  type: types.GET_TEAM_ERROR,
  payload: error,
});

export const deleteTeamRequest = () => ({
  type: types.DELETE_TEAM_REQUEST,
});

export const deleteTeamSuccess = id => ({
  type: types.DELETE_TEAM_SUCCESS,
  payload: id,
});

export const deleteTeamError = (error = '') => ({
  type: types.DELETE_TEAM_ERROR,
  payload: error,
});
