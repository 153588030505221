import types from '../types/shopTypes';

const initialState = {
  list: [],
  loading: true,
  error: '',
};

const shopReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // get list of links
    case types.GET_SHOP_LINKS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SHOP_LINKS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case types.GET_SHOP_LINKS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // remove link from list
    case types.REMOVE_LINK_FROM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_LINK_FROM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter(item => item._id !== payload),
      };

    // update link from list
    case types.UPDATE_LINK_FROM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_LINK_FROM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map(item => {
          if (item._id === payload._id) {
            return payload;
          }

          return item;
        }),
      };

    // create link from list
    case types.CREATE_LINK_FROM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_LINK_FROM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, payload],
      };

    // change link order
    case types.CHANGE_LINK_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CHANGE_LINK_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: (() => {
          const linksList = [...state.list];
          let tmp;
          const elementToChangePosition = linksList.findIndex(
            item => item._id === payload._id,
          );

          const elementOnThisPosition = linksList.findIndex(
            item => item.order === payload.order,
          );

          tmp = linksList[elementOnThisPosition];
          tmp.order = linksList[elementToChangePosition].order;
          linksList[elementToChangePosition].order = payload.order;
          linksList[elementOnThisPosition] = linksList[elementToChangePosition];
          linksList[elementToChangePosition] = tmp;

          return linksList;
        })(),
      };
    default:
      return state;
  }
};

export default shopReducer;
