import { postFormData, putFormData, get, remove } from '../apiService';

export const getPlayers = ({ isOpponent, offset = 0, limit = 18 }) => {
  const opponentQuery = `?isOpponent=${!!isOpponent}`;
  const offsetTeam = `&offset=${offset}`;
  const limitTeam = `&limit=${limit}`;
  return get(`/player/` + opponentQuery + offsetTeam + limitTeam);
};
export const getPlayer = id => {
  return get(`/player/${id}/`);
};

export const postPlayer = body => {
  return postFormData('/player/', body);
};

export const deletePlayer = id => {
  return remove(`/player/${id}`);
};

export const putPlayer = async (id, body) => {
  return putFormData(`/player/${id}`, body);
};
