import types from '../types/shopTypes';

// get list of links
export const getShopLinksListRequest = () => ({
  type: types.GET_SHOP_LINKS_LIST_REQUEST,
});

export const getShopLinksListSuccess = value => ({
  type: types.GET_SHOP_LINKS_LIST_SUCCESS,
  payload: value,
});

export const getShopLinksListError = err => ({
  type: types.GET_SHOP_LINKS_LIST_ERROR,
  payload: err,
});

// create link from list
export const createLinkFromListRequest = () => ({
  type: types.CREATE_LINK_FROM_LIST_REQUEST,
});

export const createLinkFromListSuccess = value => ({
  type: types.CREATE_LINK_FROM_LIST_SUCCESS,
  payload: value,
});

// remove link from list
export const removeLinkFromListRequest = () => ({
  type: types.REMOVE_LINK_FROM_LIST_REQUEST,
});

export const removeLinkFromListSuccess = value => ({
  type: types.REMOVE_LINK_FROM_LIST_SUCCESS,
  payload: value,
});

// update link from list
export const updateLinkFromListRequest = () => ({
  type: types.UPDATE_LINK_FROM_LIST_REQUEST,
});

export const updateLinkFromListSuccess = value => ({
  type: types.UPDATE_LINK_FROM_LIST_SUCCESS,
  payload: value,
});

// change link order
export const changeLinkOrderRequest = () => ({
  type: types.CHANGE_LINK_ORDER_REQUEST,
});

export const changeLinkOrderSuccess = value => ({
  type: types.CHANGE_LINK_ORDER_SUCCESS,
  payload: value,
});
