import book from './book';
//pages
import Home from '../pages/home/home';
import About from '../pages/about/about';
import Events from '../pages/events/events';
import Media from '../pages/media/media';
import Shop from '../pages/shop/shop';
import Team from '../pages/team/team';
import News from '../pages/news/news';
import Opponents from '../pages/opponent/opponents';

export const Routes = [
  {
    path: `${book.home}`,
    component: Home,
    isExact: true,
  },
  {
    path: `${book.about}`,
    component: About,
    isExact: true,
  },
  {
    path: `${book.events}`,
    component: Events,
    isExact: true,
  },
  {
    path: `${book.media}`,
    component: Media,
    isExact: true,
  },
  {
    path: `${book.shop}`,
    component: Shop,
    isExact: true,
  },
  {
    path: `${book.team}`,
    component: Team,
    isExact: true,
  },
  {
    path: `${book.news}`,
    component: News,
    isExact: true,
  },
  {
    path: `${book.opponents}`,
    component: Opponents,
    isExact: true,
  },
];
