import { errorNotification } from 'app/redux/operations/notificationOperation';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import uploadImage from '../../assets/image/uploadFile.svg';
import './styles.scss';

const FileLoader = ({
  name = '',
  errorInfo = { quantity: 4, size: 10000000, type: 'image' },
  callbackValue,
  callbackError,
  type,
  defaultImage = [],
}) => {
  const [files, setFiles] = useState(defaultImage);
  const [editImage, setEditImage] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (defaultImage.length && !editImage) {
      setFiles(defaultImage);
    }
  }, [defaultImage]);
  const getName = name => {
    return name.length > 10
      ? name.slice(0, 2) + '...' + name.slice(name.length - 5, name.length)
      : name;
  };

  const validationLength = newFiles => {
    newFiles.length > errorInfo.quantity - 1
      ? setShowLoader(false)
      : setShowLoader(true);
  };
  const generateError = type => {
    type === 'size'
      ? callbackError({
          error: 'size file is more ' + errorInfo.size / 1000000 + 'mb',
          type: name,
        })
      : callbackError({
          error: 'file is not correct type' + errorInfo.type,
          type: name,
        });
    return false;
  };

  const validationType = newFiles => {
    if (newFiles) {
      return newFiles.filter(file =>
        file.type.split('/')[0] === errorInfo.type
          ? true
          : generateError('type'),
      );
    }
  };

  const validationSize = newFiles => {
    if (newFiles) {
      const arr = newFiles.filter(file => {
        return file.size <= errorInfo.size ? true : generateError('size');
      });
      return validationType(arr);
    }
  };

  function getImageAspectRatio(a, b) {
    return b == 0 ? a : getImageAspectRatio(b, a % b);
  }

  const onFileChange = event => {
    const newFiles = files.concat(validationSize([...event.target.files]));

    if (type === 'news') {
      var picReader = new FileReader();

      picReader.addEventListener('load', function (event) {
        var imageObj = new Image();
        imageObj.src = event.target.result;
        imageObj.onload = function () {
          if (
            `${
              imageObj.width /
              getImageAspectRatio(imageObj.width, imageObj.height)
            }:${
              imageObj.height /
              getImageAspectRatio(imageObj.width, imageObj.height)
            }` !== '16:9'
          ) {
            return dispatch(
              errorNotification('Image should have 16:9 aspect ratio'),
            );
          } else {
            setFiles(newFiles.slice(0, errorInfo.quantity));
            validationLength(newFiles);
            callbackValue(
              newFiles.slice(0, errorInfo.quantity),
              name ? name : '',
            );
          }
        };
      });

      picReader.readAsDataURL(event.target.files[0]);
    } else {
      setFiles(newFiles.slice(0, errorInfo.quantity));
      validationLength(newFiles);
      callbackValue(newFiles.slice(0, errorInfo.quantity), name ? name : '');
    }
  };

  const removeImage = index => {
    const newArray = files.concat();
    newArray.splice(index, 1);
    setShowLoader(true);
    setFiles(newArray);
    callbackValue(newArray, name);
  };
  const getUploadImage = () => {
    return [...files].map((item, index) => {
      let image = new Image();
      image.src = item.url;
      return (
        <div
          className="fileLoader__label fileLoader__label-close-image"
          onClick={() => {
            setEditImage(true);
            removeImage(index);
          }}
          key={index}
        >
          <img
            src={typeof item === 'string' ? item : URL.createObjectURL(item)}
            className="fileLoader__upload-image"
            key={index}
            alt="upload icon"
          />
          <p className="fileLoader__text">
            {typeof item === 'string' ? '' : getName(item.name)}
          </p>
        </div>
      );
    });
  };
  return (
    <div className="fileLoader">
      <div className="fileLoader__image-container">{getUploadImage()}</div>
      {showLoader && files.length <= errorInfo.quantity - 1 ? (
        <label className="fileLoader__label">
          <img
            src={uploadImage}
            className="fileLoader__image"
            alt="file-loader"
          />
          <input
            className={'fileLoader__input'}
            type="file"
            name={name}
            onChange={onFileChange}
            multiple
          />
          <p className="fileLoader__text">UploadFile</p>
        </label>
      ) : (
        ''
      )}
    </div>
  );
};

export default FileLoader;
