const types = {
  ADD_ALBUM_LIST_REQUEST: 'ADD_ALBUM_LIST_REQUEST',
  ADD_VIDEO_LIST_REQUEST: 'ADD_VIDEO_LIST_REQUEST',
  ADD_ALBUM_ITEM_REQUEST: 'ADD_ALBUM_ITEM_REQUEST',
  SET_ACTIVE_VIDEO_OFFSET: 'SET_ACTIVE_VIDEO_OFFSET',
  SET_ACTIVE_ALBUM_OFFSET: 'SET_ACTIVE_ALBUM_OFFSET',
  ADD_STREAM_LIST_REQUEST: 'ADD_STREAM_LIST_REQUEST',
  SET_ACTIVE_STREAM_OFFSET: 'SET_ACTIVE_STREAM_OFFSET',
};
export default types;
