import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// import ReactPaginate from 'react-paginate';

import {
  initAllVideo,
  updateAlbums,
} from '../../redux/operations/mediaOperations';
// import { modalTypes } from '../../consts/consts';
// import { changeStatus } from '../../redux/actions/modalActions';
// import { setActiveOffsetAlbum } from '../../redux/actions/mediaActions';
import VideoList from '../../component/VideoList/VideoList';

import {
  mediaAlbumSelector,
  mediaAlbumVideo,
  mediaAlbumStream,
  activeOffsetAlbumSelector,
  activeOffsetVideoSelector,
} from '../../redux/selectors/selectors';

// import { checkAndTrimStringForDisplay } from '../../utils/stringMethods';
// import { formatData } from '../../utils/dataMethods';

// import defaultGalleryImage from '../../assets/image/default-gallery.png';
import searchIcon from '../../assets/image/search.svg';
import { ReactComponent as SortIcon } from '../../assets/image/sort.svg';
import './styles.scss';

const Media = ({ albums = [] }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    video: '',
    stream: '',
  });

  const [streamParams, setStreamParams] = useState({
    type: 'STREAM-INITIAL',
    params: {
      sortBy: '',
    },
  });

  const [videoParams, setVideoParams] = useState({
    type: 'VIDEO-INITIAL',
    params: {
      sortBy: '',
    },
  });

  useEffect(() => {
    dispatch(updateAlbums());
  }, [dispatch]);

  useEffect(() => {
    dispatch(initAllVideo(streamParams));
  }, [dispatch, streamParams]);

  useEffect(() => {
    dispatch(initAllVideo(videoParams));
  }, [dispatch, videoParams]);

  const handleSearchChange = ({ target }) => {
    setSearch({ ...search, [target.name]: target.value });
  };

  const handleSearchVideoSubmit = e => {
    e.preventDefault();

    const params = { type: 'VIDEO-INITIAL', params: { search: search.video } };
    dispatch(initAllVideo(params));
  };

  const handleSearchStreamSubmit = e => {
    e.preventDefault();
    const params = {
      type: 'STREAM-INITIAL',
      params: { search: search.stream },
    };

    dispatch(initAllVideo(params));
  };

  const handleSortStream = (sortType = 'dataCreate', mediaType = 'STREAM') => {
    const sortBy = sortType === streamParams.params.sortBy ? '' : sortType;

    const params = {
      type: `${mediaType}-INITIAL`,
      params: {
        ...streamParams.params,
        sortBy,
      },
    };

    setStreamParams(params);
  };

  const handleSortVideo = (sortType = 'dataCreate', mediaType = 'VIDEO') => {
    const sortBy = sortType === videoParams.params.sortBy ? '' : sortType;

    const params = {
      type: `${mediaType}-INITIAL`,
      params: {
        ...videoParams.params,
        sortBy,
      },
    };
    setVideoParams(params);
  };

  // const getGalleryItem = () => {
  //   return albums.items.map(
  //     ({
  //       dateCreate,
  //       recentPhotos,
  //       _id,
  //       title: { en: titleEn, ru: titleRu, az: titleAz },
  //     }) => (
  //       <div className="media__gallery__item " key={_id}>
  //         <div className="media__gallery__image-container">
  //           {recentPhotos && (
  //             <img
  //               className="media__gallery__image"
  //               src={recentPhotos[0]}
  //               alt={'previewPhoto'}
  //             />
  //           )}
  //           {!recentPhotos && (
  //             <img
  //               className="media__gallery__image"
  //               src={defaultGalleryImage}
  //               alt={'previewPhoto'}
  //             />
  //           )}
  //         </div>
  //         <p className="media__gallery__data">{formatData(dateCreate)}</p>
  //         <p className="media__gallery__title">Title Russian:</p>
  //         <p className="media__gallery__name">
  //           {checkAndTrimStringForDisplay(titleRu)}
  //         </p>
  //         <p className="media__gallery__title">Title Azerbaijan:</p>
  //         <p className="media__gallery__name">
  //           {checkAndTrimStringForDisplay(titleAz)}
  //         </p>
  //         <p className="media__gallery__title">Title English:</p>
  //         <p className="media__gallery__name">
  //           {checkAndTrimStringForDisplay(titleEn)}
  //         </p>
  //         <div>
  //           <div
  //             className="button media__gallery__key-open styleKey"
  //             onClick={() => dispatch(changeStatus(modalTypes.OPEN_ALBUM(_id)))}
  //           >
  //             open
  //           </div>
  //           <div
  //             className="button button-remove styleKey"
  //             onClick={() =>
  //               dispatch(changeStatus(modalTypes.CONFIRM_REMOVE_ALBUM(_id)))
  //             }
  //           >
  //             delete
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //   );
  // };

  // const handleNextAlbumList = ({ selected }) => {
  //   dispatch(setActiveOffsetAlbum(5 * selected));
  //   dispatch(updateAlbums(5 * selected));
  // };
  const getSortMedia = () => (
    <div className="media-sort">
      <button
        onClick={() => handleSortVideo('tags', 'VIDEO')}
        className="button"
      >
        <span className="media-sort__button-text">Sort by name </span>
        <SortIcon className="media-sort__icon" />
      </button>
      <form className="media-sort__form" onSubmit={handleSearchVideoSubmit}>
        <input
          onChange={handleSearchChange}
          name="video"
          placeholder="Enter video name"
          className="input"
          type="text"
        />
        <button className="button media-sort__button">
          <img className="media-sort__icon" src={searchIcon} alt="search" />
        </button>
      </form>
    </div>
  );
  const getSortStream = () => (
    <div className="media-sort">
      <button
        onClick={() => handleSortStream('tags', 'STREAM')}
        className="button button-border"
      >
        <span className="media-sort__button-text">Sort by name</span>
        <SortIcon className="media-sort__icon" />
      </button>

      <form className="media-sort__form" onSubmit={handleSearchStreamSubmit}>
        <input
          onChange={handleSearchChange}
          name="stream"
          placeholder="Enter stream name"
          className="input"
          type="text"
        />
        <button type="submit" className="button">
          <img className="media-sort__icon" src={searchIcon} alt="search" />
        </button>
      </form>
    </div>
  );
  return (
    <div className="media">
      <VideoList
        handleSort={handleSortVideo}
        typeVideo={'VIDEO'}
        conrtol={getSortMedia}
      />
      <VideoList
        handleSort={handleSortStream}
        typeVideo={'STREAM'}
        conrtol={getSortStream}
      />
      {/* <div className="media__paragraph">
        <p>Albums</p>
        <button
          className="button media__gallery-button styleKey"
          onClick={() => dispatch(changeStatus(modalTypes.CREATE_ALBUM()))}
        >
          add new album
        </button>
      </div>
      <div className="media__item">
        <div className="media__gallery scrollElement">{getGalleryItem()}</div>
        <ReactPaginate
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(albums.count / 5)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleNextAlbumList}
        />
      </div> */}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    albums: mediaAlbumSelector(state),
    video: mediaAlbumVideo(state),
    stream: mediaAlbumStream(state),
    activeOffsetAlbum: activeOffsetAlbumSelector(state),
    activeOffsetVideo: activeOffsetVideoSelector(state),
  };
};
export default connect(mapStateToProps, null)(Media);
