import CategoriesItem from '../CategoriesItem/CategoriesItem';
import { modalTypes } from '../../consts/consts';

import './styles.scss';

const CategoriesList = ({ categoriesData, removeCategory, changeStatus }) => {
  return (
    <div>
      <div className={'categories'}>
        <ul className={'categories__list'}>
          {categoriesData.map(item => (
            <CategoriesItem
              key={item._id}
              item={item}
              removeCategory={removeCategory}
              changeStatus={changeStatus}
            />
          ))}
        </ul>
      </div>
      <button
        className="button"
        onClick={() => changeStatus(modalTypes.CATEGORIES)}
      >
        add new tag
      </button>
    </div>
  );
};

export default CategoriesList;
