import SunEditor from 'suneditor-react';
import React, { useEffect, useRef } from 'react';
import 'suneditor/dist/css/suneditor.min.css';
import { optionsHistory } from './options';

const TextEditor = ({ onChange, value = '' }) => {
  const editorRef = useRef();
  useEffect(() => {}, []);

  return (
    <SunEditor
      showToolbar={true}
      ref={editorRef}
      setOptions={optionsHistory}
      height="200"
      resize={false}
      onChange={e => {
        onChange(e);
      }}
      setContents={value}
    />
  );
};

export default TextEditor;
