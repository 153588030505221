import types from '../types/mediaTypes';

export const saveAlbum = albumList => ({
  type: types.ADD_ALBUM_LIST_REQUEST,
  payload: albumList,
});

export const saveStream = streamList => ({
  type: types.ADD_STREAM_LIST_REQUEST,
  payload: streamList,
});

export const saveVideo = videoList => ({
  type: types.ADD_VIDEO_LIST_REQUEST,
  payload: videoList,
});

export const saveAlbumsItem = albumsItem => ({
  type: types.ADD_ALBUM_ITEM_REQUEST,
  payload: albumsItem,
});

export const setActiveOffsetVideo = activeOffsetVideo => ({
  type: types.SET_ACTIVE_VIDEO_OFFSET,
  payload: activeOffsetVideo,
});

export const setActiveOffsetStream = activeOffsetStream => ({
  type: types.SET_ACTIVE_STREAM_OFFSET,
  payload: activeOffsetStream,
});

export const setActiveOffsetAlbum = albumsItem => ({
  type: types.SET_ACTIVE_ALBUM_OFFSET,
  payload: albumsItem,
});
