import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';

import { errorNotification } from '../../../redux/operations/notificationOperation';

import {
  sendPlayer,
  editPlayer,
  fetchPlayer,
} from '../../../redux/operations/playerOperation';
import * as validators from '../../../utils/validators';
import ValidationInput from '../../common/validationComponents/ValidationInput';
import ValidationSelect from '../../common/validationComponents/ValidationSelect';
import FileLoader from '../../fileLoader/fileLoader';
import RecomendationInfo from '../../Recomendation/Recomendation';
import { modalTypes } from '../../../consts/consts';
import { changeStatus } from '../../../redux/actions/modalActions';
import {
  getNormalizePlayerData,
  postNormalizeData,
  putNormalizePlayerData,
} from '../../../utils/changeDataFormat';
import LocalizationTabs from '../../LocalizationTabs/LocalizationTabs';
import { firstConfig, lastConfig, bioConfig } from './config';

//options
import countryOptions from '../options/countryOptions.json';
import { getErrorMessage } from '../../../redux/actions/errorHandlerAction';
import { getCategory } from '../../../api/category-service/category-service';

import './styles.scss';

let FormPlayer = ({ handleSubmit, initialize, type, idUser, isOpponent }) => {
  const [error, setError] = useState(null);
  const [uploadImages, changeUploadImage] = useState([]);
  const [emptyImage, changeEmptyImage] = useState(false);
  const [category, setCategory] = useState([]);
  const [defaultImage, setDefaultImage] = useState(null);

  const player = useSelector(({ form }) => form.player);
  const dispatch = useDispatch();

  useEffect(() => {
    getCategory()
      .then(({ items }) => setCategory(items))
      .catch(({ message }) => dispatch(errorNotification(message)));
  }, [dispatch]);

  useEffect(() => {
    if (type) {
      dispatch(fetchPlayer(idUser))
        .then(player => {
          const newPlayer = getNormalizePlayerData(player);
          setDefaultImage(newPlayer.avatar);
          initialize(newPlayer);
        })
        .catch(({ message }) => dispatch(getErrorMessage(message)));
    }
  }, [dispatch, idUser, initialize, type]);

  const submitForm = data => {
    if (!uploadImages.length && !defaultImage) {
      return changeEmptyImage(true);
    }

    const newData = {
      ...data,
      avatar: uploadImages[0],
      games: JSON.stringify([data.games]),
      isOpponent,
    };

    type
      ? dispatch(
          editPlayer(idUser, putNormalizePlayerData(newData), isOpponent),
        )
      : dispatch(sendPlayer(postNormalizeData(newData), isOpponent));
  };

  //FileLoader
  const callbackError = value => {
    setError(value);
  };

  const callbackValue = value => {
    changeUploadImage(value);
    changeEmptyImage(false);
    setDefaultImage('');
  };
  return (
    <div className={'form-player-container'}>
      <form
        className={`form-player ${isOpponent && 'form-player-opponent'}`}
        onSubmit={handleSubmit(submitForm)}
      >
        <div className={'form-player__text-content'}>
          <div
            className={`form-player__text-content-first ${
              isOpponent && `form-player__text-content-first-opponent`
            }`}
          >
            {emptyImage && (
              <p className={'form-player__error'}>Need to add an image</p>
            )}
            {error && <p className={'form-player__error'}>{error}</p>}

            <FileLoader
              errorInfo={{ quantity: 1, size: 10506316, type: 'image' }}
              callbackError={callbackError}
              callbackValue={callbackValue}
              defaultImage={defaultImage ? [defaultImage] : []}
            />
            <RecomendationInfo
              text="Recommendation: upload images no more than 415px width and 405px high"
              position="right"
            />

            <div>
              <label>Email</label>

              <Field
                className={'form-player__input'}
                name="email"
                component={ValidationInput}
                type="email"
                validate={[validators.email]}
                placeholder="email"
              />
            </div>

            <div>
              <LocalizationTabs
                labelText={'First name'}
                syncErrors={player && player.syncErrors}
                config={firstConfig}
              />
            </div>
            <div>
              <LocalizationTabs
                labelText={'Last name'}
                syncErrors={player && player.syncErrors}
                config={lastConfig}
              />
            </div>
            <div>
              <label>Nick name</label>
              <Field
                className={'form-player__input'}
                name="nick"
                component={ValidationInput}
                type="text"
                validate={[
                  validators.required,
                  validators.maxLength14,
                  validators.spaces,
                ]}
                placeholder="nick name"
              />
            </div>
            {!isOpponent ? (
              <>
                <div>
                  <label>Favorite game</label>
                  <Field
                    className={'form-player__input'}
                    name="favouriteGame"
                    component={ValidationInput}
                    type="text"
                    validate={[validators.required]}
                    placeholder="favorite game"
                  />
                </div>

                <div>
                  <LocalizationTabs
                    labelText={'BIO'}
                    syncErrors={player && player.syncErrors}
                    config={bioConfig}
                  />
                </div>
              </>
            ) : null}
          </div>

          {!isOpponent ? (
            <div className={'form-player__text-content-second'}>
              <div>
                <label>Type</label>
                <Field
                  className={'form-player__input'}
                  name="type"
                  component={ValidationSelect}
                  placeholder="select"
                >
                  <option></option>
                  <option value="CREATOR">Creator</option>
                  <option value="PROS">Pros</option>
                </Field>
              </div>
              <div>
                <label>Country</label>
                <Field
                  className={'form-player__input'}
                  name="country"
                  component={ValidationSelect}
                  placeholder="select"
                >
                  <option></option>
                  {countryOptions.map(({ name, isoCode }) => (
                    <option key={name} value={isoCode}>
                      {name}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <label>City</label>
                <Field
                  className={'form-player__input'}
                  name="city"
                  component={ValidationInput}
                  type="text"
                  validate={[validators.minLength2, validators.spaces]}
                  placeholder="city"
                />
              </div>
              <div>
                <label>Tag</label>
                <Field
                  className={'form-player__input'}
                  name="games"
                  component={ValidationSelect}
                >
                  <option></option>
                  {category.map(({ name, _id: id }) => (
                    <option key={name} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <label>Experience</label>
                <Field
                  className={'form-player__input'}
                  name="experience"
                  component={ValidationInput}
                  type="date"
                  validate={[validators.date]}
                  placeholder="experience"
                />
              </div>
              <div>
                <label>Accession day</label>
                <Field
                  className={'form-player__input'}
                  name="dateInGMT"
                  component={ValidationInput}
                  type="date"
                  validate={[validators.date]}
                  placeholder="dateInGMT"
                />
              </div>
              <div>
                <label>In social medias:</label>
                <Field
                  className={'form-player__input'}
                  name="instagram"
                  component={ValidationInput}
                  type="text"
                  validate={[validators.url]}
                  placeholder="instagram social link"
                />
                <Field
                  className={'form-player__input'}
                  name="youtube"
                  component={ValidationInput}
                  type="text"
                  validate={[validators.url]}
                  placeholder="youtube social link"
                />
                <Field
                  className={'form-player__input'}
                  name="facebook"
                  component={ValidationInput}
                  type="text"
                  validate={[validators.url]}
                  placeholder="facebook social link"
                />
                <Field
                  className={'form-player__input'}
                  name="twitter"
                  component={ValidationInput}
                  type="text"
                  validate={[validators.url]}
                  placeholder="twitter social link"
                />
                <Field
                  className={'form-player__input'}
                  name="vk"
                  component={ValidationInput}
                  type="text"
                  validate={[validators.url]}
                  placeholder="vk social link"
                />
              </div>
            </div>
          ) : null}
        </div>
        <div />

        {type === 'edit' && (
          <div className={'form-player__actions'}>
            <button className={'button'} type="submit">
              save changes
            </button>
            <button
              onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
              className={'button'}
              type="button"
            >
              cancel
            </button>
          </div>
        )}
        {!type && (
          <div className={'form-player__actions'}>
            <button className="button" type="submit">
              submit
            </button>
            <button
              onClick={() => dispatch(changeStatus(modalTypes.CLOSE))}
              className="button"
              type="button"
            >
              cancel
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

FormPlayer = reduxForm({
  form: 'player',
})(FormPlayer);

export default FormPlayer;
