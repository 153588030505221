import types from '../types/achievementTypes';

export const addAchievementRequest = () => ({
  type: types.ADD_ACHIEVEMENT_REQUEST,
});

export const addAchievementSuccess = (data = {}) => ({
  type: types.ADD_ACHIEVEMENT_SUCCESS,
  payload: data,
});

export const addAchievementError = (error = '') => ({
  type: types.ADD_ACHIEVEMENT_ERROR,
  payload: error,
});

export const getAchievementsRequest = () => ({
  type: types.GET_ACHIEVEMENT_REQUEST,
});

export const getAchievementsSuccess = (data = {}) => ({
  type: types.GET_ACHIEVEMENT_SUCCESS,
  payload: data,
});

export const getAchievementsError = (error = '') => ({
  type: types.GET_ACHIEVEMENT_ERROR,
  payload: error,
});

export const deleteAchievementsRequest = () => ({
  type: types.DELETE_ACHIEVEMENT_REQUEST,
});

export const deleteAchievementsSuccess = id => ({
  type: types.DELETE_ACHIEVEMENT_SUCCESS,
  payload: id,
});

export const deleteAchievementsError = (error = '') => ({
  type: types.DELETE_ACHIEVEMENT_ERROR,
  payload: error,
});

export const editAchievementsRequest = () => ({
  type: types.EDIT_ACHIEVEMENT_REQUEST,
});

export const editAchievementsSuccess = id => ({
  type: types.EDIT_ACHIEVEMENT_SUCCESS,
  payload: id,
});

export const editAchievementsError = (error = '') => ({
  type: types.EDIT_ACHIEVEMENT_ERROR,
  payload: error,
});
