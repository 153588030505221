const types = {
  ADD_ACHIEVEMENT_REQUEST: 'ADD_ACHIEVEMENT_REQUEST',
  ADD_ACHIEVEMENT_SUCCESS: 'ADD_ACHIEVEMENT_SUCCESS',
  ADD_ACHIEVEMENT_ERROR: 'ADD_ACHIEVEMENT_ERROR',
  GET_ACHIEVEMENT_REQUEST: 'GET_ACHIEVEMENT_REQUEST',
  GET_ACHIEVEMENT_SUCCESS: 'GET_ACHIEVEMENT_SUCCESS',
  GET_ACHIEVEMENT_ERROR: 'GET_ACHIEVEMENT_ERROR',
  DELETE_ACHIEVEMENT_REQUEST: 'DELETE_ACHIEVEMENT_REQUEST',
  DELETE_ACHIEVEMENT_SUCCESS: 'DELETE_ACHIEVEMENT_SUCCESS',
  DELETE_ACHIEVEMENT_ERROR: 'DELETE_ACHIEVEMENT_ERROR',
  EDIT_ACHIEVEMENT_REQUEST: 'EDIT_ACHIEVEMENT_REQUEST',
  EDIT_ACHIEVEMENT_SUCCESS: 'EDIT_ACHIEVEMENT_SUCCESS',
  EDIT_ACHIEVEMENT_ERROR: 'EDIT_ACHIEVEMENT_ERROR',
};

export default types;
