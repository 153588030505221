import {
  closeNotify,
  getSuccessMessage,
  getErrorMessage,
} from '../actions/notifyActions';
import { notifySelector } from '../selectors/selectors';

export const successNotification = message => (dispatch, getStore) => {
  dispatch(getSuccessMessage(message));
  setTimeout(() => {
    const { type: notificationStatus } = notifySelector(getStore());

    notificationStatus && dispatch(closeNotify());
  }, 3000);
};

export const errorNotification = message => (dispatch, getStore) => {
  dispatch(getErrorMessage(message));
  setTimeout(() => {
    const { type: notificationStatus } = notifySelector(getStore());
    notificationStatus && dispatch(closeNotify());
  }, 3000);
};
