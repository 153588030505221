import React from 'react';

import removeIcon from 'app/assets/image/remove.png';
import editIcon from 'app/assets/image/edit.png';

import './styles.scss';

const ShopLinksTable = ({
  items,
  handleEditButton,
  handleDeleteButton,
  manageOrder,
}) => {
  return (
    <div>
      <div className="event-container">
        <table className="table">
          <thead>
            <tr>
              <th className="table__heading">Change order</th>
              <th className="table__heading">Label</th>
              <th className="table__heading">URL</th>

              <th className="table__heading">Edit</th>
              <th className="table__heading">Delete</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, indx) => (
              <tr key={item._id}>
                <td>
                  <div className="shop-links-table__order-buttons">
                    {indx > 0 && (
                      <button
                        onClick={() =>
                          manageOrder({
                            _id: item._id,
                            order: item.order - 1,
                          })
                        }
                      >
                        ↑
                      </button>
                    )}
                    {indx + 1 !== items.length && (
                      <button
                        onClick={() =>
                          manageOrder({
                            _id: item._id,
                            order: item.order + 1,
                          })
                        }
                      >
                        ↓
                      </button>
                    )}
                  </div>
                </td>
                <td>{item.name}</td>
                <td>{item.url}</td>
                <td className="table-controls">
                  <img
                    className={'icon'}
                    src={editIcon}
                    alt="edit icon"
                    onClick={() => handleEditButton(item)}
                  />
                </td>
                <td className="table-controls">
                  <img
                    className={'icon'}
                    src={removeIcon}
                    onClick={() => handleDeleteButton(item._id)}
                    alt="remove icon"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShopLinksTable;
