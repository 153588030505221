import React from 'react';

// import GameTable from '../../component/GameTable/GameTableContainer';
import TournamentTable from '../../component/TournamentTable/TournamentTableContainer';

import './styles.scss';

const Events = () => {
  return (
    <div className="events">
      {/* <GameTable /> */}
      <TournamentTable />
    </div>
  );
};

export default Events;
