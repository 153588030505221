import React from 'react';
import './styles.scss';

const ErrorPage = () => {
  return (
    <div className={'error-page'}>
      <p className={'error-page__text'}>
        Sorry, there was an error in the application, please reload the page
      </p>
    </div>
  );
};

export default ErrorPage;
