import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { setActiveOffsetVideo } from '../../redux/actions/mediaActions';
import {
  initAllVideo,
  updateStream,
  updateVideo,
} from '../../redux/operations/mediaOperations';
import commentsIcon from '../../assets/image/media/coment.png';
import edit from '../../assets/image/media/edit.png';
import remove from '../../assets/image/media/rmove.png';
import { changeStatus } from '../../redux/actions/modalActions';
import { modalTypes } from '../../consts/consts';
import { connect, useDispatch } from 'react-redux';
import { typeComment } from '../comment/typeComment';

import { ReactComponent as ArrowDown } from '../../assets/image/down-arrow.svg';
import './style.scss';

const VideoList = ({ video, stream, typeVideo, handleSort, conrtol }) => {
  const dispatch = useDispatch();

  const handleNextVideoList = ({ selected }) => {
    dispatch(setActiveOffsetVideo(6 * selected));
    if (typeVideo === 'STREAM') {
      dispatch(updateStream({ active: 6 * selected }));
    } else {
      dispatch(updateVideo({ active: 6 * selected }));
    }
  };

  const openVideoLoader = (edit, type, id = '') => {
    dispatch(changeStatus(modalTypes.OPEN_UPLOAD_VIDEO(edit, type, id)));
  };

  const getItems = item => {
    const openComment = id => {
      dispatch(
        changeStatus(
          modalTypes.OPEN_VIDEO_COMMENT(id, typeComment.VIDEO_COMMENT),
        ),
      );
    };

    const getFormatData = dateCreate => {
      const date = new Date(dateCreate);
      return (
        ('0' + date.getDate()).slice(-2) +
        '/' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '/' +
        date.getFullYear()
      );
    };

    return item.map(
      ({ dateCreate, _id, title: { en: title }, commentsCount }) => (
        <div className="videoList__video-container" key={_id}>
          <div className="videoList__video-item videoList__video-item-link">
            {title}
          </div>
          <div className="videoList__video-item videoList__video-item-data">
            {getFormatData(dateCreate)}
          </div>
          <div
            className="videoList__video-item videoList__video-item-name"
            onClick={() => {}}
          >
            <img
              src={commentsIcon}
              className={
                commentsCount
                  ? 'videoList__video-item_edit styleKey'
                  : 'videoList__video-item-off  '
              }
              onClick={() => {
                if (commentsCount) {
                  openComment(_id);
                }
              }}
              alt={'comments'}
            />
          </div>
          <div className="videoList__video-item">
            <img
              src={edit}
              className="videoList__video-item_edit styleKey"
              onClick={() => {
                openVideoLoader(true, `${typeVideo}-EDIT`, _id);
              }}
              alt={'edit'}
            />
          </div>
          <div className="videoList__video-item">
            <img
              src={remove}
              className="videoList__video-item_deleted styleKey"
              onClick={() => {
                openRemoveModal(_id);
              }}
              alt={'remove'}
            />
          </div>
        </div>
      ),
    );
  };

  const openRemoveModal = id => {
    dispatch(changeStatus(modalTypes.REMOVE_VIDEO(id, typeVideo)));
  };

  const getStreamAndVideo = () => {
    return (
      <div className="videoList__video-list scrollElement">
        <div className="videoList__video-container">
          <div className="videoList__video-item videoList__video-item-link">
            Title
          </div>
          <div
            onClick={() => handleSort('dataCreate', typeVideo)}
            className="videoList__video-item videoList__video-data"
          >
            Data
            <ArrowDown className="videoList__video-item-icon" />
          </div>
          <div className="videoList__video-item videoList__video-item-name">
            Comments
          </div>
          <div className="videoList__video-item">Edit</div>
          <div className="videoList__video-item">Delete</div>
        </div>
        {getItems(typeVideo === 'VIDEO' ? video.items : stream.items)}
      </div>
    );
  };
  const getCountPage = () => {
    const size = typeVideo === 'VIDEO' ? video.count : stream.count;
    return Math.ceil(size / 6);
  };
  return (
    <div>
      <div className="videoList">
        <p>{typeVideo === 'VIDEO' ? 'Video' : 'Stream '}</p>
        {conrtol()}
        <button
          className="button"
          onClick={() => {
            openVideoLoader(false, typeVideo);
          }}
        >
          {typeVideo === 'VIDEO' ? 'add video' : 'add stream '}
        </button>
      </div>
      <div className="videoList__item">
        {getStreamAndVideo()}
        <ReactPaginate
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={getCountPage()}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleNextVideoList}
        />
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    video: state.media.video,
    stream: state.media.streamList,
    activeOffsetStream: state.media.activeOffsetStream,
    activeOffsetVideo: state.media.activeOffsetVideo,
  };
};
export default connect(mapStateToProps, null)(VideoList);
