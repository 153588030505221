import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { modal, playersSelector } from '../../redux/selectors/selectors';
import { changeStatus } from '../../redux/actions/modalActions';
import {
  fetchPlayers,
  removePlayer,
} from '../../redux/operations/playerOperation';
import PlayerList from './PlayersList';
import ReactPaginate from 'react-paginate';

const PlayerListContainer = ({
  isOpenModal,
  playersData,
  removePlayer,
  changeStatus,
  fetchPlayers,
  isOpponent,
}) => {
  useEffect(() => {
    fetchPlayers({ isOpponent });
  }, [fetchPlayers]);
  const getNextPage = ({ selected }) => {
    fetchPlayers({ isOpponent, offset: selected * 18 });
  };
  return (
    <>
      <PlayerList
        playersData={playersData.items}
        isOpenModal={isOpenModal}
        removePlayer={removePlayer}
        changeStatus={changeStatus}
        isOpponent={isOpponent}
      />
      <ReactPaginate
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(playersData.count / 18)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={getNextPage}
      />
    </>
  );
};

const mapStateToProps = state => ({
  isOpenModal: modal(state),
  playersData: playersSelector(state),
});

const mapDispatchToProps = {
  changeStatus,
  fetchPlayers,
  removePlayer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerListContainer);
