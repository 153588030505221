import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

import { tournamentSelector } from '../../redux/selectors/selectors';
import { changeStatus } from '../../redux/actions/modalActions';
import { modalTypes } from '../../consts/consts';
import {
  fetchTournamentList,
  updateTournamentsSortingParams,
} from '../../redux/operations/tournamentOperation';

import TournamentTable from './TournamentTable';

import './styles.scss';

const TournamentTableContainer = ({
  changeStatus,
  fetchTournamentList,
  tournamentList,
  updateTournamentsSortingParams,
}) => {
  const { items, count } = tournamentList;

  useEffect(() => {
    fetchTournamentList();
  }, [fetchTournamentList]);

  return (
    <div>
      <div className="game-table-head">
        <h1>Tournament</h1>
        <button
          className="button"
          onClick={() => changeStatus(modalTypes.TOURNAMENT)}
        >
          add new tournament
        </button>
      </div>
      <TournamentTable
        handleRemoveItem={id =>
          changeStatus(modalTypes.CONFIRM_REMOVE_TOURNAMENT(id))
        }
        changeStatus={changeStatus}
        items={items}
        handleSortItems={sortType =>
          updateTournamentsSortingParams({ sortType })
        }
      />
      <div className={'react-paginate'}>
        <ReactPaginate
          pageCount={Math.ceil(count / 15)}
          onPageChange={({ selected: offset }) => {
            updateTournamentsSortingParams({ offset: 15 * offset });
          }}
          containerClassName={'pagination'}
          activeClassName={'active'}
          initialPage={0}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tournamentList: tournamentSelector(state),
});

const mapDispatchToProps = {
  changeStatus,
  fetchTournamentList,
  updateTournamentsSortingParams,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentTableContainer);
