import { postFormData, get, putFormData, remove } from '../apiService';

export const getPartnersList = active => {
  const activeInfo = active ? '?offset=' + active : '?offset=0';
  const limitInfo = '&limit=6';
  return get('/partner/' + activeInfo + limitInfo);
};
export const postPartner = body => {
  return postFormData('/partner', body);
};
export const getPartner = id => {
  return get('/partner/' + id);
};
export const putPartner = (id, body) => {
  return putFormData('/partner/' + id, body);
};
export const deletePartner = id => {
  return remove('/partner/' + id);
};
export const getStaff = active => {
  const activeInfo = active ? '?offset=' + active : '?offset=0';
  const limitInfo = '&limit=10';
  return get('/staff/' + activeInfo + limitInfo);
};
export const postStaff = body => {
  return postFormData('/staff', body);
};
export const deleteStaff = id => {
  return remove('/staff/' + id);
};
