import React, { useEffect, useState } from 'react';
import FileLoader from '../fileLoader/fileLoader';
import { connect, useDispatch } from 'react-redux';
import {
  removePhoto,
  updateAlbum,
  upgradeAlbum,
} from '../../redux/operations/mediaOperations';
import { albumItemSelector } from '../../redux/selectors/selectors';
import TagsSelector from '../tagsSelector/tagsSelector';
import { changeStatus } from '../../redux/actions/modalActions';
import { modalTypes } from '../../consts/consts';
import { typeComment } from '../comment/typeComment';

import './style.scss';

const Album = ({ id, albumsItem, onClose }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [teg, setTeg] = useState([]);
  const [titleEn, setTitleEn] = useState(
    albumsItem.title ? albumsItem.title.en : '',
  );
  const [titleRu, setTitleRu] = useState(
    albumsItem.title ? albumsItem.title.ru : '',
  );
  const [titleAz, setTitleAz] = useState(
    albumsItem.title ? albumsItem.title.az : '',
  );

  const [validBody, setValidBody] = useState({
    images: false,
    tag: false,
    titleRu: false,
    titleAz: false,
    titleEn: false,
  });

  useEffect(() => {
    dispatch(updateAlbum(id));
  }, []);
  useEffect(() => {
    setTitleEn(albumsItem.title ? albumsItem.title.en : '');
    setTitleRu(albumsItem.title ? albumsItem.title.ru : '');
    setTitleAz(albumsItem.title ? albumsItem.title.az : '');
  }, [albumsItem._id]);

  const saveChanges = () => {
    const formData = new FormData();

    if (images.length) {
      images.forEach(img => {
        formData.append('photos', img);
      });
    }
    if (teg.length) {
      formData.append('tags', JSON.stringify(teg));
    }
    if (titleEn !== albumsItem.title.en) {
      formData.append('title-en', titleEn);
    }
    if (titleRu !== albumsItem.title.ru) {
      formData.append('title-ru', titleRu);
    }
    if (titleAz !== albumsItem.title.az) {
      formData.append('title-az', titleAz);
    }

    dispatch(upgradeAlbum(id, formData, onClose));
  };
  const openComment = idPhoto => {
    dispatch(
      changeStatus(
        modalTypes.OPEN_VIDEO_COMMENT(idPhoto, typeComment.PHOTO_COMMENT, id),
      ),
    );
  };

  const getImage = () => {
    return albumsItem.photos ? (
      albumsItem.photos.map(item => (
        <div className="gallery__item" key={item._id}>
          <img className="gallery__image " src={item.photo} alt={item.photo} />
          <div
            className="gallery__item-key gallery__item-delete styleKey"
            onClick={() => {
              dispatch(removePhoto(id, item._id));
            }}
          >
            Delete
          </div>
          <div
            className="gallery__item-key styleKey"
            onClick={() => {
              openComment(item._id);
            }}
          >
            Comments
          </div>
        </div>
      ))
    ) : (
      <div />
    );
  };

  const validate = (type, value) => {
    const valid = { ...validBody };

    switch (type) {
      case 'tag':
        const tagsId = albumsItem.tags.map(item => {
          return item._id;
        });
        valid[type] =
          JSON.stringify(value.sort()) !== JSON.stringify(tagsId.sort());
        break;
      case 'images':
        valid[type] = true;
        break;
      case 'titleRu':
        valid[type] =
          albumsItem.title.ru !== value && albumsItem.title.ru.trim();
        setTitleRu(value);

        break;
      case 'titleAz':
        valid[type] =
          albumsItem.title.az !== value && albumsItem.title.az.trim();
        setTitleAz(value);

        break;
      case 'titleEn':
        valid[type] =
          albumsItem.title.en !== value && albumsItem.title.en.trim();
        setTitleEn(value);

        break;
      default:
        break;
    }

    setValidBody(valid);
  };
  const getIsValid = () => {
    return (
      validBody.images ||
      validBody.tag ||
      validBody.titleRu ||
      validBody.titleAz ||
      validBody.titleEn
    );
  };
  return (
    <div className="gallery">
      <div className="gallery__container scrollElement">{getImage()}</div>
      <div className="gallery__upload-file">
        <div>
          <p>Title Ru:</p>
          <div className="gallery__input-container">
            <input
              className="input"
              value={titleRu}
              onChange={({ target }) => {
                validate('titleRu', target.value);
              }}
            />
          </div>
          <p>Title Az:</p>
          <div className="gallery__input-container">
            <input
              className="input"
              value={titleAz}
              onChange={({ target }) => {
                validate('titleAz', target.value);
              }}
            />
          </div>
          <p>Title En:</p>
          <div className="gallery__input-container">
            <input
              className="input"
              value={titleEn}
              onChange={({ target }) => {
                validate('titleEn', target.value);
              }}
            />
          </div>
        </div>
        <TagsSelector
          activeTag={albumsItem.tags}
          setTag={value => {
            setTeg(value);
            validate('tag', value);
          }}
        />
        <FileLoader
          errorInfo={{ quantity: 30, size: 10000000, type: 'image' }}
          callbackValue={value => {
            setImages(value);
            validate('images', value);
          }}
          callbackError={e => {}}
          type={''}
        />
        <div className="gallery__button">
          <div
            className={
              getIsValid()
                ? 'button styleKey'
                : 'button styleKey button-disabled'
            }
            onClick={() => {
              if (getIsValid()) {
                saveChanges();
              }
            }}
          >
            save
          </div>
          <div
            className="button styleKey"
            onClick={() => {
              onClose();
            }}
          >
            close
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  albumsItem: albumItemSelector(state),
});

export default connect(mapStateToProps, null)(Album);
