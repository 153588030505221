import { postFormData, putFormData, get, remove } from '../apiService';

export const getAchievements = async () => {
  return get('/achievement/');
};

export const getAchievementById = async id => {
  return get(`/achievement/${id}/`);
};

export const editAchievementById = async (id, body) => {
  return putFormData(`/achievement/${id}/`, body);
};

export const postAchievement = body => {
  return postFormData('/achievement/', body);
};

export const deleteAchievement = id => {
  return remove(`/achievement/${id}`);
};
