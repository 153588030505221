import {
  deletePartner,
  deleteStaff,
  getPartner,
  getPartnersList,
  getStaff,
  postPartner,
  postStaff,
  putPartner,
} from '../../api/about-service/about-service';
import {
  setPartner,
  addPartnerList,
  addStaffList,
  removeActivePartners,
} from '../actions/aboutAction';
import { store } from '../store/store';
import { changeStatus } from '../actions/modalActions';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';

export const updatePartnerList = offset => dispatch => {
  getPartnersList(offset)
    .then(response => {
      dispatch(addPartnerList(response));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const getActivePartner = id => dispatch => {
  getPartner(id)
    .then(response => {
      dispatch(setPartner(response));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const addPartner = bodyFormData => dispatch => {
  const { about } = store.getState();
  return postPartner(bodyFormData)
    .then(() => {
      dispatch(updatePartnerList(about.activeOffsetPartners));
      dispatch(successNotification(successfulMessage.PARTNER_ADDED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const editPartner = (id, bodyFormData) => dispatch => {
  const { about } = store.getState();

  return putPartner(id, bodyFormData)
    .then(() => {
      dispatch(updatePartnerList(about.activeOffsetPartners));
      dispatch(successNotification(successfulMessage.PARTNER_CHANGED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removePartner = id => dispatch => {
  const { about } = store.getState();
  return deletePartner(id)
    .then(() => {
      dispatch(updatePartnerList(about.activeOffsetPartners));
      dispatch(successNotification(successfulMessage.PARTNER_REMOVED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const updateStaffList = offset => dispatch => {
  return getStaff(offset)
    .then(response => {
      dispatch(addStaffList(response));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const addStaff = bodyFormData => dispatch => {
  const { about } = store.getState();
  postStaff(bodyFormData)
    .then(() => {
      dispatch(updateStaffList(about.activeOffsetStaff));
      dispatch(changeStatus(false));
      dispatch(successNotification(successfulMessage.STAFFMATE_ADDED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removeStaff = id => dispatch => {
  const { about } = store.getState();
  return deleteStaff(id)
    .then(() => {
      dispatch(updateStaffList(about.activeOffsetStaff));
      dispatch(successNotification(successfulMessage.STAFFMATE_REMOVED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const cleatActivePartners = () => dispatch => {
  dispatch(removeActivePartners());
};
