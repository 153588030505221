import types from '../types/newsTypes';

export const saveArticleList = articleList => ({
  type: types.ADD_NEWS_LIST_REQUEST,
  payload: articleList,
});
export const saveNewsOffset = offset => ({
  type: types.SET_ACTIVE_NEWS_OFFSET,
  payload: offset,
});

export const saveCommentsList = commentsList => ({
  type: types.ADD_COMMENT_REQUEST,
  payload: commentsList,
});
