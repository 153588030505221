import { reset } from 'redux-form';
import { changeStatus } from '../actions/modalActions';
import * as playerActions from '../actions/playerActions';
import * as api from '../../api/players-service/players-service';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';

export const fetchPlayers = queryParams => dispatch => {
  dispatch(playerActions.getPlayersRequest());

  return api
    .getPlayers(queryParams)
    .then(r => dispatch(playerActions.getPlayersSuccess(r)))
    .catch(error => dispatch(playerActions.getPlayersError(error)));
};

export const fetchPlayer = id => dispatch => {
  dispatch(playerActions.getPlayerRequest());

  return api
    .getPlayer(id)
    .then(response => {
      return response;
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};

export const sendPlayer = (data = {}, isOpponent) => dispatch => {
  dispatch(playerActions.addPlayerRequest());

  return api
    .postPlayer(data)
    .then(player => {
      dispatch(fetchPlayers({ isOpponent }));
      dispatch(reset('player'));
      dispatch(changeStatus(false));
      dispatch(successNotification(successfulMessage.PLAYER_ADDED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removePlayer = (id, isOpponent) => dispatch => {
  dispatch(playerActions.deletePlayersRequest());

  return api
    .deletePlayer(id)
    .then(() => {
      dispatch(fetchPlayers({ isOpponent }));

      //dispatch(playerActions.deletePlayersSuccess(id));
      dispatch(successNotification(successfulMessage.PLAYER_REMOVED));
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};

export const editPlayer = (id, data, isOpponent) => dispatch => {
  dispatch(playerActions.editPlayerRequest());

  return api
    .putPlayer(id, data)
    .then(player => {
      dispatch(fetchPlayers({ isOpponent }));
      //  dispatch(playerActions.editPlayerSuccess(player));
      dispatch(changeStatus(false));
      dispatch(reset('player'));
      dispatch(successNotification(successfulMessage.PLAYER_CHANGED));
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};
