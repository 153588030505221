export const createFormData = data => {
  const formdata = new FormData();
  const newData = Object.entries(data);

  newData.forEach(item => formdata.append(item[0], item[1]));

  return formdata;
};

export const reverseConversionDataFormat = data => {
  const newData = {};
  data.forEach((value, key) => {
    newData[key] = value;
  });

  return newData;
};
