import gameTypes from '../types/gameTypes';
import tournamentTypes from '../types/tournamentTypes';

const initialState = {
  game: {
    items: [],
    count: 0,
    pageNumber: 0,
    sortingParams: { offset: 0, sortType: '' },
    isOpenInnerForm: false,
  },
  report: {
    items: [],
    count: 0,
    pageNumber: 0,
    sortingParams: { offset: 0, sortType: '' },
  },
  tournament: {
    items: [],
    count: 0,
    pageNumber: 0,
    sortingParams: { offset: 0, sortType: '' },
  },
};

const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // game
    case gameTypes.GET_GAME_LIST_SUCCESS:
    case gameTypes.GET_SORTED_GAME_LIST_SUCCESS:
      return { ...state, game: { ...state.game, ...payload } };
    case gameTypes.UPDATE_GAME_SORTING_PARAMS:
      return {
        ...state,
        game: {
          ...state.game,
          sortingParams: { ...state.sortingParams, ...payload },
        },
      };
    case gameTypes.EDIT_GAME_SUCCESS:
      return {
        ...state,
        game: {
          ...state.game,
          items: payload,
        },
      };
    case gameTypes.DELETE_GAME_COMMENT_SUCCESS:
      return {
        ...state,
        game: {
          ...state.game,
          items: payload,
        },
      };
    case gameTypes.CHANGE_GAME_INNER_FORM_STATUS:
      return {
        ...state,
        game: {
          ...state.game,
          isOpenInnerForm: payload,
        },
      };
    case gameTypes.EDIT_GAME_LOGO_SUCCESS:
      return {
        ...state,
        game: {
          ...state.game,
          items: state.game.items.map(game =>
            game._id === payload._id ? payload : game,
          ),
        },
      };

    //tournament
    case tournamentTypes.GET_TOURNAMENT_LIST_SUCCESS:
    case tournamentTypes.GET_SORTED_TOURNAMENT_LIST_SUCCESS:
      return { ...state, tournament: { ...state.tournament, ...payload } };
    case tournamentTypes.UPDATE_TOURNAMENT_SORTING_PARAMS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          sortingParams: { ...state.sortingParams, ...payload },
        },
      };
    case tournamentTypes.EDIT_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          items: payload,
        },
      };

    default:
      return state;
  }
};

export default eventReducer;
