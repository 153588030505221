import React from 'react';

import LoginForm from '../../component/loginForm/loginForm';

import './styles.scss';

const Login = () => {
  return (
    <div className="login">
      <LoginForm />
    </div>
  );
};

export default Login;
