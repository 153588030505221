import React from 'react';
import dateFormat from 'dateformat';

import removeIcon from '../../assets/image/remove.png';
import editIcon from '../../assets/image/edit.png';
import { modalTypes } from '../../consts/consts';
import { checkAndTrimStringForDisplay } from '../../utils/stringMethods';

import './styles.scss';

const TournamentTable = ({
  handleRemoveItem,
  items,
  handleSortItems,
  changeStatus,
}) => {
  return (
    <div>
      <div className="event-container">
        <table className="table">
          <thead>
            <tr>
              <th
                onClick={() => handleSortItems('discipline')}
                className="table__heading sort"
              >
                Tag
              </th>
              <th className="table__heading">Tournament name</th>
              <th
                onClick={() => handleSortItems('startDate')}
                className="table__heading sort"
              >
                Date
              </th>
              <th className="table__heading">Format</th>
              <th className="table__heading">Tournament logo</th>
              <th className="table__heading">Edit</th>
              <th className="table__heading">Delete</th>
            </tr>
          </thead>
          <tbody>
            {items.map(
              ({
                _id: id,
                discipline: { name: disciplineName },
                title,
                startDate,
                endDate,
                format,
                logo,
              }) => (
                <tr key={id}>
                  <td>{checkAndTrimStringForDisplay(disciplineName)}</td>
                  <td>{checkAndTrimStringForDisplay(title)}</td>
                  <td>
                    {dateFormat(startDate, 'dd.mm.yyyy')} -{' '}
                    {dateFormat(endDate, 'dd.mm.yyyy')}
                  </td>
                  <td>{format}</td>
                  <th>
                    <img className="event-icon" src={logo} alt="logo" />
                  </th>
                  <td className="table-controls">
                    <img
                      className={'icon'}
                      src={editIcon}
                      alt="remove icon"
                      onClick={() =>
                        changeStatus(modalTypes.EDIT_TOURNAMENT(id))
                      }
                    />
                  </td>
                  <td className="table-controls">
                    <img
                      className={'icon'}
                      src={removeIcon}
                      onClick={() => handleRemoveItem(id)}
                      alt="edit icon"
                    />
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TournamentTable;
