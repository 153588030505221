import { get, post, put, remove } from '../apiService';

export const getShopLinksList = () => {
  return get('/link');
};

export const removeShopLink = params => {
  return remove('/link/' + params.id);
};

export const updateShopLink = (params, reqBody) => {
  return put('/link/' + params._id, reqBody);
};

export const createShopLink = params => {
  return post('/link', params);
};
