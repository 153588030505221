import { reset } from 'redux-form';
import { changeStatus } from '../actions/modalActions';
import * as gameActions from '../actions/gameActions';
import * as api from '../../api/event-service/event-service';
import {
  gameSortingParamsSelector,
  gameCountSelector,
  gameListSelector,
} from '../selectors/selectors';
import { updateEventList } from '../../utils/updateEventList';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';
import { createFormData } from '../../utils/formDataOperations';

export const fetchGameList = () => dispatch => {
  dispatch(gameActions.getGameListRequest());
  return api
    .getGameList()
    .then(response => {
      dispatch(gameActions.getGameListSuccess(response));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const fetchGame = id => dispatch => {
  dispatch(gameActions.getGameRequest());

  return api
    .getGameById(id)
    .then(response => {
      return response;
    })
    .catch(({ message }) => dispatch(errorNotification(message)));
};

export const sendGame = (data, defaultPhoto) => (dispatch, getState) => {
  dispatch(gameActions.postGameRequest());
  const { sortType, offset } = gameSortingParamsSelector(getState());
  return api
    .postGame(data)
    .then(({ _id }) => {
      if (defaultPhoto.length) {
        const defaultImageFormData = createFormData({ photo: defaultPhoto[0] });
        api
          .putGamePhoto(_id, defaultImageFormData)
          .then(() => {
            dispatch(sortGame(sortType, offset));
            dispatch(reset('event'));
            dispatch(changeStatus(false));
            dispatch(successNotification(successfulMessage.GAME_ADDED));
          })
          .catch(() => {
            dispatch(sortGame(sortType, offset));
            dispatch(changeStatus(false));
            dispatch(
              errorNotification(
                'The game was created, but an error occurred while loading the photo, click edit the created game and try to add the photo again',
              ),
            );
          });

        return;
      }

      dispatch(reset('event'));
      dispatch(changeStatus(false));
      dispatch(successNotification(successfulMessage.GAME_ADDED));
    })

    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removeGame = id => (dispatch, getState) => {
  dispatch(gameActions.deleteGameRequest());
  const { sortType, offset } = gameSortingParamsSelector(getState());
  const count = gameCountSelector(getState());
  const isFirstPage = offset === 0;
  const isDeleteLastEventOfPage = count - 1 === offset;

  return api
    .deleteGame(id)
    .then(() => {
      if (!isFirstPage && isDeleteLastEventOfPage) {
        const newOffset = offset - 15;
        dispatch(sortGame(sortType, newOffset));
        dispatch(
          gameActions.updateGameSortingParams({ sortType, offset: newOffset }),
        );
        dispatch(gameActions.deleteGameSuccess());
      } else {
        dispatch(sortGame(sortType, offset));
        dispatch(successNotification(successfulMessage.GAME_REMOVED));
        dispatch(gameActions.deleteGameSuccess());
      }
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
      dispatch(gameActions.deleteGameError(message));
    });
};

export const removeGameComment = (gameId, commentId) => (
  dispatch,
  getState,
) => {
  dispatch(gameActions.deleteGameCommentRequest());
  const gameList = gameListSelector(getState());

  return api
    .deleteGameCommentById(gameId, commentId)
    .then(() => {
      const newGameList = gameList.map(game =>
        game._id === gameId
          ? { ...game, commentsCount: game.commentsCount - 1 }
          : game,
      );
      dispatch(gameActions.deleteGameCommentSuccess(newGameList));
    })
    .catch(({ message }) => {
      dispatch(gameActions.deleteGameCommentError(message));
    });
};

export const sortGame = (type, offset) => dispatch => {
  dispatch(gameActions.getSortedGameListRequest());
  dispatch(gameActions.updateGameSortingParams({ type, offset }));
  return api
    .getSortedGameList(type, offset)
    .then(response => {
      const payloadData = {
        ...response,
        pageNumber: Math.ceil(response.count / 15) - 1,
      };

      dispatch(gameActions.getSortedGameListSuccess(payloadData));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    })
    .finally(() => dispatch(reset('event')));
};

export const updateGamesSortingParams = params => (dispatch, getState) => {
  dispatch(gameActions.updateGameSortingParams(params));

  const { sortType, offset } = gameSortingParamsSelector(getState());

  dispatch(sortGame(sortType, offset));
};

export const editGame = (id, data, logo) => (dispatch, getState) => {
  const gamesItems = gameListSelector(getState());

  dispatch(gameActions.editGameRequest());
  if (!data.video) {
    delete data.video;
  }
  let editlogoRequest = null;
  const editInformationRequest = api
    .putGame(id, data)
    .then(response => {
      dispatch(
        gameActions.editGameSuccess(updateEventList(response, gamesItems)),
      );
      return response;
    })
    .catch(error => {
      dispatch(gameActions.editGameError(error));
      dispatch(
        errorNotification('An error occurred while updating the game data'),
      );
      throw error;
    });

  if (logo) {
    dispatch(gameActions.editGameLogoRequest());
    const logoFormData = createFormData({ photo: logo });

    editlogoRequest = api
      .putGamePhoto(id, logoFormData)
      .then(response => {
        dispatch(gameActions.editGameLogoSuccess(response));
        return response;
      })
      .catch(error => {
        dispatch(
          errorNotification('An error occurred while updating the photo'),
        );
        throw error;
      });
  }

  Promise.all([editInformationRequest, editlogoRequest]).then(() => {
    dispatch(changeStatus(false));
    dispatch(reset('event'));
    dispatch(successNotification(successfulMessage.GAME_CHANGED));
  });
};
