import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import FileLoader from '../fileLoader/fileLoader';
import {
  addPartner,
  editPartner,
  getActivePartner,
} from '../../redux/operations/aboutOperation';

import RecomendationInfo from '../Recomendation/Recomendation';

import './styles.scss';

const PartnerCreator = ({ closeCreator, edit, id, body }) => {
  const [name, setName] = useState('');
  const [site, setSite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [vk, setVk] = useState('');
  const [description, setDescription] = useState('');
  const [discord, setDiscord] = useState('');
  const [youtube, setYoutube] = useState('');
  const [logo, setLogo] = useState([]);
  const [banner, setBanner] = useState([]);
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState({
    name: false,
    banner: false,
    logo: false,
    site: false,
    instagram: false,
    twitter: false,
    facebook: false,
    vk: false,
    discord: false,
    youtube: false,
    description: false,
  });

  useEffect(() => {
    if (!edit) {
      setIsValid({
        name: false,
        banner: true,
        logo: false,
        site: false,
        instagram: true,
        twitter: true,
        facebook: true,
        vk: true,
        discord: true,
        youtube: true,
        description: true,
      });
    }

    return () => setIsValid(isValid);
  }, [edit]);

  useEffect(() => {
    if (edit) {
      dispatch(getActivePartner(id));
      setName(body.name);
      setSite(body.site);
      setInstagram(body.instagram);
      setFacebook(body.facebook);
      setTwitter(body.twitter);
      setVk(body.vk);
      setDescription(body.description);
      setDiscord(body.discord);
      setYoutube(body.youtube);
    }
  }, [
    body.name,
    body.site,
    body.instagram,
    body.facebook,
    body.twitter,
    body.vk,
    id,
    dispatch,
    edit,
    body.description,
    body.discord,
    body.youtube,
  ]);

  const createForData = () => {
    if (edit) {
      const formData = new FormData();
      if (isValid.name) {
        formData.append('name', name.trim());
      }
      if (isValid.banner) {
        formData.append('background', banner[0]);
      }
      if (isValid.logo) {
        formData.append('logo', logo[0]);
      }
      if (isValid.site) {
        formData.append('site', site);
      }
      if (isValid.instagram) {
        formData.append('instagram', instagram);
      }
      if (isValid.facebook) {
        formData.append('facebook', facebook);
      }
      if (isValid.twitter) {
        formData.append('twitter', twitter);
      }
      if (isValid.vk) {
        formData.append('vk', vk);
      }
      if (isValid.description) {
        formData.append('description', description);
      }
      if (isValid.discord) {
        formData.append('discord', discord);
      }
      if (isValid.youtube) {
        formData.append('youtube', youtube);
      }
      dispatch(editPartner(id, formData));
    } else {
      const formData = new FormData();
      formData.append('name', name);
      banner.length && formData.append('background', banner[0]);
      formData.append('logo', logo[0]);
      site && formData.append('site', site);
      instagram && formData.append('instagram', instagram);
      twitter && formData.append('twitter', twitter);
      facebook && formData.append('facebook', facebook);
      vk && formData.append('vk', vk);
      formData.append('description', description);
      discord && formData.append('discord', discord);
      youtube && formData.append('youtube', youtube);

      dispatch(addPartner(formData));
    }
  };

  const validation = (type, value) => {
    const isValidNew = isValid;
    const regUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    const spaces = /^\s*$/;

    switch (type) {
      case 'name':
        isValidNew[type] = edit
          ? value !== body.name && !!value && !spaces.test(value)
          : value.length && !spaces.test(value);
        break;
      case 'description':
        isValidNew[type] = edit
          ? value !== body.description && !!value && !spaces.test(value)
          : value.length && !spaces.test(value);
        break;
      case 'banner':
        isValidNew[type] = edit
          ? Array.isArray(value) && value.length
          : value.length;
        break;
      case 'logo':
        isValidNew[type] = edit
          ? Array.isArray(value) && value.length
          : value.length;
        break;
      case 'site':
        isValidNew[type] = edit
          ? value !== body.site && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      case 'vk':
        isValidNew[type] = edit
          ? value !== body.vk && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      case 'instagram':
        isValidNew[type] = edit
          ? value !== body.instagram && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      case 'facebook':
        isValidNew[type] = edit
          ? value !== body.facebook && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      case 'twitter':
        isValidNew[type] = edit
          ? value !== body.twitter && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      case 'discord':
        isValidNew[type] = edit
          ? value !== body.discord && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      case 'youtube':
        isValidNew[type] = edit
          ? value !== body.youtube && regUrl.test(value)
          : value
          ? regUrl.test(value)
          : true;
        break;
      default:
        break;
    }
    setIsValid(isValidNew);
  };

  const getIsValid = () => {
    return edit
      ? isValid.name ||
          isValid.banner ||
          isValid.logo ||
          isValid.site ||
          isValid.instagram ||
          isValid.facebook ||
          isValid.twitter ||
          isValid.vk ||
          isValid.description ||
          isValid.discord ||
          isValid.youtube
      : isValid.name &&
          isValid.banner &&
          isValid.logo &&
          isValid.site &&
          isValid.instagram &&
          isValid.facebook &&
          isValid.twitter &&
          isValid.vk &&
          isValid.description &&
          isValid.discord &&
          isValid.youtube;
  };

  return (
    <div className={'partnerCreator-container'}>
      <div className={'partnerCreator'}>
        <div className={'partnerCreator__item'}>
          <p>Name</p>
          <input
            placeholder={'Name partner'}
            className="input"
            value={name}
            onChange={({ target }) => {
              setName(target.value);
              validation('name', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Description</p>
          <input
            placeholder={'Description partner'}
            className="input"
            value={description}
            onChange={({ target }) => {
              setDescription(target.value);
              validation('description', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Site</p>
          <input
            placeholder={'Site link'}
            value={site}
            className="input"
            onChange={({ target }) => {
              setSite(target.value);
              validation('site', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>instagram</p>
          <input
            placeholder={'Instagram link'}
            value={instagram}
            className="input"
            onChange={({ target }) => {
              setInstagram(target.value);
              validation('instagram', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Facebook</p>
          <input
            placeholder={'Facebook link'}
            value={facebook}
            className="input"
            onChange={({ target }) => {
              setFacebook(target.value);
              validation('facebook', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Twitter</p>
          <input
            placeholder={'Twitter link'}
            value={twitter}
            className="input"
            onChange={({ target }) => {
              setTwitter(target.value);
              validation('twitter', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Vk</p>
          <input
            placeholder={'Vk link'}
            value={vk}
            className="input"
            onChange={({ target }) => {
              setVk(target.value);
              validation('vk', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Discord</p>
          <input
            placeholder={'Discord link'}
            value={discord}
            className="input"
            onChange={({ target }) => {
              setDiscord(target.value);
              validation('discord', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <p>Youtube</p>
          <input
            placeholder={'Youtube link'}
            value={youtube}
            className="input"
            onChange={({ target }) => {
              setYoutube(target.value);
              validation('youtube', target.value);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <RecomendationInfo
            text={
              'Recommendation: upload images that are no more than 350 pixels width'
            }
          />
          <p>Add logo</p>
          <FileLoader
            name={'logo'}
            defaultImage={body.logo ? [body.logo] : []}
            errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
            callbackError={e => console.log(e)}
            callbackValue={image => {
              setLogo(image);
              validation('logo', image);
            }}
          />
        </div>
        <div className={'partnerCreator__item'}>
          <RecomendationInfo
            text={
              'Recommendation: upload images that are no more than 350 pixels width'
            }
          />

          <p>Add banner</p>

          <FileLoader
            name={'banner'}
            defaultImage={body.background ? [body.background] : []}
            errorInfo={{ quantity: 1, size: 10000000, type: 'image' }}
            callbackError={e => console.log(e)}
            callbackValue={image => {
              setBanner(image);
              validation('banner', image);
            }}
          />
          <p>{error.banner}</p>
        </div>
        <div className={'partnerCreator__button-container'}>
          <button
            className={
              getIsValid()
                ? 'button styleKey'
                : 'button styleKey  button-disabled'
            }
            disabled={!getIsValid()}
            onClick={() => {
              createForData();
              closeCreator();
            }}
          >
            {edit ? 'Save' : 'Add Partner'}
          </button>
          <div
            className={'button styleKey '}
            onClick={() => {
              closeCreator();
            }}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ body: state.about.activePartners });

export default connect(mapStateToProps, null)(PartnerCreator);
