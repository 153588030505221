import {
  getVideoList,
  getAlbumList,
  deleteVideo,
  deletePhoto,
  getAlbum,
  deleteAlbum,
  putAlbum,
  postAlbum,
} from '../../api/media-service/media-service';
import {
  saveVideo,
  saveAlbum,
  saveAlbumsItem,
  saveStream,
} from '../actions/mediaActions';
import { store } from '../store/store';
import { successfulMessage } from '../../consts/notifySuccessfulMessage';
import {
  successNotification,
  errorNotification,
} from '../operations/notificationOperation';

export const initAllVideo = ({ type, params }) => dispatch => {
  if (type === 'STREAM') {
    dispatch(updateStream(params));
    dispatch(successNotification(successfulMessage.STREAM_ADDED));
  } else if (type === 'VIDEO') {
    dispatch(updateVideo(params));
    dispatch(successNotification(successfulMessage.VIDEO_ADDED));
  } else if (type === 'VIDEO-INITIAL') {
    dispatch(updateVideo(params));
  } else if (type === 'STREAM-INITIAL') {
    dispatch(updateStream(params));
  } else {
    dispatch(updateVideo(params));
    dispatch(updateStream(params));
  }
};

export const updateVideo = params => dispatch => {
  getVideoList({ type: 'VIDEO', params })
    .then(items => {
      dispatch(saveVideo(items));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const updateStream = params => dispatch => {
  getVideoList({ type: 'STREAM', params })
    .then(items => {
      dispatch(saveStream(items));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const updateAlbums = params => {
  return async dispatch => {
    getAlbumList(params)
      .then(items => {
        dispatch(saveAlbum(items));
      })
      .catch(({ message }) => {
        dispatch(errorNotification(message));
      });
  };
};

export const removePhoto = (id, idPhoto) => dispatch => {
  return deletePhoto(id, idPhoto)
    .then(() => {
      dispatch(updateAlbum(id));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const updateAlbum = id => dispatch => {
  getAlbum(id)
    .then(item => {
      dispatch(saveAlbumsItem(item));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const upgradeAlbum = (id, body, onClose) => dispatch => {
  const { media } = store.getState();
  putAlbum(id, body)
    .then(() => {
      dispatch(updateAlbums(media.activeOffsetAlbum));
      dispatch(updateAlbum(id));
      dispatch(successNotification(successfulMessage.ALBUM_CHANGED));
      onClose();
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removeAlbum = id => dispatch => {
  const { media } = store.getState();
  deleteAlbum(id)
    .then(() => {
      dispatch(updateAlbums(media.activeOffsetAlbum));
      dispatch(successNotification(successfulMessage.ALBUM_REMOVED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const removeVideo = (id, type) => dispatch => {
  const { media } = store.getState();

  return deleteVideo(id)
    .then(() => {
      if (type === 'VIDEO') {
        dispatch(updateVideo(media.activeOffsetAlbum));
        dispatch(successNotification(successfulMessage.VIDEO_REMOVED));
      }
      if (type === 'STREAM') {
        dispatch(updateStream(media.activeOffsetAlbum));
        dispatch(successNotification(successfulMessage.STREAM_REMOVED));
      }
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};

export const createAlbum = formData => dispatch => {
  const { media } = store.getState();
  return postAlbum(formData)
    .then(() => {
      dispatch(updateAlbums(media.activeOffsetAlbum));
      dispatch(successNotification(successfulMessage.ALBUM_ADDED));
    })
    .catch(({ message }) => {
      dispatch(errorNotification(message));
    });
};
