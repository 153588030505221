import React from 'react';

import { modalTypes } from '../../consts/consts';
import TeamItem from '../TeamItem/TeamItem';

import './styles.scss';

const TeamList = ({ teamData = [], removeTeam, changeStatus, isOpponent }) => {
  return (
    <div>
      <div className="teams__head">
        <h1>Teams</h1>
        <button
          className={'button'}
          onClick={() =>
            changeStatus(
              isOpponent ? modalTypes.OPPONENT_TEAM : modalTypes.TEAM,
            )
          }
        >
          add new team
        </button>
      </div>

      <div className={'team'}>
        <ul className={'team__list'}>
          {teamData?.map(({ _id, name, game, logo }) => (
            <TeamItem
              key={_id}
              id={_id}
              name={name}
              game={game?.icon}
              logo={isOpponent ? logo : game?.icon}
              isOpponent={isOpponent}
              removeTeam={removeTeam}
              changeStatus={changeStatus}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TeamList;
