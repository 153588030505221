import types from '../types/playerTypes';

export const addPlayerRequest = () => ({
  type: types.ADD_PLAYER_REQUEST,
});

export const addPlayerSuccess = (data = {}) => ({
  type: types.ADD_PLAYER_SUCCESS,
  payload: data,
});

export const addPlayerError = (error = '') => ({
  type: types.ADD_PLAYER_ERROR,
  payload: error,
});

export const getPlayersRequest = () => ({
  type: types.GET_PLAYERS_REQUEST,
});

export const getPlayersSuccess = (data = {}) => ({
  type: types.GET_PLAYERS_SUCCESS,
  payload: data,
});

export const getPlayersError = (error = '') => ({
  type: types.GET_PLAYERS_ERROR,
  payload: error,
});

export const getPlayerRequest = () => ({
  type: types.GET_PLAYER_REQUEST,
});

export const getPlayerSuccess = (data = {}) => ({
  type: types.GET_PLAYER_SUCCESS,
  payload: data,
});

export const getPlayerError = (error = '') => ({
  type: types.GET_PLAYER_ERROR,
  payload: error,
});

export const deletePlayersRequest = () => ({
  type: types.DELETE_PLAYER_REQUEST,
});

export const deletePlayersSuccess = id => ({
  type: types.DELETE_PLAYER_SUCCESS,
  payload: id,
});

export const deletePlayersError = (error = '') => ({
  type: types.DELETE_PLAYER_ERROR,
  payload: error,
});

export const editPlayerRequest = () => ({
  type: types.EDIT_PLAYER_REQUEST,
});

export const editPlayerSuccess = (data = {}) => ({
  type: types.EDIT_PLAYER_SUCCESS,
  payload: data,
});

export const editPlayerError = (error = '') => ({
  type: types.EDIT_PLAYER_ERROR,
  payload: error,
});
