import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { Routes } from './config';

import Login from '../pages/login/login';

import LeftPanel from '../component/leftPanel/leftPanel';

import { startRefreshTimeout } from '../redux/actions/authenticationActions';
import book from './book';

import './styles.scss';

const Main = ({ userStatus, startRefreshTimeout }) => {
  useEffect(() => {
    startRefreshTimeout();
  }, []);

  return (
    <Router>
      {userStatus ? (
        <div className="router">
          <LeftPanel />
          <div className="router__content">
            <Switch>
              {Routes.map(({ path, component, isExact }) => (
                <Route
                  key={path}
                  path={path}
                  exact={isExact}
                  component={component}
                />
              ))}

              <Redirect to={book.home} />
            </Switch>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </Router>
  );
};
const mapStateToProps = state => ({
  userStatus: state.authentication.userIsLogin,
});

const mapDispatchToProps = dispatch => {
  return {
    startRefreshTimeout: () => dispatch(startRefreshTimeout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
